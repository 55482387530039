export default function currency(
  v: number,
  currency: string = "USD",
  intDigits: number = 2,
  fractionDigits: number = 2
) {
  const opt: any = {
    minimumIntegerDigits: intDigits,
    minimumFractionDigits: fractionDigits,
    style: 'currency'
  };
  if (currency) opt.currency = currency;
  return new Intl.NumberFormat("en-US", opt).format(v);
}
