/**
 * accessProperties
 * extract value from nested object by string annotation
 * @param  {object} o the object to extract value from
 * @param  {string} s the value to extract as a string example : employee.info.fullname
 * @return {any}      needed value
 */

export default function accessProperties(o: object, s: string): any {
  if (!o || !s) return 0;

  var localO: any = o;
  var localS: string = s;
  var a: string[];
  var property: string

  // convert braket access to dot access
  localS = localS.replace(/\[(\w+)\]/g, ".$1");
  localS = localS.replace(/^\./, ""); // trim leading dot
  a = localS.split(".");

  for (var i = 0; i < a.length; ++i) {
    // if (!o) return;
    property = a[i];
    if (localO && property in localO) {
      localO = localO[property];
    } else {
      return 0;
    }
  }

  return localO;
}
