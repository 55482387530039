import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  Dispatch,
  ReactNode,
} from "react";
import { useRequest } from "../../services/request";
import { BaseStatus, Status } from "types/types";

export type Bulk = {
  id: number;
  name: string,
  items_count: string,
  created_on: string,
}

export enum BulksActionType {
  SetList,
  ChangeStatus,
  SetParentId,
  SetCount,
  ChangePage,
  ChangePerPage,
}

type Action =
  | { type: BulksActionType.SetList; payload: any[] }
  | { type: BulksActionType.ChangeStatus; payload: Status }
  | { type: BulksActionType.SetParentId; payload: number }
  | { type: BulksActionType.SetCount; payload: number }
  | { type: BulksActionType.ChangePage; payload: number }
  | { type: BulksActionType.ChangePerPage; payload: number };

type State = {
  list: any[];
  status: Status;
  error: any;
  parentId: number;
  count: number;
  page: number;
  perPage: number;
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Action>;
  fetchList: (id: number, page: number, pageSize: number) => void;
};

const Context = createContext<ContextType | null>(null);

export function useBulks() {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      "useBulks has to be used within <BulkProvider>. One possible solution is to add <BulkProvider> to providers.js in /services"
    );

  return context;
}

const initState: State = {
  list: [],
  parentId: null,
  status: BaseStatus.Idle,
  error: null,
  count: 0,
  page: 0,
  perPage: 10,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case BulksActionType.SetList:
      return { ...state, list: [...action.payload] };
    case BulksActionType.SetParentId:
      return { ...state, parentId: action.payload };
    case BulksActionType.ChangeStatus:
      return { ...state, status: action.payload };
    case BulksActionType.SetCount:
      return { ...state, count: action.payload };
    case BulksActionType.ChangePage:
      return { ...state, page: action.payload };
    case BulksActionType.ChangePerPage:
      return { ...state, perPage: action.payload };

    // default:
    //   throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function BulkProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    (id: number, page = 0, pageSize = 10) => {
      return new Promise(async (resolve, reject) => {
        try {
          if (!id) return;
          dispatch({
            type: BulksActionType.ChangeStatus,
            payload: BaseStatus.Fetching,
          });
          dispatch({ type: BulksActionType.SetParentId, payload: id });
          let query = `page=${page + 1}&page_size=${pageSize}`;
          const resData = await req({
            url: `products/${id}/bulks/?${query}`,
            withAuth: true
          });
          dispatch({ type: BulksActionType.SetList, payload: resData.results });
          dispatch({ type: BulksActionType.SetCount, payload: resData.count });

          resolve(resData);
        } catch (error) {
          console.error(error);
          reject(error);
        } finally {
          dispatch({
            type: BulksActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
      }}
    >
      {children}
    </Context.Provider>
  );
}
