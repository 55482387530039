function mapProductDetails(productDetails: any, productSpecFields: any) {
  const tempsFields = productSpecFields.map((f: any) => ({
    name: f.name,
    value: productDetails?.["json_data"]?.[f.id],
  }));
  tempsFields.unshift(
    {
      name: "Product name",
      value: productDetails.name,
    },
    {
      name: "Product tag",
      value: productDetails.tag?.name,
    },
    {
      name: "Manufacturer",
      value: productDetails.manufacturer?.name,
    },
    {
      name: "Product Spec",
      value: productDetails.details?.productSpec.name,
    },
    {
      name: "Item Spec",
      value: productDetails.details?.itemSpec.name,
    },
  );

  return tempsFields;
}

export { mapProductDetails };
