import { useEffect, useState } from "react";
import AnalyticEcommerce from "./components/AnalyticEcommerce";
import MonthlyBarChart from "./components/MonthlyBarChart";
import OrdersTable from "./components/OrdersTable";
import { Box, Card, Divider, Flex, Grid, Loading, Select, Type } from "ui";
import MapChart from "./components/MapChart";
import { useAuth } from "services/auth";
import getUserCountry from "utilities/getUserCountry";
import percentageDiff from "utilities/percentageDiff";
import { useQuery } from "@tanstack/react-query";
import useStatsApi from "views/stats/useStatsApi";

const emptyTotals = {
  products: {
    current_range: 0,
    previous_range: 0,
  },
  items: {
    current_range: 0,
    previous_range: 0,
  },
  redeemed: {
    current_range: 0,
    previous_range: 0,
  },
  verification_transactions: {
    current_range: 0,
    previous_range: 0,
  },
};

const Dashboard = () => {
  const [imgProfile, setImgProfile] = useState(null);
  const [days, setDays] = useState(365);

  const auth = useAuth();
  const { getTotals, getMintedItems } = useStatsApi();

  const totalsQuery = useQuery({
    queryKey: ["totals", days],
    queryFn: () => getTotals({ daysAgo: days }),
    placeholderData: (previousData) => previousData || emptyTotals,
  });

  const mintedItemsQuery = useQuery({
    queryKey: ["mintedItems"],
    queryFn: () => getMintedItems(),
    placeholderData: (previousData) => previousData || [],
  });

  useEffect(() => {
    console.log(`Country: ${getUserCountry().country}`);
    console.log(`City: ${getUserCountry().city}`);
    console.log(`Region: ${getUserCountry().region}`);
    console.log(`Timezone: ${getUserCountry().timezone}`);
  }, []);

  useEffect(() => {
    (async function () {
      const data = await auth.getIdentity();
      setImgProfile(data);
    })();
  }, [auth]);

  const { data: totals } = totalsQuery;
  const { data: mintedItems } = mintedItemsQuery;

  return (
    <>
      {imgProfile ? (
        <img
          width={140}
          style={{borderRadius: '4px'}}
          src={`${auth.user.configs.ipfs_base_url}/${imgProfile.image}`}
          alt="Profile logo"
        />
      ) : (
        <Loading size={18} />
      )}

      <Flex jc="space-between">
        <Type
          sx={{
            lh: 20,
            mt: 40,
            fw: 600,
            fs: 17,
            border: ["gray600", 3, "solid", 0, "b"],
            c: "gray1k",
            mb: -1,
            p: 10,
          }}
        >
          Quick Statistics
        </Type>
        <Select
          onChange={(data) => {
            switch (Array.isArray(data) ? data[0].value : data.value) {
              case "7 Days":
                setDays(7);
                break;
              case "1 Month":
                setDays(30);
                break;
              case "6 Months":
                setDays(180);
                break;
              case "1 Year":
                setDays(365);
                break;
              default:
                setDays(30);
            }
          }}
          search={false}
          defaultValue="1 Year"
          size="small"
          noSeperator
          style={{ width: 140, marginBottom: -10 }}
          data={["7 Days", "1 Month", "6 Months", "1 Year"]}
        />
      </Flex>

      <Divider border="gray300" h={1} />

      <Grid
        cols={["1fr 1px 1fr 1px 1fr 1px 1fr", "1fr 1px 1fr 1px 1fr 1px 1fr", "1fr 1px 1fr", "1fr 1px 1fr"]}
        colGap={25}
        rowGap={40}
        sx={{ w: "100%", mt: 25 }}
      >
        <AnalyticEcommerce
          title="Total Products"
          count={totals.products?.current_range}
          percentage={percentageDiff(
            totals.products?.previous_range,
            totals.products?.current_range
          )}
          color="blue"
        />
        <Divider vertical border="gray400" />
        <AnalyticEcommerce
          title="Total Items"
          count={totals.items?.current_range}
          percentage={percentageDiff(
            totals.items?.previous_range,
            totals.items?.current_range
          )}
          color="blue"
        />
        <Divider
          vertical
          border="gray400"
          sx={{ d: ["block", "_", "none", "none"] }}
        />
        <AnalyticEcommerce
          title="Total Redeemed"
          count={totals.redeemed?.current_range}
          percentage={percentageDiff(
            totals.redeemed?.previous_range,
            totals.redeemed?.current_range
          )}
        />
        <Divider vertical border="gray400" />
        <AnalyticEcommerce
          title="Total Transactions"
          count={totals.verification_transactions?.current_range}
          percentage={percentageDiff(
            totals.verification_transactions?.previous_range,
            totals.verification_transactions?.current_range
          )}
        />
      </Grid>

      <Divider border="gray300" h={50} />

      <Box sx={{ mb: 10 }}>
        <Type var="h3" as="h3" sx={{ mb: 20, mt: 40 }}>
          Top Transactions Locations
        </Type>
        <MapChart />
      </Box>

      <Divider border="gray300" h={50} />

      <Grid
        cols={["100%", "100%"]}
        sx={{ mt: 40, w: ["auto", "auto", "auto", "100%"] }}
        gap={40}
      >
        <Box>
          <Type var="h3" as="h3">
            Minted Items
          </Type>

          <Card elevation={0} bg="transparent" padding="0">
            <Flex gap={10} jc="flex-start" sx={{py: 15}}>
              <Type var="h3">
                {mintedItems.reduce((partialSum, a) => partialSum + a.count, 0)}
              </Type>
              <Type sx={{ fw: 600 }}>Total Items</Type>
            </Flex>
            {Boolean(mintedItems.length) && (
              <MonthlyBarChart
                labels={mintedItems.map((item) => item.date)}
                data={mintedItems.map((item) => {
                  return item.count;
                })}
              />
            )}
          </Card>
        </Box>
        <Box>
          <Type var="h3" as="h3" sx={{ mb: 20 }}>
            Recent Orders
          </Type>

          <OrdersTable />
        </Box>
      </Grid>
    </>
  );
};

export default Dashboard;
