import styled from "styled-components";
import Label from "./Label";
import Type from "./Type";
import { InputHTMLAttributes } from "react";

const Wrap = styled.div`
  .container {
    display: block;
    position: relative;
    padding-left: 0;
    margin-bottom: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
  .checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border: ${(p) => p.theme.color.gray500} 1px solid;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  }
  .container:hover input ~ .checkmark {
    border-width: 2px;
  }
  .container input:active ~ .checkmark {
    border-width: 3px;
  }

  .container input:checked ~ .checkmark {
    border-width: 1px;
  }
  .checkmark:after {
    content: "";
    width: 0;
    height: 0;
    background: transparent;
    border-radius: 100px;
    transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  }
  .container input:checked ~ div .checkmark:after {
    width: 12px;
    height: 12px;
    background: ${(props) => props.theme.color.secondary};
  }
`;

type InputRadioProps = {
  name: string;
  label: string;
  checked?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  error?: boolean;
  msg?: string;
};

function InputRadio({
  name,
  label,
  checked,
  inputProps,
  error,
  msg,
  ...props
}: InputRadioProps) {
  return (
    <Wrap {...props} className={`${error && "error"}`}>
      <Label className="container">
        <span>{label}</span>
        <input type="radio" {...{ name, checked, ...inputProps }} />
        <div>
          <span className="checkmark"></span>
        </div>
      </Label>
      {msg && <Type sx={{ c: "red" }}>{msg}</Type>}
    </Wrap>
  );
}

export default InputRadio;
