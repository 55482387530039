import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText, Link, Type } from "../../ui";
import "../../css/flags.css";
import { useAuth } from "../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const schema = Yup.object().shape({
  email: Yup.string().required("Required.").email("Invalid email."),
  password: Yup.string()
    .required("Required.")
    .min(4, "Too Short! at least 4 letters is required!"),
});

type LoginFormProps = {
  onSuccess: () => void,
  setLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

function LoginForm({ onSuccess, setLogin }: LoginFormProps) {
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  let navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (auth.user) navigate(from, { replace: true });
  }, [auth, from, navigate]);

  const onSubmit = async (values: any) => {
    setError(null);
    try {
      await auth.signin(values);
      reset();
      onSuccess();
    } catch (e: any) {
      window.scrollTo(0, 0);
      console.log("error", e);
      if (e.status === 400) setError("Wrong email/password combination!");
    }
  };

  return (
    <Box as="form" sx={{ w: [322, 322, 322, 300] }} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Type role="alert" sx={{ c: "red", mb: 20, fw: 500 }}>{error && error}</Type>
      <InputText
        placeholder="Email Address"
        iconLeft="mail"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          autoFocus: true,
          // "aria-label": "email",
          required: true,
        }}
      />
      <InputText
        placeholder="Password"
        type={showPass ? "text" : "password"}
        iconLeft="key"
        iconRight={[
          showPass ? "eyeSlash" : "eye",
          () => setShowPass((showPass) => !showPass),
        ]}
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{
          ...register("password"),
          // "aria-label": "password",
          role: "textbox",
          required: true,
        }}
      />
      <Button
        type="submit"
        loading={isSubmitting}
        size="large"
        iconName="arrowRight"
        sx={{ mx: "auto", w: "100%" }}
      >
        Login
      </Button>
      <Type
        as={Link}
        to=""
        onClick={() => setLogin(false)}
        sx={{ ta: "center", mt: 40, fw: 500, d: "block" }}
      >
        Forgot Password?
      </Type>
    </Box>
  );
}

export default LoginForm;
