import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Providers from "./services/providers";
import Login from "./views/login/Login";
import GlobalStyle from "./GlobalStyles";
import { RequireAuth } from "./services/auth";
import Dashboard from "views/dashboard/Dashboard";
import Specs from "views/specs/Specs";
import productsRoutes from "./views/products/routes";
import tagsRoutes from "./views/tags/routes";
import usersRoutes from "./views/Users/routes";
import userRoutes from "./views/User/Routes";
import walletRoutes from "./views/wallet/Routes";
import reportsRoutes from "./views/reports/routes";
import WalletCreate from "views/wallet/WalletCreate";
import NoAccess from "views/NoAccess";
import SetPass from "views/User/SetPass";
import VerificationTransaction from "views/dashboard/VerificationTransaction";
import bulksRoutes from "./views/bulks/routes";
import WalletResetPass from "views/wallet/WalletResetPass";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Providers>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="specs"
              element={
                <RequireAuth>
                  <Specs />
                </RequireAuth>
              }
            />
            <Route
              path="/verification-transactions"
              element={
                <RequireAuth>
                  <VerificationTransaction />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            {productsRoutes}
            {usersRoutes}
            {userRoutes}
            {walletRoutes}
            {tagsRoutes}
            {reportsRoutes}
            {bulksRoutes}
            <Route path="no-access" element={<NoAccess />} />
            <Route path="set" element={<SetPass />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route
            path="wallet/create"
            element={
              <RequireAuth>
                <WalletCreate />
              </RequireAuth>
            }
          />
          <Route
            path="reset-password"
            element={
              <RequireAuth>
                <WalletResetPass />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
    </Providers>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
