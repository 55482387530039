import { Icon, Loading } from "ui";
import useProductApi from "../useProductApi";

export default function RemoveProduct({ id }: { id: number }) {
  const { remove } = useProductApi();

  return remove.isPending ? (
    <span>
      <Loading size={15} />
    </span>
  ) : (
    <Icon
      hover="gray300"
      name="trash"
      w={14}
      onClick={() => {
        if (window.confirm("Are you sure?")) remove.mutate(Number(id));
      }}
    />
  );
}
