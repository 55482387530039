import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, InputText, Type } from "ui";
import RolesList from "./components/RolesList";
import useApi from "./useApi";
import { User } from "./type";
import Label from "ui/Label";

const schema = z.object({
  email: z.string().email(),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
});

type UserFormProps = {
  handleSuccess: (id: number) => void;
};

function UserForm({ handleSuccess }: UserFormProps) {
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState<number[]>([]);

  const { addUser } = useApi();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Pick<User, "email" | "first_name" | "last_name">>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (
    values: Pick<User, "email" | "first_name" | "last_name">
  ) => {
    if(!roles.length) return setError('At least one role must be checked.');
    addUser.mutate(
      { ...values, roles },
      {
        onSuccess: (data: User) => {
          reset();
          handleSuccess(data.user_id);
        },
        onError: (e: any) => {
          if (e.errors)
            setError(
              <>
                <div>Server error.</div>
                {e.errors?.data?.map((e: string) => (
                  <p>{e}</p>
                ))}
              </>
            );
          else
            setError(
              "Something went wrong. If this error persists, try using different email."
            );
          console.log("e", e);
        },
      }
    );
    return false;
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Card sx={{ mt: 10, mxw: 500 }}>
        {error && <Type sx={{ mt: 20, mb: 20, c: "red", bgc: "redBg", p: 7, br: 4}}>{error}</Type>}

        <InputText
          id="email"
          label="Email"
          name="email"
          type="email"
          error={!!errors.email}
          msg={errors.email?.message}
          inputProps={{
            required: true,
            ...register("email"),
            autoFocus: true,
          }}
        />

        <InputText
          id="firstname"
          label="First name"
          name="first_name"
          error={!!errors.first_name}
          msg={errors.first_name?.message}
          inputProps={{
            required: true,
            ...register("first_name"),
          }}
        />

        <InputText
          id="lastname"
          label="Last name"
          name="last_name"
          error={!!errors.last_name}
          msg={errors.last_name?.message}
          inputProps={{
            required: true,
            ...register("last_name"),
          }}
        />

        <Label>Roles</Label>
        <RolesList userRolesIds={roles} onChange={setRoles} />

        <Button sx={{ mt: 50, mr: 10 }} loading={isSubmitting}>
          Create
        </Button>
        <Button
          variant="text"
          sx={{ mt: 20, mb: 20 }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Card>
    </Box>
  );
}

export default memo(UserForm);
