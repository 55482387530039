import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../services/auth";
import { useUtils } from "services/utilsProvider";
import { Box, Button, Card, Flex, Icon, InputText, Link, Type } from "ui";

function Name() {
  const navigate = useNavigate();
  const {notify} = useUtils();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      first_name: auth.user.first_name,
      last_name: auth.user.last_name,
    },
  });

  const onSubmit = async (values: any) => {
    try {
      await auth.editAccount(values);
      notify("Name has been saved successfully", "success");
      navigate("/profile");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Type var="h4">
        <Link to={-1 as any}>
          <Icon name="arrowLeft" w={14} color="black" />
        </Link>{" "}
        Name
      </Type>
      <Type sx={{ py: 15 }}>
        Changes to your name will be reflected across your Stitshain Account.
      </Type>

      <Card sx={{ mxw: 400, mt: 40 }}>
        <Type var="h5" as="h5">
          Change Name
        </Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30 }}
        >
          <InputText
            id="first_name"
            label="First Name"
            name="first_name"
            inputProps={{
              autoFocus: true,
              ...register("first_name"),
            }}
          />

          <InputText
            id="last_name"
            label="Last Name"
            name="last_name"
            inputProps={{
              autoComplete: "last_name",
              autoFocus: true,
              ...register("last_name"),
            }}
          />

          <Flex jc="flex-start" gap={15} sx={{ mt: 30 }}>
            <Button loading={isSubmitting}>Save</Button>
            <Button type="button" variant="text" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </Card>
    </>
  );
}

export default Name;
