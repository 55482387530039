import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../services/auth";
import { useUtils } from "../../services/utilsProvider";
import { Box, Button, Card, Flex, Icon, InputText, Link, Type } from "ui";

const schema = Yup.object().shape({
  old_password: Yup.string().min(8, "Too Short!").required("Required"),
  new_password1: Yup.string().min(8, "Too Short!").required("Required"),
  new_password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
});

function Password() {
  const [serverErrors, setServerErrors] = useState(null);

  const navigate = useNavigate();

  const { notify } = useUtils();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    try {
      await auth.changePass(values);
      notify("New password has been saved", "success");
      navigate("/profile");
    } catch (e: any) {
      console.log("error", e);
      setServerErrors(e.errors);
    }
  };

  return (
    <>
      <Type var="h4">
        <Link to={-1 as any}>
          <Icon name="arrowLeft" w={14} c="black" />
        </Link>{" "}
        Password
      </Type>
      <Type sx={{ py: 15 }}>
        Increase account security by changing password regularly
      </Type>

      <Card sx={{ mxw: 400, mt: 40 }}>
        <Type var="h5">Change Password</Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30 }}
        >
          <InputText
            id="old_password"
            type="password"
            label="Old Password"
            name="old_password"
            error={Boolean(errors.old_password || serverErrors?.old_password)}
            msg={errors.old_password?.message || serverErrors?.old_password}
            inputProps={{
              autoFocus: true,
              ...register("old_password"),
            }}
          />

          <InputText
            id="new_password1"
            type="password"
            label="Password"
            name="new_password1"
            error={Boolean(errors.new_password1 || serverErrors?.new_password1)}
            msg={errors.new_password1?.message || serverErrors?.new_password1}
            inputProps={{
              ...register("new_password1"),
            }}
          />

          <InputText
            id="new_password2"
            type="password"
            label="Confirm Password"
            name="new_password2"
            {...register("new_password2")}
            error={Boolean(errors.new_password2 || serverErrors?.new_password2)}
            msg={errors.new_password2?.message || serverErrors?.new_password2}
            inputProps={{
              ...register("new_password2"),
            }}
          />

          <Flex jc="flex-start" gap={15} sx={{ mt: 30 }}>
            <Button loading={isSubmitting}>Save</Button>
            <Button type="button" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </Card>
    </>
  );
}

export default Password;
