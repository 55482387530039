import { useRequest } from "services/request";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useStore from "useStore";
import type { Field } from "./types";

export default function useFieldApi() {
  const req = useRequest();
  const queryClient = useQueryClient();

  const { page } = useStore((state) => state.field);
  const { selected } = useStore((state) => state.spec);

  async function getList({
    specId,
    page,
    pageSize = 10,
  }: GetListParams): Promise<FieldListResult> {
    if (!specId) return { results: [], count: 0 };
    const query = `page=${page + 1}&page_size=${pageSize}`;
    return await req({
      url: `fields-schemas/${specId}/data-fields/?${query}`,
      withAuth: true,
    });
  }

  const create = useMutation({
    mutationFn: async (data: Omit<Field, "id">): Promise<Field> => {
      return await req({
        url: `fields-schemas/${selected}/data-fields/`,
        body: data,
        options: { method: "POST" },
        withAuth: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "fields" &&
          query.queryKey[1] === selected &&
          (query.queryKey[2] as number) >= page + 1,
      });
    },
  });

  const edit = useMutation({
    mutationFn: async ({ id, data }: EditParams): Promise<Field> => {
      return await req({
        url: `data-field/${id}/`,
        body: data,
        options: { method: "PATCH" },
        withAuth: true,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["fields", selected, page + 1],
        (oldData: FieldListResult) => {
          const results = oldData.results.map((field) =>
            field.id === data.id ? { ...field, ...data } : field
          );

          return { ...oldData, results };
        }
      );
    },
  });

  const remove = useMutation({
    mutationFn: async (id: number): Promise<void> => {
      await req({
        url: `data-field/${id}/`,
        options: { method: "DELETE" },
        withAuth: true,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "fields" &&
          query.queryKey[1] === selected &&
          (query.queryKey[2] as number) >= page + 1,
      });
    },
  });

  return {
    getList,
    create,
    edit,
    remove,
  };
}

// types

type GetListParams = {
  specId: number;
  page: number;
  pageSize?: number;
};

type FieldListResult = {
  count: number;
  next?: string | null;
  prev?: string | null;
  results: Field[];
};

type EditParams = {
  id: Number;
  data: Omit<Field, "id">;
};
