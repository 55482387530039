import { useState } from "react";
import { useRequest } from "services/request";
import { PosOrderResult } from "./types";

export default function usePosOrderApi() {
  const [page, setPage] = useState(0);

  const req = useRequest();

  async function getPosOrders(page: number): Promise<PosOrderResult> {
    const query = `page=${page + 1}&page_size=8`;
    return await req({ url: `pos-orders/?${query}`, withAuth: true });
  }

  return { getPosOrders, page, setPage };
}
