import { Box, Icon, Loading } from "ui";
import useApi from "../useApi";
import { User } from "../type";

const RemoveUser = ({ user_id, email, created_by }: User) => {
  const { removeUser } = useApi();

  if (user_id === created_by)
    return (
      <Box as="span" sx={{ mr: 9 }}>
        <Icon c="gray500" name="trash" w={14} />
      </Box>
    );

  if (removeUser.isPending) return <Loading size={20} />;

  return (
    <Icon
      hover="gray300"
      name="trash"
      w={14}
      onClick={() => {
        if (window.confirm(`Are you sure you want to delete user ${email}?`))
          removeUser.mutate(user_id);
      }}
    />
  );
};

export default RemoveUser;
