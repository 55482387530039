import styled from "styled-components";
import Box from "./Box";
import { Color } from "theme";

type TooltipProps = {
  bg?: Color;
};

export default styled(Box).attrs<TooltipProps>((p) => ({
  bg: "black",
}))`
  border-radius: 4px;
  opacity: 1;
  font-size: 13px;
  color: ${(p) => p.theme.color.white};
  background-color: ${(p) => p.theme.color.black}99;
  padding: 7px;
  > div {
    padding: 10px 15px;
  }
`;
