import { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Card, Checkbox, Icon, InputText, Select, Type } from "ui";
import { useUtils } from "services/utilsProvider";
import { SelectOption } from "ui/select/Selected";
import { orArray } from "types/types";
import useFieldApi from "./useFieldApi";
import { Field } from "./types";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  field_type: Yup.number()
    .nullable()
    .required("Required")
    .typeError("a number is required"),
  is_required: Yup.bool(),
});

type FieldFormProps = {
  field: any;
  onSuccess: () => void;
  id: number | null;
};

function FieldForm({ field = null, onSuccess, id }: FieldFormProps) {
  const { create, edit } = useFieldApi();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: field,
  });

  useEffect(() => {
    reset(field);
  }, [field, reset]);

  const onSubmit = async (values: Omit<Field, "id">) => {
    try {
      if (id)
        edit.mutate(
          { id, data: values },
          {
            onSuccess: () => {
              reset();
              notify(`Field updated successfully`, "success");
              onSuccess();
            },
          }
        );
      else
        create.mutate(values, {
          onSuccess: () => {
            reset();
            notify(`Field added successfully`, "success");
            onSuccess();
          },
        });
    } catch (e: any) {
      console.error(e);
      if (e.rrors) notify(e.errors["non_field_errors"].join(", "), "alert");
    }
  };

  const onChange = useCallback(
    (v: orArray<SelectOption>, name: string) => {
      setValue(name, Array.isArray(v) ? v[0].value : v.value);
    },
    [setValue]
  );

  return (
    <Box
      sx={{ ta: "left" }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Card>
        <Box sx={{ ta: "center", py: 20 }}>
          <Icon name="schema" w={18} />
          <Type var="h6" as="h5" sx={{ mt: 10 }}>
            {id ? `Editing Specs field` : "Add Spec field"}
          </Type>
        </Box>

        <InputText
          id="name"
          label="Field name"
          error={!!errors.name}
          msg={errors.name?.message as string}
          inputProps={{
            autoFocus: true,
            required: true,
            ...register("name"),
          }}
        />

        <Select
          style={{ width: "100%", marginBottom: 20 }}
          data={[
            { value: "", label: "Choose type" },
            { value: 1, label: "Number" },
            { value: 2, label: "Text" },
            { value: 3, label: "Yes/No" },
          ]}
          mapData={{ value: "value", label: "label" }}
          name="type"
          label="Field Type"
          search={false}
          noSeperator={true}
          defaultValue={getValues("field_type")}
          onChange={onChange}
          error={!!errors.field_type}
          msg={errors.field_type?.message as string}
          inputProps={{
            ...register("field_type"),
            required: true,
          }}
        />

        <Checkbox
          id="required"
          label="Is Required?"
          error={!!errors.is_required}
          msg={errors.is_required?.message as string}
          inputProps={{ ...register("is_required") }}
        />

        <Button
          type="submit"
          sx={{ w: "100%", ai: "center", mt: 20 }}
          loading={edit.isPending || create.isPending}
        >
          {id ? "Save" : "Create"}
        </Button>
      </Card>
    </Box>
  );
}

export default memo(FieldForm);
