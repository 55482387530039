export type Color =
  | "primary"
  | "primaryFg"
  | "secondary"
  | "secondaryFg"
  | "white"
  | "whiteFg"
  | "black"
  | "blackFg"
  | "bg"
  | "redBg"
  | "red"
  | "redFg"
  | "greenBg"
  | "green"
  | "greenFg"
  | "blueBg"
  | "blue"
  | "blueFg"
  | "bgSecondary"
  | "yellow"
  | "yellowFg"
  | "gray50"
  | "gray100"
  | "gray200"
  | "gray300"
  | "gray400"
  | "gray500"
  | "gray600"
  | "gray700"
  | "gray800"
  | "gray900"
  | "gray1k"
  | "transparent";

export type Font = "primary" | "secondary" | "body" | "display";

const theme = {
  color: {
    primary: "#000000",
    primaryFg: "#ffffff", // foreground: contrasting color for text mainly
    secondary: "#000000",
    secondaryFg: "#ffffff",
    white: "#ffffff",
    whiteFg: "#000000",
    black: "#000000",
    blackFg: "#ffffff",
    bg: "#f9f9f9",
    redBg: "#FEF3F2",
    red: "#7A271A",
    redFg: "#ffffff",
    greenBg: "#ECFDF3",
    green: "#054F31",
    greenFg: "#ffffff",
    blueBg: "#F5FAFF",
    blue: "#194185",
    blueFg: "#ffffff",
    bgSecondary: "#141414",
    yellow: "#f5d85b",
    yellowFg: "#000000",

    //gray shads
    gray50: "#f8f8f8",
    gray100: "#f5f5f5",
    gray200: "#eeeeee",
    gray300: "#dddddd",
    gray400: "#cccccc",
    gray500: "#999999",
    gray600: "#666666",
    gray700: "#444444",
    gray800: "#222222",
    gray900: "#1d1d1d",
    gray1k: "#111111",
    transparent: "#00000000",
  },
  fonts: {
    primary: "'Montserrat', sans-serif;",
    secondary: "'Montserrat', sans-serif;",
    body: "'Montserrat', sans-serif;",
    display: "'Montserrat', sans-serif;",

    size: {
      body: "16px",
    },
  },
  pageWidth: 1350,
  borderRadius: 16,
  spaces: {
    blockY: 180,
  },
  resolution: {
    lg: 1300,
    md: 992,
    sm: 768,
    xs: 520,
  },
};

export default theme;
