import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRequest } from "services/request";
import useStore from "useStore";
import { Product, ProductListResult } from "./types";
import { Tag } from "views/tags/types";

export default function useProductApi() {
  const changeProduct = useStore((state) => state.changeProduct);
  const { count, page, perPage } = useStore((state) => state.product);

  const req = useRequest();
  const queryClient = useQueryClient();

  async function getList({
    page,
    pageSize = perPage,
    keyword = "",
  }: {
    page: number;
    pageSize?: number;
    keyword?: string;
  }): Promise<Product[]> {
    let query = `page=${page + 1}&page_size=${pageSize}`;
    if (keyword.length) query += `&keyword=${keyword}`;
    const data: ProductListResult = await req({
      url: `products/?${query}`,
      withAuth: true,
    });

    changeProduct({ count: data.count });

    return data.results;
  }

  const remove = useMutation({
    mutationFn: async (id: number): Promise<number> => {
      await req({
        url: `products/${id}/`,
        options: { method: "DELETE" },
        withAuth: true,
      });
      return id;
    },
    onSuccess: () => {
      const results = queryClient.getQueryData<Product[]>([
        "products",
        page + 1,
      ]);

      if (results.length <= 1) {
        changeProduct({ page: page - 1, count: count - 1 }); // zero based page
        return queryClient.removeQueries({
          queryKey: ["products", page + 1],
        });
      }

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "products" &&
          (query.queryKey[1] as number) >= page + 1,
      });
    },
  });

  const create = useMutation({
    mutationFn: async ({data, tag}: {data: Partial<Product>, tag: Tag}): Promise<Product & {tag: Tag}> => {
      const results = await req({
        url: `products/`,
        body: data,
        withAuth: true,
      });
      return {...results, tag}
    },
    onSuccess(data) {
      const lastPage = Math.ceil((count + 1) / perPage);

      changeProduct({ page: lastPage - 1 }); //zero based

      queryClient.setQueryData(["products", lastPage], (oldData: Product[]) => [
        ...oldData,
        data,
      ]);
    },
  });

  const edit = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number;
      data: Partial<Product>;
    }): Promise<Product> => {
      return await req({
        url: `products/${id}/`,
        body: data,
        options: { method: "PATCH" },
        withAuth: true,
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["products", page + 1], (oldData: Product[]) =>
        oldData.map((product) =>
          product.id === data.id ? { ...product, name: data.name } : product
        )
      );
    },
  });

  return {
    page,
    getList,
    remove,
    edit,
    create,
  };
}
