/**
 * capitalize
 * capitalize first char in a string
 * @param  {string} s any string to capitalize
 * @return {string}   the capitalize string
 */

const capitalize = (s: string): string => {
  return (s || '').charAt(0).toUpperCase() + (s||'').slice(1);
};

export default capitalize;
