import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "../../../services/utilsProvider";
import { Box, Button, InputText } from "ui";

const schema = Yup.object().shape({
  password1: Yup.string().min(8, "Too Short!").required("Required"),
  password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("password1"), null], "Passwords must match"),
});

type PasswordProps = {
  next: (password: string) => void;
  loading: boolean;
  isImport?: boolean;
};

function Password({ next, loading, isImport = false }: PasswordProps) {
  const [serverErrors, setServerErrors] = useState(null);

  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    try {
      notify(
        isImport
          ? "Wallet has been imported."
          : "Wallet has been reset with a new password.",
        "success"
      );
      next(values.password1);
    } catch (e: any) {
      console.log("error", e);
      setServerErrors(e.errors);
    }
  };

  return (
    <div id="create-password">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 40, ta: "center", mw: 400, mx: "auto" }}
      >
        <Box sx={{ ta: "left" }}>
          <InputText
            id="password1"
            type="password"
            label="New Password"
            error={Boolean(errors.password1 || serverErrors?.password1)}
            msg={errors.password1?.message || serverErrors?.password1}
            inputProps={{
              name: "password1",
              ...register("password1"),
            }}
          />
          <InputText
            id="password2"
            type="password"
            label="Confirm Password"
            error={Boolean(errors.password2 || serverErrors?.password2)}
            msg={errors.password2?.message || serverErrors?.password2}
            inputProps={{
              name: "password2",
              ...register("password2"),
            }}
          />
        </Box>

        <Button sx={{ mt: 20 }} loading={isSubmitting || loading}>
          {isImport ? "Submit" : "Create Password"}
        </Button>
      </Box>
    </div>
  );
}

export default Password;
