import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import ProductProvider from "views/products/provider";
import ItemProvider from "views/items/provider";
import WalletProvider from "views/wallet/Provider";
import BulkProvider from "views/bulks/Provider";
import { ReactNode } from "react";

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <UtilsProvider>
            <ProductProvider>
              <ItemProvider>
                <WalletProvider>
                  <BulkProvider>{children}</BulkProvider>
                </WalletProvider>
              </ItemProvider>
            </ProductProvider>
        </UtilsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
