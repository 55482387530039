import { useEffect, useState } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { ProductsActionType, useProducts } from "./provider";
import ProductDetails from "./ProductDetails";
import { Access } from "../../services/auth";
import { Flex, Grid, Link, Type } from "ui";
import { mapProductDetails } from "./functions";
import useFieldApi from "views/fields/useFieldApi";
import { useQueryClient } from "@tanstack/react-query";

function Product() {
  const [productList, setProductList] = useState([]);
  const [productSpecFields, setProductSpecFields] = useState([]);
  const params = useParams();
  let id = Number(params.id);

  const { pathname } = useLocation();
  const { getList } = useFieldApi();
  const queryClient = useQueryClient();

  const {
    state: { product },
    dispatch,
    fetchFullProd,
  } = useProducts();

  useEffect(() => {
    if (!id) return;
    fetchFullProd(id);
    return () =>
      dispatch({ type: ProductsActionType.SetSelect, payload: null });
  }, [fetchFullProd, id, dispatch]);

  useEffect(() => {
    if (!product) return;
    (async () => {
      const data = await queryClient.fetchQuery({
        queryKey: ["allFields", product["product_fields_schema"]],
        queryFn: () =>
          getList({
            specId: product["product_fields_schema"],
            page: 0,
            pageSize: 100,
          }),
      });
      setProductSpecFields(data.results);
    })();
  }, [product, queryClient, getList]);

  useEffect(() => {
    if (!product || !productSpecFields?.length) return;
    setProductList(mapProductDetails(product, productSpecFields));
  }, [product, productSpecFields]);

  return (
    <>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Dashboard</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
        {Boolean(pathname.split("/")[3] === "bulk") && (
          <>
            <Type>-</Type>
            <Link to={`/products/${id}/bulks`}>Bulks</Link>
          </>
        )}
      </Flex>

      <Grid
        cols={["5fr 2fr", "5fr 2fr", "5fr 2fr", "100%"]}
        gap={30}
        sx={{ mt: 40 }}
      >
        <Access path="/items">{<Outlet />}</Access>

        {productList && <ProductDetails list={productList} />}
      </Grid>
    </>
  );
}

export default Product;
