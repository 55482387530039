import {
  CSSProperties,
  ComponentPropsWithoutRef,
  ReactNode,
  memo,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import MoreOptions from "./MoreOptions";
import Loading from "../Loading";
import Responsive from "../Responsive";
import Checkbox from "../Checkbox";
import Dropdown from "../core/Dropdown";
import Button from "../Button";
import Box from "../Box";
import Type from "../Type";
import stylesBlocks from "../core/stylesBuilder";
import currency from "utilities/currency";
import accessProperties from "utilities/accessProperties";
import { Color } from "theme";
import { format } from "date-fns";
import { BaseStatus, Status, Sx } from "types/types";
import isPropValid from "@emotion/is-prop-valid";

export type Row = { [k: string]: unknown };

export type RowAction<T extends Row = Row> = {
  name: string;
  render: (row: T) => ReactNode;
};

export type Formatter<T extends Row = Row> =
  | "number"
  | "count"
  | "date"
  | "datetime"
  | "currency"
  | "link"
  | ((value: any, row: T) => ReactNode);

interface TableProps {
  bg?: Color;
}

const Table = styled.table.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<TableProps>`
  min-width: 100%;
  font-size: 20px;
  border-collapse: collapse;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  tr td,
  tr th {
    padding: 17px;
    background-color: ${(p) =>
      p.bg ? p.theme.color[p.bg] : p.theme.color.white};
  }
  thead {
    tr {
      th {
        font-size: 14px;
        text-align: left;
        font-weight: 500;
        color: ${(p) => p.theme.color.white};
        background-color: ${(p) => p.theme.color.black};
      }
    }
  }
  tbody {
    tr {
      transition: all 0.12s ease;
      td {
        border-bottom: ${(p) =>
          p.border ? `1px solid ${p.theme.color[p.border]}99` : "none"};
      }
      &:hover {
        background-color: ${(p) => p.theme.color.gray100};
      }
      &.active {
        background: #f5f5f5;
        td {
          border-bottom: #e9e9e9 1px solid;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    thead {
      display: none;
    }
    tr td {
      display: flex;
      gap: 10px;
      padding: 10px;
      max-width: calc(100vw - 40px);
    }

    tr {
      /* border-bottom: ${(p) => p.theme.color.gray400} 1px solid; */
    }

    td::before {
      content: attr(data-label);
      font-weight: 600;
      font-size: 15px;
      width: auto;
      /* min-width: 100px; */
    }
    td:first-child {
      padding-top: 20px;
    }
    td:last-child {
      /* background-color: ${(p) => p.theme.color.gray100}; */
      /* padding-top: 15px; */
      padding-bottom: 20px;
      margin-bottom: 8px;
      border-radius: 0 0 4px 4px;
    }
    td.actions {
      border-top: ${(p) => p.theme.color.white} 10px solid;
      background-color: ${(p) => p.theme.color.gray50};
      text-align: center;
      display: block;
      &::before {
        content: none;
      }
      > div {
        display: block;
        > span {
          padding: 0 5px;
        }
      }
      margin-bottom: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  ${stylesBlocks}
`;

export type Col<T extends Row = Row> = {
  label: string;
  name: string;
  render?: (props: { row: T; index: number }) => ReactNode;
  append?: ReactNode;
  prepend?: ReactNode;
  sx?: Sx;
  style?: CSSProperties;
  fn?: Formatter<T>;
  props?: ComponentPropsWithoutRef<"td">;
  filter?: ReactNode;
  filtered?: boolean;
};

type TableContainerProps<T extends Row = Row> = {
  empty: boolean;
  status: Status;
  cols: Col<T>[];
  data: T[];
  tableProps?: ComponentPropsWithoutRef<"table">;
  checkbox?: boolean;
  moreOptions?: RowAction<T>[];
  onChecked?: (rows: T[]) => void;
  identifier?: keyof T;
};

function TableContainer<T extends Row = Row>({
  empty = false,
  status,
  cols,
  data = [],
  tableProps = {},
  checkbox = false,
  moreOptions,
  identifier = "id",
  onChecked = () => {},
  ...rest
}: TableContainerProps<T>) {
  const [checked, setChecked] = useState<boolean[]>([]);
  const [all, setAll] = useState(false);

  useEffect(() => {
    setChecked(new Array(data.length).fill(false));
  }, [data]);

  // useEffect(() => {
  //   onChecked(checked.map((v, i) => data[i]));
  // }, [checked, onChecked, data]);

  function formatData(value: any, formatType: Formatter<T>, row: T) {
    // if (!formatType) return value?.toString();
    switch (formatType) {
      case "number":
        return (
          <Box as="span" sx={{ ta: "right", d: "block" }}>
            {value}
          </Box>
        );
      case "count":
        return value.length;
      case "date":
        return format(new Date(value), "MMMM dd, yyyy");
      case "datetime":
        return format(new Date(value), "MMMM dd, yyyy hh:mm aaa");
      case "currency":
        return currency(value);
      case "link":
        return (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      default:
        return formatType(value, row);
    }
  }

  const renderCell = (col: Col<T>, i: number) => {
    if (col.render) {
      const Component = col.render;
      return <Component row={data[i]} index={i} />;
    }
    return (
      <Type sx={col.sx || {}} style={col.style || {}}>
        {col.prepend}
        {col.fn
          ? formatData(accessProperties(data[i], col.name), col.fn, data[i])
          : accessProperties(data[i], col.name)?.toString()}
        {col.append}
      </Type>
    );
  };

  const handleCheckAll = () => {
    if (all) {
      setAll(false);
      setChecked((checked) => [...checked.fill(false)]);
    } else {
      setAll(true);
      setChecked((checked) => [...checked.fill(true)]);
    }
    // onChecked(checked)
  };

  const handleCheckbox = (index: number) => {
    setAll(false);
    var count = 0;
    setChecked((checked) => {
      const a = checked.map((c, i) => {
        var v = i === index ? !c : c;
        if (v) count++;
        return v;
      });
      if (count === checked.length) setAll(true);
      return a;
    });
  };

  if (!data || !checked) return;

  return (
    <>
      {status === BaseStatus.Fetching && (
        <Box
          abs={{ t: 120, l: "50%" }}
          sx={{ tr: "translate(0, -50%)", z: 9999 }}
        >
          <Loading />
        </Box>
      )}
      <Responsive {...rest}>
        <Box className="tableWrapper">
          <Table {...{ size: "small", ...tableProps }}>
            <thead>
              <tr>
                {checkbox && (
                  <th>
                    <Checkbox
                      inputProps={{
                        checked: all,
                        onChange: handleCheckAll,
                        name: "all",
                        value: "all",
                      }}
                    />
                  </th>
                )}
                {cols.map((col) => (
                  <th key={col.label} {...col.props}>
                    {col.filter ? (
                      <Dropdown content={col.filter} space={15}>
                        <span>
                          <Button
                            variant={col.filtered ? "outlined" : "text"}
                            iconName="angleDown"
                            bg="white"
                          >
                            {col.label}
                          </Button>
                        </span>
                      </Dropdown>
                    ) : (
                      col.label
                    )}
                  </th>
                ))}
                {moreOptions && <th></th>}
              </tr>
            </thead>
            <tbody>
              {!empty ? (
                data.map((row, i) => (
                  <tr
                    key={`${row.id}${i}`}
                    className={checked[i] ? "active" : ""}
                  >
                    {checkbox && (
                      <td>
                        <Checkbox
                          inputProps={{
                            name: "row" + i,
                            value: (row[identifier] as string | number) || "",
                            checked: checked[i] || false,
                            onChange: () => handleCheckbox(i),
                          }}
                        />
                      </td>
                    )}
                    {cols.map((col) => (
                      <td data-label={col.label + ":"} key={col.label}>
                        {renderCell(col, i)}
                      </td>
                    ))}
                    {moreOptions && (
                      <td className="actions">
                        <MoreOptions row={row} moreOptions={moreOptions} />
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    align="center"
                    colSpan={
                      cols.length +
                      Number(Boolean(moreOptions)) +
                      Number(Boolean(checkbox))
                    }
                  >
                    <Type>No data available</Type>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
      </Responsive>
    </>
  );
}

// export default memo(TableContainer);

const MemoTableContainer = memo(TableContainer) as typeof TableContainer;
export default MemoTableContainer;
