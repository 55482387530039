import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Pagination, TableContainer, Type } from "ui";
import { Access } from "services/auth";
import { Col } from "ui/tableContainer/TableContainer";
import { User } from "./type";
import EditRoles from "./components/EditRoles";
import useApi from "./useApi";
import { useQuery } from "@tanstack/react-query";
import { BaseStatus } from "types/types";
import RemoveUser from "./components/RemoveUser";

const cols: Col<User>[] = [
  { label: "Email", name: "email", props: { style: { width: 400 } } },
  {
    label: "Name",
    name: "full name",
    render: ({ row }) => (
      <Type>
        {row.first_name} {row.last_name}
      </Type>
    ),
    props: { style: { width: 360 } },
  },
  {
    label: "Roles",
    name: "roles",
    render: EditRoles,
  },
  {
    label: "Created",
    name: "created_on",
    fn: 'datetime',
  },
];

function UsersList() {
  const navigate = useNavigate();
  const { getUsers, page, setPage } = useApi();

  const { data, isPending } = useQuery({
    queryKey: ["users", page + 1],
    queryFn: () => getUsers(page),
    placeholderData: (previousData) =>
      previousData || { results: [], count: 0 },
  });

  const handleAdd = () => navigate("new");

  const { results: list, count } = data;

  return (
    <>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Users List
        </Type>

        <Access path="/products/new">
          <Button onClick={handleAdd} iconName="plus">
            New User
          </Button>
        </Access>
      </Flex>

      <Box>
        <TableContainer<User>
          empty={list.length === 0}
          status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
          aria-label="User table"
          cols={cols}
          data={list}
          moreOptions={[
            {
              name: "delete",
              render: RemoveUser,
            },
          ]}
        />

        <Pagination count={count} page={page} onPageChange={setPage} />
      </Box>
    </>
  );
}

export default memo(UsersList);
