import { useRequest } from "services/request";
import { TagFetch } from "./types";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export default function useApi() {
  const [page, setPage] = useState(0);
  const req = useRequest();

  async function getTags(page = 0): Promise<TagFetch> {
    return await req({url: `tags/?page=${page + 1}`, withAuth: true});
  }

  const query = useQuery({
    queryKey: ["tags", page + 1],
    queryFn: () => getTags(page),
  });

  const fetch = { ...query, page, setPage };

  return {
    fetch,
  };
}
