import { create } from "zustand";

type StoreState = {
  spec: SpecState;
  field: FieldState;
  product: ProductState;
  changeSpec: (newState: Partial<SpecState>) => void;
  changeField: (newState: Partial<FieldState>) => void;
  changeProduct: (newState: Partial<ProductState>) => void;
};

type SpecState = {
  count: number;
  page: number;
  perPage: number;
  selected: number | null;
};

type ProductState = {
  count: number;
  page: number;
  perPage: number;
};

type FieldState = {
  page: number;
  perPage: number;
};

const useStore = create<StoreState>((set) => ({
  spec: {
    count: 0,
    page: 0,
    perPage: 8,
    selected: null,
  },
  field: {
    page: 0,
    perPage: 10,
  },
  product: {
    count: 0,
    page: 0,
    perPage: 10,
  },
  changeSpec: (newState) =>
    set((state) => ({ spec: { ...state.spec, ...newState } })),
  changeField: (newState) =>
    set((state) => ({ field: { ...state.field, ...newState } })),
  changeProduct: (newState) =>
    set((state) => ({ product: { ...state.product, ...newState } })),
}));

export default useStore;
