import {
  Dispatch,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";
import { BaseStatus, Status } from "types/types";

export enum WalletActionType {
  SetPublicKey,
  SetPrivateKey,
  SetFinancialInfo,
  SetHasApproved,
  ChangeStatus,
}

type Action =
  | { type: WalletActionType.SetPublicKey; payload: string }
  | { type: WalletActionType.SetPrivateKey; payload: string }
  | { type: WalletActionType.SetFinancialInfo; payload: any }
  | { type: WalletActionType.SetHasApproved; payload: boolean }
  | { type: WalletActionType.ChangeStatus; payload: Status };

type State = {
  publicKey: string | null;
  privateKey: string | null;
  financialInfo: any;
  hasApproved: boolean | null;
  status: Status;
};

type ContextType = {
  state: State;
  dispatch: Dispatch<Action>;
  getFinancialInfo: () => Promise<any>;
  fetchHasApproved: () => Promise<any>;
};

const Context = createContext<ContextType | null>(null);

export function useWallet() {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      "useWallet has to be used within <WalletProvider>. One possible solution is to add <WalletProvider> to providers.js in /services"
    );

  return context;
}

const initState: State = {
  privateKey: null,
  publicKey: null,
  financialInfo: null,
  hasApproved: null,
  status: BaseStatus.Idle,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case WalletActionType.SetPublicKey:
      return { ...state, publicKey: action.payload };
    case WalletActionType.SetPrivateKey:
      return { ...state, privateKey: action.payload };
    case WalletActionType.SetFinancialInfo:
      return { ...state, financialInfo: action.payload };
    case WalletActionType.SetHasApproved:
      return { ...state, hasApproved: action.payload };
    case WalletActionType.ChangeStatus:
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type`);
  }
};

export default function WalletProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const auth = useAuth();
  const req = useRequest();

  useEffect(() => {
    dispatch({
      type: WalletActionType.SetPublicKey,
      payload:
        auth.user?.manufacturer?.identity_address ||
        window.localStorage.getItem("wallet_public_key"),
    });
  }, [auth.user]);

  const getFinancialInfo = useCallback(() => {
    return new Promise(async (resolve) => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user.manufacturer.identity_address) return resolve(null);
      dispatch({
        type: WalletActionType.ChangeStatus,
        payload: BaseStatus.Fetching,
      });
      const resData = await req({
        url: `wallets/${user.manufacturer.identity_address}/financial-info/`,
        withAuth: true
      });
      dispatch({ type: WalletActionType.SetFinancialInfo, payload: resData });
      dispatch({
        type: WalletActionType.ChangeStatus,
        payload: BaseStatus.Idle,
      });
      resolve(resData);
    });
  }, [req, state.publicKey]);

  const fetchHasApproved = useCallback(() => {
    return new Promise(async (resolve) => {
      dispatch({
        type: WalletActionType.ChangeStatus,
        payload: BaseStatus.Fetching,
      });
      const resData = await req({
        url: `identities/${state.publicKey}/is-approved/`,
        withAuth: true
      });
      dispatch({
        type: WalletActionType.SetHasApproved,
        payload: resData.approved,
      });
      dispatch({
        type: WalletActionType.ChangeStatus,
        payload: BaseStatus.Idle,
      });
      resolve(resData);
    });
  }, [req, state.publicKey]);

  return (
    <Context.Provider
      value={{ state, getFinancialInfo, fetchHasApproved, dispatch }}
    >
      {children}
    </Context.Provider>
  );
}
