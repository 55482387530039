import { useState } from "react";
import { useEffect } from "react";
import request from "../../services/request";

function useImageLink(hash: string, type = "image"): string | null {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!hash) return;
    (async () => {
      const response = await request({
        url: `upload/image/?key=${hash}`,
        options: {
          headers: {
            "Content-Type": type,
          },
        },
        withAuth: true
      });
      const imageBlob = await response.blob();
      setUrl(URL.createObjectURL(imageBlob));
    })();
  }, [hash, type]);

  return url;
}

export default useImageLink;
