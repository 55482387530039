import { memo, useState } from "react";
import { Box, Button, Flex, Pagination, TableContainer, Type } from "ui";
import { CSVDownload } from "react-csv";
import { Col } from "ui/tableContainer/TableContainer";
import useApi from "./useApi";
import { useQuery } from "@tanstack/react-query";
import { BaseStatus } from "types/types";
import { Report } from "./types";

const cols: Col<Report>[] = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  {
    label: "Total minted items",
    name: "minted_items_count",
    sx: { ta: "right" },
    props: { style: { textAlign: "right" } },
  },
  {
    label: "Total bound items",
    name: "bound_items_count",
    sx: { ta: "right" },
    props: { style: { textAlign: "right" } },
  },
  {
    label: "Total verified transactions",
    name: "verification_transactions_count",
    sx: { ta: "right" },
    props: { style: { textAlign: "right" } },
  },
  {
    label: "Countries",
    name: "countries",
    render: ({ row }) => (
      <Type>
        {row.countries.map((c: string, i: number) =>
          i === 0 ? c : c ? ", " + c : ""
        )}
      </Type>
    ),
  },
];

function List() {
  const [csvData, setCsvData] = useState([]);
  const { getReports, page, setPage, pageSize } = useApi();

  const { data, isPending } = useQuery({
    queryKey: ["reports", page + 1, pageSize],
    queryFn: () => getReports(page),
    placeholderData: { results: [], count: 0 },
  });

  const fetchAll = useQuery({
    queryKey: ["reports", page + 1, 9999],
    queryFn: () => getReports(page, 9999),
    enabled: false,
  });

  const handleChangePage = (newPage: number): void => setPage(newPage);

  const handleExport = () => {
    (async () => {
      const query = await fetchAll.refetch();
      setCsvData(query.data.results);
    })();
  };

  const { results: list, count } = data;

  return (
    <>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Products Report
        </Type>

        <Button onClick={handleExport} iconName="exportFile">
          Export
        </Button>

        {Boolean(csvData.length) && (
          <CSVDownload data={csvData} target="_blank" />
        )}
      </Flex>

      <Box>
        <TableContainer
          empty={list?.length === 0}
          data={list}
          status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
          aria-label="Product table"
          cols={cols}
        />

        <Box sx={{ mt: 20 }}>
          <Pagination
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
          />
        </Box>
      </Box>
    </>
  );
}

export default memo(List);
