import { memo } from "react";
import { useNavigate } from "react-router-dom";
import UserForm from "./UserForm";
import { useUtils } from "../../services/utilsProvider";
import { Box, Flex, Type, Link } from "ui";

function AddUser() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = () => {
    navigate(`/users`);
    notify(
      "User saved successfully, An email has been sent to user on how to set password",
      "success"
    );
  };

  return (
    <Box sx={{ mxw: 500, p: 2 }}>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Dashboard</Link>
        <Type>-</Type>
        <Link to="/users">Users</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Add new user
      </Type>
      <Type sx={{ mt: 10, mxw: 520 }}>
        The new user will recieve, by email, the intruction on how to set a
        password for his newly created account.
      </Type>
      <Box sx={{ mt: 40, p: 0 }}>
        <UserForm handleSuccess={handleSuccess} />
      </Box>
    </Box>
  );
}

export default memo(AddUser);
