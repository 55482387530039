import UserAccount from "./UserAccount";
import { Flex, Link } from "../../ui";
import Logo from "layout/sidebar/Logo";
import ToggleNav from "./ToggleNav";
import useMedia from "utilities/hooks/useMedia";
import theme from "theme";

type HeaderProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Header({ setOpen }: HeaderProps) {
  const media = useMedia();

  return (
    <Flex
      as="header"
      jc="space-between"
      gap={[20, 20, 20, 0]}
      sx={{
        gc: "1 / 3",
        px: 20,
        py: 8,
        bgc: "black",
        pos: "sticky",
        top: 0,
        h: 68,
        z: 9999
      }}
    >
      <Flex gap={15}>
        {media <= theme.resolution.sm && <ToggleNav setOpen={setOpen} />}
        <Link as={Link} to="/">
          <Logo />
        </Link>
      </Flex>
      <UserAccount />
    </Flex>
  );
}

export default Header;
