import { memo } from "react";
import { Flex, Icon, Loading, Tooltip } from "ui";
import Dropdown from "ui/core/Dropdown";
import useSpecApi from "./useSpecApi";
import { Spec } from "./types";

function RowActions({ spec }: { spec: Spec }) {
  const { remove, clone } = useSpecApi();

  if (!spec) return "loading";

  return (
    <Flex gap={12}>
      <Dropdown clickable={false} content={<Tooltip>Clone</Tooltip>}>
        {clone.isPending ? (
          <Loading size={15} />
        ) : (
          <span>
            <Icon
              c="gray600"
              hover="gray200"
              w={16}
              name="copy"
              onClick={() => clone.mutate(spec.id)}
              aria-label="clone spec"
            />
          </span>
        )}
      </Dropdown>
      {spec.locked ? (
        <Dropdown clickable={false} content={<Tooltip>Locked</Tooltip>}>
          <span>
            <Icon
              hover="gray200"
              c="gray500"
              w={14}
              name="lock"
              disabled
              aria-label="locked spec"
            />
          </span>
        </Dropdown>
      ) : (
        <Dropdown clickable={false} content={<Tooltip>Delete</Tooltip>}>
          {remove.isPending ? (
            <span>
              <Loading size={15} />
            </span>
          ) : (
            <span>
              <Icon
                hover="gray200"
                name="trash"
                c="red"
                w={14}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    window.confirm(
                      `Are you sure you want to delete spec ${spec.name}?`
                    )
                  )
                    remove.mutate(spec.id);
                }}
                aria-label="delete spec"
              />
            </span>
          )}
        </Dropdown>
      )}
    </Flex>
  );
}

export default memo(RowActions);
