import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_APT_KEY } from "config";

const containerStyle = {
  width: "600px",
  height: "600px",
};

type TransactionMapProps = {
  lng: number;
  lat: number;
};

function TransactionMap({ lng, lat }: TransactionMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_APT_KEY,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat, lng }}
      zoom={15}
      // onUnmount={onUnmount}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default TransactionMap;
