import { Outlet } from "react-router-dom";
import ScrollToTop from "./utilities/hooks/ScrollToTop";
import Layout from "layout/Layout";

const App = () => (
  <Layout>
    <ScrollToTop />
    <Outlet />
  </Layout>
);

export default App;
