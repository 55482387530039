import { memo, useEffect } from "react";
import { Box, Button, Card, Icon, InputText, Type } from "ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "services/utilsProvider";
import useSpecApi from "./useSpecApi";

const YupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

type FormProps = {
  spec: any;
  edit: boolean;
  onSuccess: () => void;
};

function Form({ spec, edit, onSuccess }: FormProps) {
  const { create, edit: editSpec } = useSpecApi();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    // getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(YupSchema),
    defaultValues: spec,
  });

  useEffect(() => {
    reset(spec);
  }, [spec, reset]);

  const mutation = edit ? editSpec : create;

  const onSubmit = async (values: any) => {
    try {
      mutation.mutate(values["name"], {
        onSettled: (data) => {
          reset();
          notify(`Spec ${edit ? "updated" : "added"} successfully`, "success");
          onSuccess();
        },
      });
    } catch (e: any) {
      console.log(e);
      notify(e.errors["non_field_errors"].join(", "), "alert");
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Box sx={{ ta: "center", py: 30, minw: 300 }}>
          <Icon name="schema" w={18} />
          <Type var="h6" as="h5" sx={{ mt: 10 }}>
            {edit ? `Editing Spec "${spec.name}"` : "Create Spec"}
          </Type>
        </Box>
        <InputText
          // inputRef={inputEl}
          id="name"
          label="Spec name"
          error={!!errors.name}
          msg={errors.name?.message as string}
          inputProps={{
            ...register("name"),
            autoFocus: true,
            required: true,
          }}
        />

        <Button
          sx={{ w: "100%", ai: "center", mt: 20 }}
          // onClick={handleSubmit}
          loading={mutation.isPending}
        >
          {edit ? "Save" : "Create"}
        </Button>
      </Card>
    </form>
  );
}

export default memo(Form);
