import { Color } from "theme";
import { Box, Card, Flex, Icon, Type } from "ui";

type AnalyticEcommerceProps = {
  title: string;
  count: number;
  percentage: number;
  color?: Color;
  extra?: number
}

const AnalyticEcommerce = ({ color, title, count, percentage, extra }: AnalyticEcommerceProps) => (
  <Card elevation={0} bg="transparent" padding="0">
    <Box sx={{ mt: 0 }}>
      <Type
        sx={{
          text: "uppercase",
          fs: 12,
          fw: 700,
          c: "gray800",
          mb: 22,
          lh: 14,
        }}
      >
        {title}
      </Type>
      <Flex jc="space-between">
        <Type var="h2" sx={{ pb: 0, lh: 26 }}>
          {count}
        </Type>

        {percentage < 0 && (
          <Flex gap={10}>
            <Icon name="circleDown" w={18} c="red" />
            <Type sx={{ c: "red", fw: 600 }}>
              {Number.parseFloat(percentage.toString()).toFixed(2)}%
            </Type>
          </Flex>
        )}
        {percentage > 0 && (
          <Flex gap={10}>
            <Icon name="circleUp" w={18} c="green" />
            <Type sx={{ c: "green", fw: 600 }}>
              {Number.parseFloat(percentage.toString()).toFixed(2)}%
            </Type>
          </Flex>
        )}
      </Flex>
    </Box>
    {extra && (
      <Box sx={{ pt: 2.25 }}>
        <Type var="caption">
          You made an extra{" "}
          <Type
            as="span"
            var="caption"
            sx={{ c: `${color || "primary"}` }}
          >
            {extra}
          </Type>{" "}
          this year
        </Type>
      </Box>
    )}
  </Card>
);

export default AnalyticEcommerce;
