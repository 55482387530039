import { createContext, useReducer, useContext, Dispatch, ReactNode } from "react";
import Snackbar from "../ui/Snackbar";
import { Icon } from "ui";
import { IconType } from "ui/Icon";

const ICON_TYPE_NAME: {[k in Severity]: IconType} = {
  success: "doubleCheck",
  alert: "circleExclamation",
  info: "circleExclamation",
};

export enum UtilsActionType {
  SetAlert = "set_alert",
}

export type Severity = "success" | "alert" | "info";

type Alert = {
  open: boolean;
  content: ReactNode;
  autoHideDuration?: number;
  severity?: Severity;
  position?: {
    vertical?: "bottom" | "top" | "center";
    horizontal?: "left" | "right" | "center";
  };
};

type Action = { type: UtilsActionType.SetAlert; payload: Alert };

type UtilsState = {
  alert: Alert;
};

type UtilsContextType = {
  state: UtilsState;
  dispatch: Dispatch<Action>;
  notify: (content: string, severity: Severity) => void;
};

const UtilsContext = createContext<UtilsContextType | null>(null);

export function useUtils() {
  const utilsContext = useContext(UtilsContext);

  if (!utilsContext)
    throw new Error(
      "useUtils has to be used within <UtilsProvider>. One possible solution is to add <UtilsProvider> to providers.js in /services"
    );

  return utilsContext;
}

const initState: UtilsState = {
  alert: {
    open: false,
    content: "",
    autoHideDuration: 4000,
    severity: "success",
    position: { vertical: "bottom", horizontal: "center" },
  },
};

const reducer = (state: UtilsState, action: Action): UtilsState => {
  switch (action.type) {
    case UtilsActionType.SetAlert:
      return { ...state, alert: { ...state.alert, ...action.payload } };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UtilsProvider({ children }: {children: ReactNode}) {
  let [state, dispatch] = useReducer(reducer, initState);

  const handleClose = (): void => {
    dispatch({ type: UtilsActionType.SetAlert, payload: { open: false, content: "" } });
  };

  const notify = (content: string, severity: Severity) => {
    setTimeout(handleClose, state.alert.autoHideDuration);
    const content2 = (
      <span>
        <Icon name={ICON_TYPE_NAME[severity]} w={18} /> {content}
      </span>
    );
    dispatch({
      type: UtilsActionType.SetAlert,
      payload: { open: true, content: content2, severity },
    });
  };

  // const alert = (content) => {
  //   setTimeout(handleClose, state.alert.autoHideDuration);
  //   dispatch({
  //     type: "set_alert",
  //     payload: {
  //       open: true,
  //       content,
  //       severity: "error",
  //       autoHideDuration: 10000,
  //     },
  //   });
  // };

  let value = { state, notify, alert, dispatch };

  return (
    <UtilsContext.Provider value={value}>
      <Snackbar
        open={state.alert.open}
        {...state.alert.position}
        severity={state.alert.severity}
      >
        {state.alert.content}
      </Snackbar>

      {children}
    </UtilsContext.Provider>
  );
}
