import { useQueryClient } from "@tanstack/react-query";
import {
  useContext,
  useReducer,
  createContext,
  useCallback,
  Dispatch,
  ReactNode,
} from "react";
import { useRequest } from "services/request";
import { BaseStatus, Status } from "types/types";
import useSpecApi from "views/specs/useSpecApi";

export type Product = {
  id: number;
  name: string;
  json_data: JsonData;
  manufacturer: Manufacturer;
  product_fields_schema: number;
  item_fields_schema: number;
  tag: Tag;
  locked: boolean;
};
export type JsonData = {
  [key: number]: number | string | boolean;
};
interface Manufacturer {
  id: number;
  name: string;
  identity_address: string;
}
interface Tag {
  id: number;
  name: string;
  stit_based_verification: boolean;
  write_verification_hash: boolean;
  tag_type: number;
}

export enum ProductsActionType {
  SetSelect = "set_product",
  SetList = "set_list",
  ChangeStatus = "status",
  SetCount = "set_count",
  ChangePage = "set_page",
  ChangePerPage = "set_per_page",
  Add = "add",
  Edit = "edit",
  Delete = "delete",
}

type Action =
  | { type: ProductsActionType.SetList; payload: any[] }
  | { type: ProductsActionType.SetSelect; payload: any }
  | { type: ProductsActionType.ChangeStatus; payload: Status }
  | { type: ProductsActionType.SetCount; payload: number }
  | { type: ProductsActionType.ChangePage; payload: number }
  | { type: ProductsActionType.Add; payload: any }
  | { type: ProductsActionType.Edit; payload: any }
  | { type: ProductsActionType.Delete; payload: number }
  | { type: ProductsActionType.ChangePerPage; payload: number };

type ProductsState = {
  list: any[];
  product: any;
  status: Status;
  error: any;
  count: number;
  page: number;
  perPage: number;
};

type ProductContextType = {
  state: ProductsState;
  dispatch: Dispatch<Action>;
  fetchList: (page?: number, pageSize?: number, keyword?: string) => void;
  fetchProduct: (id: number) => Promise<any>;
  fetchFullProd: (id: number) => Promise<any>;
  createProduct: (data: any, tag: any) => Promise<any>;
  editProduct: (id: number, data: any) => Promise<any>;
  deleteProduct: (id: number) => any;
};

const ProductContext = createContext<ProductContextType | null>(null);

export function useProducts() {
  const productContext = useContext(ProductContext);

  if (!productContext)
    throw new Error(
      "useProducts has to be used within <ProductProvider>. One possible solution is to add <ProductProvider> to providers.js in /services"
    );

  return productContext;
}

const initState: ProductsState = {
  list: [],
  product: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: BaseStatus.Idle,
  error: null,
};

const reducer = (state: ProductsState, action: Action): ProductsState => {
  switch (action.type) {
    case ProductsActionType.SetList:
      return { ...state, list: [...action.payload] };
    case ProductsActionType.SetSelect:
      return { ...state, product: { ...action.payload } };
    case ProductsActionType.SetCount:
      return { ...state, count: action.payload };
    case ProductsActionType.Add:
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case ProductsActionType.Edit:
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case ProductsActionType.Delete:
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case ProductsActionType.ChangeStatus:
      return { ...state, status: action.payload };
    case ProductsActionType.ChangePage:
      return { ...state, page: action.payload };
    case ProductsActionType.ChangePerPage:
      return { ...state, perPage: action.payload };

    // default:
    //   throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProductProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  // const { fetchOne } = useSpecs();
  const { getOne } = useSpecApi();
  const queryClient = useQueryClient();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10, keyword = "") => {
      dispatch({
        type: ProductsActionType.ChangeStatus,
        payload: BaseStatus.Fetching,
      });
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword.length) query += `&keyword=${keyword}`;
      const resData = await req({ url: `products/?${query}`, withAuth: true });
      dispatch({
        type: ProductsActionType.ChangeStatus,
        payload: BaseStatus.Idle,
      });
      dispatch({ type: ProductsActionType.SetList, payload: resData.results });
      dispatch({ type: ProductsActionType.SetCount, payload: resData.count });
    },
    [req]
  );

  const fetchProduct = useCallback(
    (id: number) => {
      return new Promise(async (resolve, reject) => {
        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Fetching,
        });
        const resData = await req({ url: `products/${id}/`, withAuth: true });
        dispatch({ type: ProductsActionType.SetSelect, payload: resData });
        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Idle,
        });
        resolve(resData);
      });
    },
    [req]
  );

  const fetchFullProd = useCallback(
    (id: number) => {
      return new Promise(async (resolve, reject) => {
        const data: any = await fetchProduct(id);

        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Fetching,
        });
        const prodSpec = await queryClient.fetchQuery({
          queryKey: ["spec", data["product_fields_schema"]],
          queryFn: () => getOne(data["product_fields_schema"]),
        });
        data.details = {};
        data.details.productSpec = prodSpec;
        const itemSpec = await queryClient.fetchQuery({
          queryKey: ["spec"],
          queryFn: () => getOne(data["item_fields_schema"]),
        });
        data.details.itemSpec = itemSpec;
        dispatch({ type: ProductsActionType.SetSelect, payload: data });
        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Idle,
        });

        resolve(data);
      });
    },
    [fetchProduct, getOne, queryClient]
  );

  const createProduct = useCallback(
    async (data: any, tag: any) => {
      return new Promise(async (resolve, reject) => {
        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Fetching,
        });
        try {
          const resData = await req({
            url: "products/",
            body: data,
            options: { method: "POST" },
            withAuth: true,
          });
          dispatch({
            type: ProductsActionType.Add,
            payload: { ...resData, tag },
          });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({
            type: ProductsActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
        }
      });
    },
    [req]
  );

  const editProduct = useCallback(
    async (id: number, data: any) => {
      return new Promise(async (resolve, reject) => {
        dispatch({
          type: ProductsActionType.ChangeStatus,
          payload: BaseStatus.Fetching,
        });
        try {
          const resData = await req({
            url: `products/${id}/`,
            body: data,
            options: { method: "PATCH" },
            withAuth: true,
          });
          dispatch({ type: ProductsActionType.Edit, payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({
            type: ProductsActionType.ChangeStatus,
            payload: BaseStatus.Idle,
          });
        }
      });
    },
    [req]
  );

  const deleteProduct = useCallback(
    async (id: number) => {
      dispatch({
        type: ProductsActionType.ChangeStatus,
        payload: `${BaseStatus.Deleting}_${id}`,
      });
      await req({
        url: `products/${id}/`,
        options: { method: "DELETE" },
        withAuth: true,
      });
      dispatch({
        type: ProductsActionType.ChangeStatus,
        payload: BaseStatus.Idle,
      });
      dispatch({ type: ProductsActionType.Delete, payload: id });
    },
    [req]
  );

  return (
    <ProductContext.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchProduct,
        createProduct,
        editProduct,
        deleteProduct,
        fetchFullProd,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}
