import { useEffect, useState } from "react";
import { useAuth } from "services/auth";
import { Box, Card, Flex, List, Loading, Type } from "ui";

function BrandInfo() {
  const [iProfile, setIProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();

  useEffect(() => {
    (async function () {
      const data = await auth.getIdentity();
      setIProfile(data);
      setLoading(false);
    })();
  }, [auth]);

  return (
    <Box>
        <Card>
          <Type var="h5" as="h5" sx={{mb: 5}}>Brand Idenetity info</Type>
          <Type sx={{mb: 10}}>
            This is the brand information that you provided while creating your
            wallet.
          </Type>
          {!loading ? (
            <List>
              <Flex as="li" jc="space-between">
                <Type>Profile Logo</Type>
                <img
                  width={160}
                  src={`${auth.user.configs.ipfs_base_url}/${iProfile.image}`}
                  alt=""
                />
              </Flex>
              <Flex as="li" jc="space-between">
                <Type>Identity Name</Type>
                <Type>{iProfile.name}</Type>
              </Flex>
              <Flex as="li" jc="space-between">
                <Type>Brand Name</Type>
                <Type>{iProfile.brand}</Type>
              </Flex>
              <Flex sx={{py: 14}} jc="space-between" ai="flex-top" gap={40}>
                <Type>Description</Type>
                <Type>{iProfile.description}</Type>
              </Flex>
            </List>
          ) : (
            <Flex>
              <Loading />
            </Flex>
          )}
        </Card>
    </Box>
  );
}

export default BrandInfo;
