import { ComponentPropsWithoutRef } from "react";
import { Box } from "../ui";

interface LayoutMaskedPRops extends ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
};

function LayoutMasked({ children }: LayoutMaskedPRops) {
  return (
    // @ts-ignore
    <Box sx={{ h: "100vh", w: "100vw" }} style={{ overflowX: "hidden" }}>
      {children}
    </Box>
  );
}

export default LayoutMasked;
