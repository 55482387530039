import { Box, Grid, Loading, Pagination, Type } from "ui";
import SpecRow from "./SpecRow";
import useSpecApi from "./useSpecApi";
import { useQuery } from "@tanstack/react-query";
import useStore from "useStore";
import Fields from "views/fields/Fields";
import { Spec } from "./types";
import { useEffect } from "react";

export default function List({ onEdit }: { onEdit: (spec: Spec) => void }) {
  const changeSpec = useStore((state) => state.changeSpec);
  const { count, page, perPage, selected } = useStore((state) => state.spec);
  const changeField = useStore((state) => state.changeField);

  const { getList } = useSpecApi();

  const { data: list, isPending, isFetching } = useQuery({
    queryKey: ["specs", page + 1],
    queryFn: () => getList({ page }),
    placeholderData: (prevData) => prevData || [],
  });

  useEffect(() => {
    if (list.length && !list.map((l) => l.id).includes(selected) && !isFetching)
      changeSpec({ selected: list[list.length - 1].id });
  }, [list, selected, changeSpec, isFetching]);

  const handleToggle = (id: number): void => {
    changeSpec({ selected: id });
    changeField({ page: 0 });
  };

  return (
    <Grid cols={["1fr 3fr", "1fr 3fr", "1fr 3fr", "100%"]} gap={30}>
      <Box>
        {isFetching && <Box sx={{fl: 'right', mt: 17, mr: 20 }}>
          <Loading size={20} />
        </Box>}
        <Box sx={{ w: "100%", bgc: "white", br: 4, mb: 16 }}>
          <Type
            var="h6"
            as="h6"
            sx={{ p: 15, border: ["gray300", 1, "solid", 0, "b"] }}
          >
            All Specs
          </Type>
          {list.map((item) => (
            <SpecRow
              key={item.id}
              spec={item}
              role="listitem"
              onToggle={handleToggle}
            />
          ))}

          {!list.length && !isPending && (
            <Type sx={{ py: 20, ta: "center" }}>
              No spec has been created yet.
            </Type>
          )}
        </Box>
        <Pagination
          count={count}
          page={page}
          pager={false}
          info={true}
          rowsPerPage={perPage}
          onPageChange={(page) => changeSpec({ page })}
        />
      </Box>

      {selected && <Fields onEdit={onEdit} spec={list.find((l) => l.id === selected)} />}
    </Grid>
  );
}
