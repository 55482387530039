export enum FieldType {
  number = 1,
  string,
  boolean,
}

export type Field = {
  id: number;
  name: string;
  field_type: FieldType;
  is_required: boolean;
};