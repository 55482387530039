import { useEffect, useState } from "react";
import { useRequest } from "../../services/request";
import { WalletActionType, useWallet } from "./Provider";
import { Box, Button, Grid, InputText, Type } from "ui";
import { encryptMessage } from "utilities/encrypt-aes-cbc";
import shuffle from "utilities/shuffle";

// const wordsFieldStyles = {
//   display: "grid",
//   gridTemplateColumns: {
//     xs: "repeat(2, 1fr)",
//     md: "repeat(4, 1fr)",
//   },
//   rowGap: "20px",
//   p: 2,
//   my: 4,
//   "& section > div:first-of-type": {
//     padding: "4px",
//     margin: "4px",
//   },
//   "& section > div:last-of-type": {
//     color: "#666",
//     fontSize: "13px",
//   },
// };

type WalletConfirmProps = {
  next: () => void;
  words: string[];
  password: string;
};

function WalletConfirm({ next, words, password }: WalletConfirmProps) {
  const [phrase, setPhrase] = useState(Array(words.length).fill(""));
  const [shuffled, setShuffled] = useState([]);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const req = useRequest();
  const {
    state: { privateKey },
    dispatch,
  } = useWallet();

  useEffect(() => {
    setShuffled(shuffle(words));
  }, [words]);

  const handleContinue = async () => {
    setLoading(true);
    try {
      const resData = await req({
        url: `wallets/`,
        body: { mnemonic: words.join(" ") },
        options: { method: "POST" },
        withAuth: true
      });
      dispatch({ type: WalletActionType.SetPublicKey, payload: resData.public_key });
      dispatch({ type: WalletActionType.SetPrivateKey, payload: resData.private_key });
      window.localStorage.setItem("wallet_public_key", resData.public_key);
      const cipherPack = await encryptMessage(privateKey, password);
      const cipherPackBuffer = new Uint8Array(cipherPack);
      window.localStorage.setItem("wallet_enc_pack", `[${cipherPackBuffer}]`);
      next();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (i: number, v: string) => {
    setPhrase((p) => {
      p[i] = v;
      if (p.join(" ") === words.join(" ")) {
        setConfirmed(true);
      } else {
        setConfirmed(false);
      }
      return [...p];
    });

    // if (phrase.join(" ") === words.join(" ")) {
    //   setConfirmed(true);
    // } else {
    //   setConfirmed(false);
    // }
  };

  return (
    <div id="create-password">
      <Box sx={{ mt: 60, ta: "center" }}>
        <Type var="h5" sx={{ mb: 10 }}>
          Confirm Recovery Phrase
        </Type>
        <Type sx={{ mxw: 400, mx: "auto", mb: 40 }}>
          Select each phrase in the order it was presented to you to make sure
          it is correct.
        </Type>

        <Grid
          cols={[
            "repeat(6, 1fr)",
            "repeat(4, 1fr)",
            "repeat(3, 1fr)",
            "repeat(2, 1fr)",
          ]}
          rowGap={20}
          sx={{ p: 20, my: 0 }}
        >
          {shuffled.map((w, i) => (
            <section key={w}>
              <Box sx={{ p: 4, m: 4 }}>
                <InputText
                  value={phrase[i]}
                  inputProps={{
                    onChange: (e) => handleChange(i, e.target.value),
                    style: { marginBottom: 0 },
                  }}
                />
              </Box>
              <Box sx={{ c: "gray600", fs: 13 }}>{i + 1}</Box>
            </section>
          ))}
        </Grid>

        <Button
          disabled={!confirmed}
          sx={{ mt: 30 }}
          onClick={handleContinue}
          loading={loading}
          iconName="arrowRight"
        >
          Continue
        </Button>
      </Box>
    </div>
  );
}

export default WalletConfirm;
