import styled from "styled-components";
import Box from "./Box";

type AvatarProps = {
  size: number;
  c?: string;
  var?: "dark" | "light";
};

export default styled(Box)<AvatarProps>`
  width: ${(p) => p.size || 40}px;
  height: ${(p) => p.size || 40}px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.color[p.c || "gray200"]};
  transition: all 0.2s ease-in;
  outline: ${(p) =>
      p.var === "dark" ? p.theme.color.black : p.theme.color.white}22
    0px solid;
  border: ${(p) => p.theme.color[p.c || "gray200"]} 3px solid;
  img {
    width: 100%;
  }
  &:hover {
    outline: ${(p) =>
        p.var === "dark" ? p.theme.color.white : p.theme.color.white}22
      4px solid;
  }
`;
