import { useState, useRef, memo } from "react";
import { Access } from "../../services/auth";
import { Box, Button, Flex, Icon, Type } from "ui";
import Modal from "ui/Modal";
import List from "./List";
import SpecForm from "./Form";
import type { Spec } from "./types";

function Specs() {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [spec, setSpec] = useState<Spec>(null);

  const handleEdit = (spec: Spec) => {
    setEdit(true);
    setSpec(spec);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setSpec(null);
  };

  const onSuccess = handleClose;

  return (
    <>
      <Flex
        jc="space-between"
        ai={["center", "center", "center", "flex-start"]}
        gap={20}
        flexDir={["row", "row", "row", "column"]}
        sx={{ mb: 60 }}
      >
        <Box>
          <Type var="h3" as="h3" sx={{ mt: 0, mb: 0 }}>
            Specs
          </Type>
          <Type>Create and manage specs and fields.</Type>
        </Box>
        <Access path="/specs/new">
          <Button onClick={() => setOpen(true)} iconName="schema">
            Add New Spec
          </Button>
        </Access>
      </Flex>

      <Modal show={open}>
        <Box
          abs={{ t: 10, r: 10 }}
          sx={{ z: 999, cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon hover="gray200" name="xmark" w={14} c="black" />
        </Box>
        <SpecForm
          {...{
            inputEl,
            edit,
            spec,
            onSuccess,
          }}
        />
      </Modal>

      <List onEdit={handleEdit} />
    </>
  );
}

export default memo(Specs);
