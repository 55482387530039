import styled, { css } from "styled-components";
import stylesBlocks, { StylesBlocksProps } from "./core/stylesBuilder";
import isPropValid from "@emotion/is-prop-valid";
import { Color } from "theme";

type DividerProps = {
  h?: number;
  w?: number;
  vertical?: boolean;
  border?: Color;
} & StylesBlocksProps;

const Divider = styled.div
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })<DividerProps>`
  ${(p) =>
    p.border
      ? css`
          border-${p.vertical ? "right" : "bottom"}: ${
          p.theme.color[typeof p.border === "string" ? p.border : "gray100"]
        } 1px solid;
        `
      : undefined}
  ${(p) =>
    p.vertical
      ? css`
          margin-left: ${p.w ? p.w / 2 : 0}px;
          margin-right: ${p.w ? p.w / 2 : 0}px;
          height: ${p.h ? p.h + "px" : "auto"};
        `
      : css`
          margin-bottom: ${p.h ? p.h / 2 : 0}px;
          height: ${p.h ? p.h / 2 + "px" : "auto"};
        `}
    ${stylesBlocks}
`;

export default Divider;
