import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { ProductsActionType, useProducts } from "./provider";
import { Access } from "../../services/auth";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import SearchInput from "components/SearchInput";
import { Col } from "ui/tableContainer/TableContainer";
import { Product } from "./types";
import { useQuery } from "@tanstack/react-query";
import useStore from "useStore";
import useProductApi from "./useProductApi";
import { BaseStatus } from "types/types";
import RemoveProduct from "./components/RemoveProduct";

const cols: Col<Product>[] = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  { label: "Tag", name: "tag.name" },
  { label: "Manufacturer", name: "manufacturer.name" },
];

function List() {
  const [term, setTerm] = useState("");
  const navigate = useNavigate();
  const { page, count, perPage } = useStore((state) => state.product);
  const changeProduct = useStore((state) => state.changeProduct);
  const { getList } = useProductApi();

  const { data: list, isPending } = useQuery({
    queryKey: term.length ? ["products", page + 1, term] : ["products", page + 1],
    queryFn: () => getList({ page, keyword: term }),
    placeholderData: (prevData) => prevData || [],
  });

  // const {
  //   // state: { list, status, count, page, perPage },
  //   // dispatch,
  //   // fetchList,
  //   // deleteProduct,
  // } = useProducts();

  // useEffect(() => {
  //   fetchList(page, perPage, term);
  // }, [fetchList, page, perPage, term]);

  // const handleDelete = (id: number) => {
  //   remove.mutate(id);
  // };

  const handleShow = (id: number) => {
    navigate(`/products/${id}/bulks`);
  };

  const handleEdit = (id: number) => {
    navigate(`/products/edit/${id}`);
  };

  const handleAdd = () => navigate("new");

  // const handleChangePage = (newPage: number) =>
  //   dispatch({ type: ProductsActionType.ChangePage, payload: newPage });

  return (
    <>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Products List
        </Type>

        <Access path="/products/new">
          <Button onClick={handleAdd} iconName="plus">
            New Product
          </Button>
        </Access>
      </Flex>

      <Box>
        <Box sx={{ ml: "auto", w: 240 }}>
          <SearchInput setTerm={setTerm} />
        </Box>

        <TableContainer
          empty={list?.length === 0}
          data={list}
          status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
          aria-label="Product table"
          cols={cols}
          moreOptions={[
            {
              name: "show",
              render: ({ id }) => (
                <Icon
                  hover="gray300"
                  name="eye"
                  w={14}
                  onClick={() => handleShow(Number(id))}
                />
              ),
            },
            {
              name: "edit",
              render: ({ id }) => (
                <Icon
                  hover="gray300"
                  name="pen"
                  w={14}
                  onClick={() => handleEdit(Number(id))}
                />
              ),
            },
            {
              name: "delete",
              render: RemoveProduct,
            },
          ]}
        />

        <Pagination
          // sx={{ mt: 20 }}
          data-testid="product-pagination"
          count={count}
          page={page}
          onPageChange={(page) => changeProduct({ page })}
          rowsPerPage={perPage}
        />
      </Box>
    </>
  );
}

export default memo(List);
