import { useState } from "react";
import { useRequest } from "services/request";
import { FetchListResult, Role, User } from "./type";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useApi() {
  const [page, setPage] = useState(0);
  const req = useRequest();
  const queryClient = useQueryClient();

  async function getUsers(page = 0): Promise<FetchListResult> {
    return await req({
      url: `stitchain-users/?page=${page + 1}`,
      withAuth: true,
    });
  }

  async function getRoles(): Promise<Role[]> {
    return await req({ url: `roles/`, withAuth: true });
  }

  const removeUser = useMutation({
    mutationFn: (id: number) => {
      return req({
        url: `stitchain-users/${id}/`,
        options: { method: "DELETE" },
        withAuth: true,
      });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "users" &&
          (query.queryKey[1] as number) >= page + 1,
      });
    },
  });

  const addUser = useMutation({
    mutationFn: async (
      data: Pick<User, "email" | "first_name" | "last_name"> & {
        roles: number[];
      }
    ) => {
      const resData = await req({
        url: "stitchain-users/",
        body: data,
        options: { method: "POST" },
        withAuth: true,
      });

      const roles: Role[] = queryClient.getQueryData(["roles"]);

      const user: User = {
        user_id: resData.user_id,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        roles: data.roles.map((rid: number) => roles.find((r) => r.id === rid)),
      };

      return user;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === "users" &&
          (query.queryKey[1] as number) >= page + 1,
      });
    },
  });

  const editUser = useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: number;
      data: { roles: number[] };
    }) => {
      await req({
        url: `stitchain-users/${id}/`,
        body: data,
        options: { method: "PATCH" },
        withAuth: true,
      });

      const roles: Role[] = queryClient.getQueryData(["roles"]);

      const userRoles: Role[] = data.roles.map((rid) =>
        roles.find((r) => r.id === rid)
      );

      return { id, userRoles };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["users", page + 1],
        (oldData: FetchListResult) => {
          const results = oldData.results.map((user) =>
            user.user_id === data.id ? { ...user, roles: data.userRoles } : user
          );

          return { ...oldData, results };
        }
      );
    },
  });

  return { getUsers, removeUser, addUser, editUser, getRoles, page, setPage };
}
