import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  cloneElement,
  ReactNode,
  ReactElement,
} from "react";
import { useLocation, Navigate, Link, useNavigate } from "react-router-dom";
import { useRequest } from "./request";
import { access } from "../config";

type Manufacturer = {
  id: number;
  name: string;
  identity_address: string;
};

type Configs = {
  ipfs_base_url: string;
  stitchain_wallet: string;
};

export interface Tokens {
  token: string;
  refresh: string;
}

export type User = {
  pk: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  manufacturer: Manufacturer;
  configs: Configs;
  permissions: number[];
  tokens: Tokens;
};

type RoleServer = {
  id: string;
  name: string;
  permissions: {
    [k: string]: string;
  };
};

interface UserServer extends Tokens {
  user: {
    pk: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    manufacturer: Manufacturer;
    configs: Configs;
    roles: RoleServer[];
  };
}

interface ChangePassData {
  newPassword1: string;
  newPassword2: string;
}

interface ResetPassData extends ChangePassData {
  uid: number;
  token: string;
  detail: string;
}

export type AuthContextType = {
  user: User;
  signin: (data: { email: string; password: string }) => Promise<void>;
  changePass: (data: ChangePassData) => Promise<void>;
  forgotPass: (data: { email: string }) => Promise<void>;
  signout: () => Promise<void>;
  editAccount: (
    data: Partial<Omit<User, "pk" | "permission" | "config" | "token">>
  ) => Promise<UserServer>;
  setPass: (data: ResetPassData) => Promise<ResetPassData>;
  getIdentity: () => Promise<any>;
  setUserIdentity: (publicKey: string) => void;
  saveTokens: (newTokens: Tokens) => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth(): AuthContextType {
  const authContext = useContext(AuthContext);

  // if (!authContext)
  //   throw new Error(
  //     "useAuth has to be used within <AuthProvider>. One possible solution is to add <AuthProvider> to providers.js in /services"
  //   );

  return authContext;
}

export default function AuthProvider({ children }: { children: ReactNode }) {
  let [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem("user"))
  );
  const req = useRequest();

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);

  function createLocalUser(user: UserServer) {
    let localUser = {} as User;
    localUser.permissions = user.user.roles
      ?.reduce((a, b) => [...a, ...Object.keys(b.permissions)], [])
      .map((item) => Number(item));
    // localUser.permissions = localUser.permissions.map(item => Number(item))
    localUser.configs = user.user.configs;
    localUser.email = user.user.email;
    localUser.first_name = user.user.first_name;
    localUser.last_name = user.user.last_name;
    localUser.username = user.user.username;
    localUser.pk = user.user.pk;
    localUser.manufacturer = user.user.manufacturer;
    localUser.tokens = { token: user.token, refresh: user.refresh };

    window.localStorage.setItem("user", JSON.stringify(localUser));
    setUser(localUser);
  }

  let signin = useCallback(
    (data: { email: string; password: string }): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          // const formData = new FormData();
          // formData.set("email", data.email);
          // formData.set("password", data.password);

          const res: UserServer = await req({url: `rest-auth/login/`, body: data});
          window.localStorage.setItem(
            "user",
            JSON.stringify({ ...res.user, tokens: res })
          );
          // setUser({ ...omit(res.user, "roles"), tokens: omit(res, "users") });
          createLocalUser(res);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let getIdentity = useCallback((): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const { identity_address } = user.manufacturer;
        const { uri, name } = await req({
          url: `identities/${identity_address}/`,
          withAuth: true
        });
        const ipfsBaseUrl = user.configs.ipfs_base_url;
        const res = await fetch(`${ipfsBaseUrl}/${uri}/`, {
          method: "GET",
          redirect: "follow",
          // headers: {
          //   Authorization: `jwt ${window.localStorage.getItem("token")}`,
          // },
        });

        let profile;

        profile = await fetch(res.url);

        resolve({
          name,
          ...(await profile.json()),
        });
      } catch (error) {
        reject(error);
      }
    });
  }, [req, user]);

  const setUserIdentity = useCallback((publicKey: string): void => {
    const newUser: User = JSON.parse(localStorage.getItem("user"));
    newUser.manufacturer.identity_address = publicKey;
    setUser(newUser);
    window.localStorage.setItem("user", JSON.stringify(newUser));
  }, []);

  let editAccount = useCallback(
    (
      data: Partial<Omit<User, "pk" | "permission" | "config" | "token">>
    ): Promise<UserServer> => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req({
            url: `rest-auth/user/`,
            body: data,
            options: { method: "PATCH" },
            withAuth: true
          });
          createLocalUser(resData);
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (data: ChangePassData): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          await req({
            url: `rest-auth/password/change/`,
            body: data,
            options: { method: "POST" },
            withAuth: true
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let forgotPass = useCallback(
    (data: { email: string }): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          await req({
            url: `rest-auth/password/reset/`,
            body: data,
            options: { method: "POST" },
            withAuth: true
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let setPass = useCallback(
    (data: ResetPassData): Promise<ResetPassData> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req({
            url: `rest-auth/password/reset/confirm/`,
            body: data,
            options: { method: "POST" }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  const saveTokens = useCallback(
    (newTokens: Tokens): void => {
      const userTokens: User = {
        ...user,
        tokens: { ...user.tokens, ...newTokens },
      };
      window.localStorage.setItem("user", JSON.stringify(userTokens));
      setUser(userTokens);
    },
    [user]
  );

  let signout = useCallback((): Promise<void> => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("user");
      setUser(null);
      resolve();
    });
  }, []);

  let value: AuthContextType = {
    user,
    signin,
    changePass,
    forgotPass,
    signout,
    editAccount,
    setPass,
    getIdentity,
    setUserIdentity,
    saveTokens,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }: { children: ReactNode }) {
  const location = useLocation();
  let { user } = useAuth();

  // if (!user) user = JSON.parse(localStorage.getItem("user"));
  const { pathname } = location;

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  if (
    user.manufacturer?.identity_address === "" &&
    location.pathname !== "/wallet/create" &&
    location.pathname !== "/wallet/unlock"
  )
    return <Navigate to="/wallet/create" replace />;

  if (checkAccess(pathname)) return children;

  return <Navigate to="/no-access" replace />;
}

type AccessProps = {
  path: string;
  children: ReactNode;
  type?: "click" | "link";
};

export function Access({ path, children, type, ...props }: AccessProps) {
  const navigate = useNavigate();
  const hasAccess = checkAccess(path);

  if (!hasAccess) return null;

  switch (type) {
    case "click":
      return cloneElement(children as ReactElement, {
        onClick: () => navigate(path),
        ...props,
      });
    case "link":
      return (
        <Link to={path} {...props}>
          {children}
        </Link>
      );
    default:
      return children;
  }
}

function checkAccess(path: string): boolean {
  const user: User = JSON.parse(localStorage.getItem("user"));
  const key: number = getRequiredKey(path);

  if (!key || user?.permissions?.includes(key)) return true;

  return false;
}

function getRequiredKey(path: string): number {
  for (const key in access)
    for (let i = 0; i < access[key].length; i++)
      if (path.match(access[key][i])) return Number(key);

  return null;
}
