import styled, { css } from "styled-components";
import Mask from "./Mask";
import isPropValid from "@emotion/is-prop-valid";
import { BaseHTMLAttributes, ReactNode } from "react";

interface WrapProps extends BaseHTMLAttributes<HTMLDivElement> {
  delay?: number;
  show: boolean;
  x?: number;
  y?: number;
};

const Wrap = styled.div
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs<WrapProps>((p) => ({
    delay: p.delay || 0.1,
  }))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    z-index: 9999;
    min-width: 320px;
    ${(p) =>
      (p.x || p.y) &&
      css`
        position: absolute;
        top: ${p.y}px;
        left: ${p.x}px;
      `}

    transition-property: transform, opacity;
    transition-duration: 0.1s !important;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s;
    transform-origin: center;
    transform: scale(${(p) => (p.show ? 1 : 0.7)});
    opacity: ${(p) => Number(p.show)};
  }
`;

interface ModalProps extends WrapProps {
  onClose?: ((event: React.MouseEvent<HTMLSpanElement>) => void);
  children: ReactNode;
}

function Modal({ children, show, onClose, ...props }: ModalProps) {
  return (
    <Wrap {...props} show={show} role="dialog" className={show ? "active" : ""}>
      <div className="content">{show? children : null}</div>
      <Mask show={show} onClick={onClose ? onClose : undefined} />
    </Wrap>
  );
}

export default Modal;
