import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../services/auth";
import LayoutMasked from "layout/LayoutMasked";
import logo from "../../assets/logo-white.png";
import { Box, Button, InputText, Type } from "ui";

const schema = Yup.object().shape({
  new_password1: Yup.string().min(8, "Too Short!").required("Required"),
  new_password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
});

function SetPass() {
  const [msg, setMsg] = useState("");
  const [countDown, setCountDown] = useState(6);
  const [serverErrors, setServerErrors] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setPass } = useAuth();

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  let countDownInterval: NodeJS.Timeout;
  let redirectTimeout: NodeJS.Timeout;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      clearInterval(countDownInterval);
      clearTimeout(redirectTimeout);
    };
  });

  const onSubmit = async (values: any) => {
    try {
      const data = await setPass({ ...values, uid, token });
      setMsg(data.detail);
      setServerErrors(null);
      redirectTimeout = setTimeout(() => navigate("/login"), 6000);
      countDownInterval = setInterval(() => setCountDown((c) => --c), 1000);
    } catch (e: any) {
      console.log("error", e);
      setServerErrors(e.errors);
      setMsg("");
    }
  };

  if (!uid || !token) {
    navigate("/");
    return;
  }

  return (
    <LayoutMasked>
      <Box center w={500} sx={{ ta: "center", p: 40 }}>
        <Box sx={{ p: 6, br: 2, bgc: "black", d: "inline-block", mb: 30 }}>
          <img
            src={logo}
            alt="stitchain"
            width={40}
            style={{ display: "block" }}
          />
        </Box>
        <Type var="h4" sx={{ my: 30, fw: 500, ta: "left" }}>
          Create a password for your new account
        </Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30, ta: "left" }}
        >
          {serverErrors?.token && (
            <Type sx={{ mt: 30, mb: 20, c: "red" }}>
              The token provided is invalid, please contact administrator.
            </Type>
          )}

          {msg.length ? (
            <Type sx={{ mt: 30, mb: 20, c: "blue" }}>
              {msg}
              <Box sx={{ mt: 10, fs: 13 }}>
                You will be redirected to login page in {countDown} seconds
              </Box>
            </Type>
          ) : (
            ""
          )}

          <InputText
            id="new_password1"
            type="password"
            label="Password"
            error={Boolean(errors.new_password1 || serverErrors?.new_password1)}
            msg={errors.new_password1?.message || serverErrors?.new_password1}
            inputProps={{
              autoFocus: true,
              ...register("new_password1"),
            }}
          />

          <InputText
            id="new_password2"
            type="password"
            label="Confirm Password"
            error={Boolean(errors.new_password2 || serverErrors?.new_password2)}
            msg={errors.new_password2?.message || serverErrors?.new_password2}
            inputProps={{
              ...register("new_password2"),
            }}
          />

          <Button sx={{ mt: 10, mb: 20 }} loading={isSubmitting}>
            Set Password
          </Button>
        </Box>
      </Box>
    </LayoutMasked>
  );
}

export default SetPass;
