import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useProducts } from "./provider";
import { Box, Button, Type } from "ui";
import yupSchemaReducer from "../../utilities/yupSchemaCreator";
import useExpandFieldsFromSpec from "./hooks/useExpandFieldsFromSpec";
import FormFields from "components/FormFields";
import cleanObj from "utilities/cleanObj";
import { FormField } from "types/types";
import useTagApi from "views/tags/useApi";
import useProductApi from "./useProductApi";

type ProductFormProps = {
  product?: any;
  productSpec?: any;
  productSpecFields: any;
  itemSpec?: any;
  handleSuccess: () => void;
};

function ProductForm({
  product = null,
  productSpec,
  productSpecFields,
  itemSpec,
  handleSuccess,
}: ProductFormProps) {
  const [error, setError] = useState<any>(null);
  // we are storing tag object so we can use it on product dispatch
  // because the server return tag attribute as id
  const [tag, setTag] = useState<any>(null);

  const productSpecId = product
    ? product.product_fields_schema
    : productSpec.id;

  const itemSpecId = product ? product.item_fields_schema : itemSpec.id;

  const formFields: FormField[] = useExpandFieldsFromSpec(
    itemSpecId,
    productSpecId,
    productSpecFields,
    Boolean(product)
  );

  const { createProduct, editProduct } = useProducts();
  const {create, edit} = useProductApi();
  const { fetch } = useTagApi();

  const tags = fetch.isPending ? [] : fetch.data.results;

  const yupSchema = yupSchemaReducer(formFields, {
    viewType: product ? "edit" : "new",
  });
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // set default values if editing
  useEffect(() => {
    if (!product) return;
    setValue("name", product.name);
    setValue("tag", product.tag?.id);
    if (product["json_data"])
      Object.keys(product["json_data"]).forEach((k) => {
        setValue(k.toString(), product["json_data"][k]);
      });
  }, [product, setValue]);

  const onSubmit = async (values: any) => {
    const data: any = {
      name: values.name,
      tag: values.tag,
    };

    if (!product) {
      data.product_fields_schema = values.product_fields_schema;
      data.item_fields_schema = values.item_fields_schema;
    }

    delete values.product_fields_schema;
    delete values.item_fields_schema;
    delete values.name;
    delete values.tag;
    
    const fieldsIds = productSpecFields.map((field:any) => field.id);

    Object.keys(values).forEach((key) => {
      if(!fieldsIds.includes(Number(key))) {
        delete values[key];
      }
    })

    // don't send empty object properties to server
    data.save_json_data = cleanObj(values);

    try {
      if (product) await edit.mutate({id: product.id, data});
      else {
        await create.mutate({data, tag});
        reset();
      }
      handleSuccess();
    } catch (e: any) {
      setError(
        <>
          <div>Server error.</div>
          {e.errors.data?.map((e: any) => (
            <p>{e}</p>
          ))}
        </>
      );
      console.log("e", e);
    }
  };

  const handleValue = (fieldName: string, data: any) => {
    setValue(fieldName, data.id);
    if (fieldName === "tag") setTag(data);
  };

  if (!tags.length) return null;

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1, mxw: 400 }}
    >
      {error && (
        <Type sx={{ mt: 3, mb: 2, c: "red" }} as="div">
          {error}
        </Type>
      )}

      <FormFields
        fields={formFields}
        errors={errors}
        register={register}
        resources={{ tags }}
        setValue={handleValue}
        getValues={getValues}
      />

      <Button type="submit" sx={{ mt: 15, mb: 15 }} loading={isSubmitting}>
        {product ? "Save" : "Create"}
      </Button>
    </Box>
  );
}

export default memo(ProductForm);
