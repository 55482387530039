import styled, { css } from "styled-components";
import { Card, Flex, Icon } from "..";
import Dropdown from "../core/Dropdown";
import { ReactNode } from "react";
import { RowAction, Row } from "./TableContainer";

type OptionsCardProps = {
  light?: boolean;
}

const OptionsCard = styled(Card)<OptionsCardProps>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  ${(props) =>
    props.light &&
    css`
      box-shadow: none;
      font-size: 14px;
    `}
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: ${(props) => props.theme.color.white};
      font-weight: 600;
      padding: 15px 20px;
      border-bottom: ${(props) => props.theme.color.gray600} 1px solid;
      svg {
        margin-right: 8px;
        fill: ${(props) => props.theme.color.white};
      }
      &.delete {
        color: ${(props) => props.theme.color.red};
        svg {
          fill: ${(props) => props.theme.color.red};
        }
      }
      &:hover {
        background-color: ${(props) => props.theme.color.primary};
        color: white;
        svg {
          fill: white;
        }
        &.delete {
          background-color: ${(props) => props.theme.color.red};
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

function buildOption<T extends Row = Row>(row: T, opt: RowAction<T>): ReactNode {
  return <span key={opt.name}>{opt.render(row)}</span>;
};

function renderOptions<T extends Row = Row> (row: T, opts: RowAction<T>[]): ReactNode {
  if (!row) return null;
  const list = opts.map((opt) => buildOption(row, opt));
  return list;
};

type MoreOptionsProps<T extends Row = Row> = {
  row: T,
  moreOptions: RowAction<T>[]
}

function MoreOptions<T extends Row = Row>({ row, moreOptions }: MoreOptionsProps<T>) {

  const maxVisibleActions = 3;

  // if (Object.keys(moreOptions).length <= 2)
  //   return <Flex light></Flex>;

  return (
    <Flex gap={10} jc="flex-end">
      {renderOptions(row, moreOptions.slice(0, maxVisibleActions))}
      {Boolean(moreOptions.length > maxVisibleActions) && (
        <Dropdown
          content={
            <OptionsCard>
              {renderOptions(
                row,
                moreOptions.slice(maxVisibleActions, moreOptions.length)
              )}
            </OptionsCard>
          }
        >
          <span aria-label="dropdown-trigger">
            <Icon
              name="ellipsisVertical"
              circle
              c="black"
              hover="primary"
              w={10}
            />
          </span>
        </Dropdown>
      )}
    </Flex>
  );
}

export default MoreOptions;
