import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { Box, Divider, Flex, Icon, Type } from "ui";
import useStatsApi from "views/stats/useStatsApi";

const SList = styled.ul`
  list-style: none;
  margin: 60px 0 4px 0;
  padding: 0;
  li {
    margin: 34px 20px 0px 0;
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    span:first-child {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    span:last-child {
      font-weight: 700;
    }
  }
`;

function SideInfo({ colors, total, highest }) {
  const { getTransactionsByCountry } = useStatsApi();

  const { data: transactionsLocations } = useQuery({
    queryKey: ["tranByCountry"],
    queryFn: getTransactionsByCountry,
    placeholderData: (previousData) => previousData || [],
  });

  return (
    <Box sx={{ pt: 20 }}>
      <Type sx={{ fs: 42, fw: 600, mb: 24, c: "black" }}>{total}</Type>
      <Type sx={{ fs: 16, c: "gray700" }}>Total Transactions</Type>

      <SList as="ul">
        <Box as="li">
          <span>
            <Icon name="circle" c={colors[3]} />
            Highest
          </span>
          <span>
            {Math.max(
              ...transactionsLocations
                .filter(
                  (item) => item.count >= highest / 3 && item.country !== null
                )
                .map((i) => i.count),
              0
            )}
          </span>
        </Box>
        <Box as="li">
          <span>
            <Icon name="circle" c={colors[2]} /> Medium
          </span>
          <span>
            {Math.max(
              ...transactionsLocations
                .filter(
                  (item) => item.count < highest / 3 && item.country !== null
                )
                .map((i) => i.count),
              0
            )}
          </span>
        </Box>
        <Box as="li">
          <span>
            <Icon name="circle" c={colors[1]} /> Low
          </span>
          <span>
            {Math.max(
              ...transactionsLocations
                .filter(
                  (item) => item.count < highest / 6 && item.country !== null
                )
                .map((i) => i.count),
              0
            )}
          </span>
        </Box>
        <Box as="li">
          <span>
            <Icon name="circle" c={colors[0]} /> Lowest
          </span>
          <span>
            {Math.max(
              ...transactionsLocations
                .filter(
                  (item) => item.count < highest / 9 && item.country !== null
                )
                .map((i) => i.count),
              0
            )}
          </span>
        </Box>
      </SList>
      <Divider border="gray400" h={50} />

      <Flex sx={{ ml: 0, mw: 320 }} jc="space-between">
        <Flex gap={12}>
          <Icon name="info" w={20} c="gray700" /> <Type>Unknown</Type>
        </Flex>

        <Type sx={{ fw: 600 }}>
          {transactionsLocations.find((item) => item.country === null).count}{" "}
        </Type>
      </Flex>
    </Box>
  );
}

export default SideInfo;
