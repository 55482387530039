import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductForm from "./ProductForm";
import { useUtils } from "../../services/utilsProvider";
import { Card, Flex, Link, Loading, Type } from "ui";
import { useProducts } from "./provider";
import useFieldApi from "views/fields/useFieldApi";
import { useQueryClient } from "@tanstack/react-query";

function EditProduct() {
  const [productSpecFields, setProductSpecFields] = useState([]);

  const {id} = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const { getList } = useFieldApi();
  const queryClient = useQueryClient();

  const {
    state: { product },
    fetchProduct,
  } = useProducts();

  useEffect(() => {
    fetchProduct(Number(id));
  }, [id, fetchProduct]);

  useEffect(() => {
    if (!product) return;
    (async () => {
      const data = await queryClient.fetchQuery({
        queryKey: ["allFields", product["product_fields_schema"]],
        queryFn: () =>
          getList({
            specId: product["product_fields_schema"],
            page: 0,
            pageSize: 100,
          }),
      });
      setProductSpecFields(data.results);
    })();
  }, [product, queryClient, getList]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Product saved successfully", 'success');
  };

  return (
    <>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Dashboard</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Edit Product
      </Type>

      <Card sx={{ mt: 40, w: 360 }}>
        {Boolean(productSpecFields && product) ? (
          <ProductForm
            product={product}
            productSpecFields={productSpecFields}
            handleSuccess={handleSuccess}
          />
        ) : (
          <Loading />
        )}
      </Card>
    </>
  );
}

export default memo(EditProduct);
