import styled from "styled-components";
import stylesBlocks, { StylesBlocksProps } from "./core/stylesBuilder";
import { BaseHTMLAttributes } from "react";

export interface ListProps
  extends StylesBlocksProps,
    BaseHTMLAttributes<HTMLDListElement> {}

const List = styled.dl<ListProps>`
  margin: 0;
  padding: 10px 0;
  list-style: none;
  dt {
    border-bottom: ${(p) => p.theme.color.grayBg} 1px solid;
  }
  dd {
    margin-left: 0;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: ${(p) => p.theme.color.gray200}cc 1px solid;
    span {
      font-size: 16px;
    }
    span:first-child {
      font-size: 16px;
      font-weight: 600;
    }
    &.primary {
      color: ${(p) => p.theme.color.primary};
    }
  }
  ${stylesBlocks}
`;

export default List;
