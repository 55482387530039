import { ComponentPropsWithoutRef, memo } from "react";
import styled, { css } from "styled-components";
import { Box, Button, Flex, Icon, Loading } from "../ui";
import Label from "../ui/Label";

type WrapProps = {
  $isDoc: boolean;
};

const Wrap = styled.div<WrapProps>`
  .preview {
    img {
      width: 100%;
      margin: 0;
      display: block;
    }
  }
  ${(p) =>
    p.$isDoc &&
    css`
      .wrap-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    `}
`;

const FileLabel = styled.label`
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 10px;
      svg {
        width: 18px;
      }
    }
  }
`;

type FileFieldProps = {
  label: string;
  preview: any;
  setPreview: Function;
  inputProps: ComponentPropsWithoutRef<"input">;
  msg?: string | null;
  buttonLabel: string;
  document?: boolean;
  documentFile?: { url: string; name: string; size?: number };
  disabled?: boolean;
  readOnly?: boolean;
  edit?: boolean;
  onClose?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  loading?: boolean;
};

function FileField({
  label,
  preview,
  setPreview,
  inputProps,
  msg = null,
  buttonLabel,
  document,
  documentFile,
  disabled = false,
  readOnly,
  edit,
  onClose,
  loading,
  ...props
}: FileFieldProps) {
  return (
    <Wrap $isDoc={document} {...props}>
      <Label>{label}</Label>

      {Boolean(!document && preview) && (
        <Box
          // @ts-ignore
          relative
          sx={{
            minh: 180,
            bgc: "gray100",
            bgsize: "cover",
            mb: 20,
            of: "hidden",
            br: 4,
            p: 0,
          }}
          className="preview"
        >
          {/* {Boolean(preview) && ( */}
          <img src={preview} alt="preview uploaded file" />
          {/* )} */}
          {loading && (
            // @ts-ignore
            <Flex abs={{ t: 0, l: 0 }} sx={{ w: "100%", h: "100%" }}>
              <Loading />
            </Flex>
          )}
        </Box>
      )}

      <div className="wrap-grid">
        {!disabled && (
          // @ts-ignore
          <Flex jc="flex-start">
            <FileLabel htmlFor={inputProps?.name + "file"}>
              <Button
                as="span"
                type="button"
                size="small"
                sx={{ text: "uppercase" }}
                disabled={false} // temporary fix for ts check, remove once Bottom is type checked
              >
                {buttonLabel}
              </Button>
              <input
                className="custom-file-input"
                id={inputProps?.name + "file"}
                type="file"
                {...inputProps}
              />
            </FileLabel>
            {Boolean(preview) && (
              <Button
                disabled={false} // temporary fix for ts check, remove once Bottom is type checked
                variant="text"
                sx={{ text: "uppercase" }}
                onClick={() => setPreview(null)}
              >
                Cancel
              </Button>
            )}
          </Flex>
        )}
        {documentFile && (
          <FileLabel className="doc-preview">
            {!readOnly && (
              <span className="close" onClick={onClose}>
                <Icon name="xmark" />
              </span>
            )}
            <a
              className="icon"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="pdf" />
            </a>
            <a
              className="info"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <p>{documentFile.name}</p>
              {documentFile.size ? (
                <small>{documentFile.size} KB</small>
              ) : (
                <small>Click to download</small>
              )}
            </a>
          </FileLabel>
        )}
      </div>
    </Wrap>
  );
}

export default memo(FileField);
