import { memo, useRef, useState } from "react";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import { Access } from "services/auth";
import Modal from "ui/Modal";
import FieldForm from "./FieldForm";
import { Col } from "ui/tableContainer/TableContainer";
import { useQuery } from "@tanstack/react-query";
import { Spec } from "views/specs/types";
import useStore from "useStore";
import { type Field, FieldType } from "./types";
import useFieldApi from "./useFieldApi";
import { BaseStatus } from "types/types";

const fieldType = (type: FieldType) => {
  switch (type) {
    case FieldType.number:
      return <Type>Number</Type>;
    case FieldType.string:
      return <Type>Text</Type>;
    default:
      return <Type>Yes/No</Type>;
  }
};

const cols: Col<Field>[] = [
  { label: "ID", name: "id", sx:{fw: 600} },
  { label: "Name", name: "name" },
  {
    label: "Type",
    name: "field_type",
    render: ({ row }) => fieldType(row.field_type),
  },
  {
    label: "Is Required",
    name: "is_required",
    props: {style: {textAlign: 'center'}},
    render: ({ row }) => (
      <Type sx={{ ta: "center" }}>
        {row.is_required ? (
          <Icon name="check" w={14} c="gray600" />
        ) : (
          <Icon name="check" w={14} c="gray200" />
        )}
      </Type>
    ),
  },
];

type FieldsProps = {
  onEdit: (spec: Spec) => void;
  spec: Spec;
};

function Fields({ onEdit, spec }: FieldsProps) {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [field, setField] = useState(null);

  const { page, perPage } = useStore((state) => state.field);
  const changeField = useStore((state) => state.changeField);

  const { getList, remove } = useFieldApi();

  const { data, isPending } = useQuery({
    queryKey: ["fields", spec?.id, page + 1],
    queryFn: () => getList({ specId: spec.id, page }),
    placeholderData: (prevData) => prevData || { results: [], count: 0 },
  });

  const { results: list, count } = data;

  if (!spec) return;

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setId(null);
    setField({
      id: null,
      name: "",
      field_type: "",
      is_required: false,
    });
  };

  const onSuccess = () => {
    handleClose();
  };

  const openEdit = (field: any) => {
    setId(field.id);
    setField(field);
    handleOpen();
  };

  return (
    <Box>
      <Box sx={{ of: "hidden", mb: 15 }}>
        <Flex
          jc="space-between"
          ai={["center", "center", "center", "flex-start"]}
          gap={20}
          flexDir={["row", "row", "row", "column"]}
          sx={{ py: 20, px: 30, bgc: "white", mb: 10, br: 4 }}
        >
          <Flex jc="flex-start" gap={10}>
            <Type var="h5" as="h4">
              {spec?.name}
            </Type>
            <Access path="/specs/edit">
              <Icon
                disabled={Boolean(spec?.locked)}
                name="pen"
                hover="gray300"
                w={15}
                onClick={() => onEdit(spec)}
              />
            </Access>
          </Flex>
          <Button
            onClick={handleOpen}
            iconName="plus"
            disabled={Boolean(spec?.locked)}
            variant="outlined"
          >
            New Field
          </Button>
        </Flex>

        <Modal show={open}>
          <Box
            abs={{ t: 10, r: 10 }}
            sx={{ z: 999, cursor: "pointer" }}
            onClick={handleClose}
          >
            <Icon hover="gray300" name="xmark" w={14} c="black" />
          </Box>
          <FieldForm
            {...{
              inputEl,
              field,
              id,
              onSuccess,
            }}
          />
        </Modal>

        <TableContainer
          empty={list?.length === 0}
          status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
          aria-label="Spec fields table"
          cols={cols}
          data={list}
          moreOptions={[
            {
              name: "edit",
              render: (row) => (
                <Icon
                  disabled={spec?.locked}
                  hover="gray300"
                  name="pen"
                  w={14}
                  onClick={() => openEdit(row)}
                />
              ),
            },
            {
              name: "delete",
              render: (row) => (
                <Icon
                  hover="gray300"
                  disabled={spec?.locked}
                  name="trash"
                  w={14}
                  onClick={() => {
                    if (window.confirm("Are you sure?")) remove.mutate(row.id);
                  }}
                />
              ),
            },
          ]}
        />
      </Box>

      <Pagination
        data-testid="fields-pagination"
        count={count}
        page={page}
        info={true}
        pager={false}
        onPageChange={(page) => changeField({ page })}
        rowsPerPage={perPage}
      />
    </Box>
  );
}

export default memo(Fields);
