import { FieldErrors } from "react-hook-form";
import { FormField } from "types/types";
import { Checkbox, InputText, Select } from "ui";
import ImageUpload from "views/items/ImageUpload";

type FormFieldsProps = {
  fields: FormField[];
  errors: FieldErrors;
  register: any;
  resources?: {
    [k: string]: unknown[];
  };
  setValue?: (name: string, data: any) => void;
  getValues?: any;
  watch?: any;
  editing?: boolean;
};

function FormFields({
  fields,
  resources = {},
  errors,
  register,
  setValue = () => {},
  getValues,
  watch,
  editing = false,
}: FormFieldsProps) {
  return fields.map((f, i) => {
    // if (f.viewOnly) return null;
    if (f.hide === "edit" && editing) return false;

    const inputProps = { ...f.inputProps };
    if (i === 0) inputProps.autoFocus = true;
    if (f.required) inputProps.required = true;

    if (f.data) {
      const data = f.resourceName ? resources[f.resourceName] : f.data;

      if (!data?.length) return null;

      return (
        <div key={i}>
          <Select
            style={{ width: "100%", marginBottom: "30px" }}
            label={f.label}
            name={f.name}
            data={data}
            // @ts-ignore
            onChange={({ data }) => setValue(f.name, data)}
            defaultValue={getValues ? getValues(f.name) : null}
            mapData={f.mapData}
            required={f.required}
            error={!!errors[f.name]}
            // @ts-ignore
            msg={errors[f.name]?.message || f.msg}
            inputProps={{
              ...register(f.name),
              "aria-label": f.label,
              required: true,
            }}
          />
        </div>
      );
    } else if (f.type === "hidden")
      return (
        <input
          key={i}
          type="hidden"
          name={f.name}
          {...register(f.name)}
          value={f.value}
        />
      );
    else if (f.type === "checkbox")
      return (
        <Checkbox
          key={i}
          id={f.name}
          error={!!errors[f.name]}
          msg={errors[f.name]?.message as string}
          label={f.label}
          inputProps={{
            ...register(f.name),
            ...inputProps,
            name: f.name,
          }}
        />
      );
    else if (f.type === "image")
      return (
        <ImageUpload
          label={f.label}
          key={i}
          setValue={setValue}
          // @ts-ignore
          error={!!errors[f.name]}
          watch={watch}
          msg={errors[f.name]?.message as string}
          buttonLabel={f.buttonLabel || "upload"}
          inputProps={{
            // name: f.name,
            ...register(f.name),
          }}
        />
      );
    else
      return (
        <InputText
          key={i}
          label={f.label}
          name={f.name}
          error={!!errors[f.name]}
          // @ts-ignore
          msg={errors[f.name]?.message}
          type={f.type}
          inputProps={{
            ...register(f.name),
            ...inputProps,
            "aria-label": f.label,
          }}
        />
      );
  });
}

export default FormFields;
