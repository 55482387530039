import { Dispatch, SetStateAction } from "react";
import { InputText } from "ui";
import debounce from "utilities/debounce";

type SearchInputProps = {
  // setTerm: React.Dispatch<React.SetStateAction<string>>;
  setTerm: Dispatch<SetStateAction<string>> | ((v: string) => void);
};

function SearchInput({ setTerm }: SearchInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value.length < 3) value = "";
    setTerm(value);
  };

  const debouncedHandleChange = debounce(handleChange, 500);

  return (
    <InputText
      // @ts-ignore
      iconLeft="magnifyingGlass"
      placeholder="Search…"
      size="medium"
      inputProps={{ "aria-label": "search" }}
      onChange={debouncedHandleChange}
    />
  );
}

export default SearchInput;
