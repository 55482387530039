import { Box, Card, Flex, Icon, List, Type } from "ui";

function ProductDetails({ list }: { list: any[] }) {
  return (
    <Box>
      <Type var="h4" as="h4" sx={{ mb: 20 }}>
        Product Information
      </Type>
      <Card>
        <List>
          {list.map((item, i) => (
            <Flex key={item.name + i} as="li" jc="space-between">
              <Type sx={{ pr: 30, mnw: 180 }}>{item.name}</Type>
              <Type>
                {typeof item.value === "boolean" ? (
                  <Icon name="check" c="black" w={14} />
                ) : (
                  item.value
                )}
              </Type>
            </Flex>
          ))}
        </List>
      </Card>
    </Box>
  );
}

export default ProductDetails;
