import { FormField, ViewType } from "types/types";
import * as yup from "yup";

// !!!!! test typeError for date !!!!!!
//
// return null if date is not set, else parse the string date to js date
// this is used a callback param for the yup transform function it recieve the input value
export function parseDate(value: any, originalValue: any, context: yup.Schema) {
  if (context.isType(value)) return value;
  if (
    typeof originalValue === "string" &&
    (originalValue === "" || originalValue === "Invalid Date")
  )
    return null;

  return new Date(originalValue);
}

export default function yupSchemaFromFieldsArray(
  fields: FormField[],
  config: { viewType: ViewType }
) {
  const schema: yup.ObjectShape = {};

  fields.forEach((field) => {
    const { name, hide = "", validationType, validations = [] } = field;

    if (!yup[validationType] || config?.viewType === hide) return;

    let validator: any;

    if (validationType === "array") validator = yup.array();
    else if (validationType === "mixed") validator = yup.mixed();
    else validator = yup[validationType]();

    validations.forEach((validation) => {
      const { params, type, fn } = validation;

      if (!validator[type]) return;

      if (validationType === "array" && type === "of")
        validator = fn && validator.of(fn(yup));
      else validator = validator[type](...(params || []));
    });

    schema[name] = validator;
  });

  return schema;
}
