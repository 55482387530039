import styled, { css } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { ReactNode } from "react";
import { Severity } from "services/utilsProvider";

interface WrapProps {
  horizontal?: "left" | "right" | "center";
  vertical?: "center" | "top" | "bottom";
  open: boolean;
  severity?: Severity;
}

const Wrap = styled.div
  .withConfig({
    shouldForwardProp: (p) => isPropValid(p),
  })
  .attrs<WrapProps>((p) => ({
    // we can define static props
    horizontal: p.horizontal || "center",

    // or we can define dynamic ones
    vertical: p.vertical || "top",
  }))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  pointer-events: none;

  ${(p) => {
    switch (p.horizontal) {
      case "left":
        return css`
          justify-content: flex-start;
        `;
      case "right":
        return css`
          justify-content: flex-end;
        `;
      default:
        return css`
          justify-content: center;
        `;
    }
  }}

  ${(p) => {
    switch (p.vertical) {
      case "center":
        return css`
          align-items: center;
        `;
      case "top":
        return css`
          align-items: flex-start;
        `;
      default:
        return css`
          align-items: flex-end;
        `;
    }
  }}
  transition: all 0.1s 0.3s ease;
  ${(p) =>
    p.open
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  .snackbar {
    padding: 15px 25px;
    border-radius: 4px;
    min-width: 260px;

    ${(p) => {
      switch (p.severity) {
        case "alert":
          return css`
            background-color: ${(p) => p.theme.color.redBg};
            color: ${(p) => p.theme.color.red};
            outline: ${(p) => p.theme.color.red}11 1px solid;
          `;
        case "success":
          return css`
            background-color: ${(p) => p.theme.color.greenBg};
            color: ${(p) => p.theme.color.green};
            outline: ${(p) => p.theme.color.green}11 1px solid;
            svg {
              fill: ${(p) => p.theme.color.green};
            }
          `;
        default:
          return css`
            background: ${(p) => p.theme.color.blueBg};
            color: ${(p) => p.theme.color.blue};
            outline: ${(p) => p.theme.color.blue}11 1px solid;
          `;
      }
    }}

    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    ${(p) =>
      p.open
        ? css`
            opacity: 1;
            transform: translate(0, 0);
          `
        : css`
            opacity: 0;
            transform: translate(0, 200px);
            transition: all 0.9s cubic-bezier(0, 0, 0.2, 1);
          `}
  }
`;

interface SnackbarProps extends WrapProps {
  children: ReactNode;
}

function Snackbar({ open = false, children, ...rest }: SnackbarProps) {
  return (
    <Wrap open={open} {...rest}>
      <div className="snackbar">{children}</div>
    </Wrap>
  );
}

export default Snackbar;
