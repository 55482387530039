import { ComponentPropsWithoutRef } from "react";
import type * as CSS from "csstype";
import { Color } from "theme";

export type Position = "left" | "right" | "bottom" | "top";

export type Size = "small" | "medium" | "large";

export type ValidationsTypes =
  | "string"
  | "number"
  | "date"
  | "boolean"
  | "mixed"
  | "array";

export type ValidationTest = {
  type: string;
  params?: any[];
  fn?: (yup: any) => any;
};

export type ViewType = "edit" | "new" | "show";

export type FormField = {
  name: string;
  label?: string;
  type: string;
  validationType: ValidationsTypes;
  required?: boolean;
  validations: ValidationTest[];
  editing?: boolean;
  hide?: ViewType;
  buttonLabel?: string;
  resourceName?: string;
  multi?: boolean;
  data?: any[];
  mapData?: { value: string; label: string };
  msg?: string;
  inputProps?: ComponentPropsWithoutRef<"input">;
  value?: string | number;
};

export type SpecField = {
  field_type: 1 | 2 | 3;
  id: number;
  is_required: boolean;
  name: string;
};

export enum BaseStatus {
  Idle = "idle",
  Fetching = "fetching",
  Creating = "creating",
  Editing = "editing",
  Deleting = "deleting",
  Cloning = "cloning",
  Uploading = "uploading",
}

export type Status = BaseStatus | `${BaseStatus}_${number}`;

// type AlphaNum = string | number | AlphaNum[]
// type Color = "primary" | "secondary" | "red" | "#FF0000" | string;

export type orArrayNum<T> = T | number | (T | number)[];
export type orArray<T> = T | T[];

// make it partial
export type Sx = {
  mt?: orArrayNum<CSS.Properties["marginTop"]>;
  mb?: orArrayNum<CSS.Properties["marginBottom"]>;
  ml?: orArrayNum<CSS.Properties["marginLeft"]>;
  mr?: orArrayNum<CSS.Properties["marginRight"]>;
  m?: orArrayNum<CSS.Properties["margin"]>;
  /**
   * The **`my`** property is shorthand for using both **`margin-top`** and **`margin-bottom`** CSS properties.
   * They set the margin on the left and right side of an element.
   * */
  my?: orArrayNum<CSS.Properties["marginTop"]>;
  /**
   * The **`mx`** property is shorthand for using both **`margin-left`** and **`margin-right`** CSS properties.
   * They set the margin on the left and right side of an element.
   * */
  mx?: orArrayNum<CSS.Properties["marginLeft"]>;
  pt?: orArrayNum<CSS.Properties["paddingTop"]>;
  pb?: orArrayNum<CSS.Properties["paddingBottom"]>;
  pl?: orArrayNum<CSS.Properties["paddingLeft"]>;
  pr?: orArrayNum<CSS.Properties["paddingRight"]>;
  p?: orArrayNum<CSS.Properties["padding"]>;
  /**
   * The **`py`** property is shorthand for using both **`padding-top`** and **`padding-bottom`** CSS properties.
   * They set the padding on the left and right side of an element.
   * */
  py?: orArrayNum<CSS.Properties["paddingTop"]>;
  /**
   * The **`px`** property is shorthand for using both **`padding-left`** and **`padding-right`** CSS properties.
   * They set the padding on the left and right side of an element.
   * */
  px?: orArrayNum<CSS.Properties["paddingLeft"]>;
  // fonts
  ff?: CSS.Properties["fontFamily"];
  fs?: orArrayNum<CSS.Properties["fontSize"]>;
  fw?: orArray<CSS.Properties["fontWeight"]>;
  lh?: orArray<CSS.Properties["lineHeight"]>;
  fstyle?: orArray<CSS.Properties["fontStyle"]>;
  text?: orArray<CSS.Properties["textTransform"]>;
  ta?: orArray<CSS.Properties["textAlign"]>;
  c?: CSS.Properties["color"];
  bgc?: CSS.Properties["backgroundColor"];
  bgsize?: orArray<CSS.Properties["backgroundSize"]>;
  d?: orArray<CSS.Properties["display"]>;
  fl?: orArray<CSS.Properties["float"]>;
  w?: orArrayNum<CSS.Properties["width"]>;
  gap?: orArrayNum<CSS.Properties["gap"]>;
  grow?: orArray<CSS.Properties["flexGrow"]>;
  jc?: orArray<CSS.Properties["justifyContent"]>;
  ai?: orArray<CSS.Properties["alignItems"]>;
  /**
   * The **`sqr`** property is shorthand for using both **`width`** and **`height`** CSS properties.
   * They set the height and width of an element to same value, making it a square.
   * */
  sqr?: orArrayNum<CSS.Properties["width"]>;
  mw?: orArrayNum<CSS.Properties["maxWidth"]>;
  maxw?: orArrayNum<CSS.Properties["maxWidth"]>;
  mxw?: orArrayNum<CSS.Properties["maxWidth"]>;
  minw?: orArrayNum<CSS.Properties["minWidth"]>;
  mnw?: orArrayNum<CSS.Properties["minWidth"]>;
  h?: orArrayNum<CSS.Properties["height"]>;
  mh?: orArrayNum<CSS.Properties["maxHeight"]>;
  mxh?: orArrayNum<CSS.Properties["maxHeight"]>;
  maxh?: orArrayNum<CSS.Properties["maxHeight"]>;
  mnh?: orArrayNum<CSS.Properties["minHeight"]>;
  minh?: orArrayNum<CSS.Properties["minHeight"]>;
  cursor?: orArray<CSS.Properties["cursor"]>;
  of?: orArray<CSS.Properties["overflow"]>;
  ofx?: orArray<CSS.Properties["overflowX"]>;
  ofy?: orArray<CSS.Properties["overflowY"]>;
  tr?: orArray<CSS.Properties["transition"]>;
  transform?: orArray<CSS.Properties["transform"]>;
  bc?: CSS.Properties["borderColor"];
  bs?: orArray<CSS.Properties["borderStyle"]>;
  bw?: orArrayNum<CSS.Properties["borderWidth"]>;
  br?: orArrayNum<CSS.Properties["borderRadius"]>;
  /**
   * The **`border`** property is shorthand for specifying border color, width, style, radius,
   * and sides(top as t, bottom as b, left as l and right as r) CSS properties.
   *
   * e.g. border: ['red', 1, 'solid', 3, 'tblr'] create a solid 1px red border with a 3px radius
   * and on all sides ('tblr')
   * */
  border?: [
    Color | CSS.Properties["borderColor"],
    CSS.Properties["borderWidth"] | number,
    CSS.Properties["borderStyle"],
    CSS.Properties["borderRadius"] | number,
    string
  ];
  op?: orArray<CSS.Properties["opacity"]>;
  gcs?: orArray<CSS.Properties["gridColumnStart"]>;
  gce?: orArray<CSS.Properties["gridColumnEnd"]>;
  grs?: orArray<CSS.Properties["gridRowStart"]>;
  gre?: orArray<CSS.Properties["gridRowEnd"]>;
  gc?: orArray<CSS.Properties["gridColumn"]>;
  gr?: orArray<CSS.Properties["gridRow"]>;
  gtc?: orArray<CSS.Properties["gridTemplateColumns"]>;
  gtr?: orArray<CSS.Properties["gridTemplateRows"]>;
  pos?: orArray<CSS.Properties["position"]>;
  left?: orArrayNum<CSS.Properties["left"]>;
  right?: orArrayNum<CSS.Properties["right"]>;
  top?: orArrayNum<CSS.Properties["top"]>;
  bottom?: orArrayNum<CSS.Properties["bottom"]>;
  shadow?: orArrayNum<CSS.Properties["boxShadow"]>;
  z?: orArray<CSS.Properties["zIndex"]>;
  ":hover"?: Sx;
  print?: Sx;
};
