import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { WalletActionType, useWallet } from "./Provider";
import { decryptMessage } from "utilities/encrypt-aes-cbc";
import { Box, Button, Card, Flex, InputText, Type } from "ui";

const schema = Yup.object().shape({
  password1: Yup.string().min(8, "Too Short!").required("Required"),
  password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("password1"), null], "Passwords must match"),
});

function WalletUnlock() {
  const [serverErrors, setServerErrors] = useState(null);

  const navigate = useNavigate();

  const { dispatch } = useWallet();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    try {
      const encryptedMsg = JSON.parse(
        window.localStorage.getItem("wallet_enc_pack")
      );
      const encryptedMsgBuffer = new Uint8Array(encryptedMsg);
      const privateKey = await decryptMessage(
        encryptedMsgBuffer,
        values.password1
      );
      dispatch({
        type: WalletActionType.SetPrivateKey,
        payload: privateKey as string,
      });
      navigate("/wallet");
    } catch (e: any) {
      console.log("error", e);
      setServerErrors(e.errors);
    }
  };

  return (
    <>
      <Type var="h4" as="h4">
        Unlock Wallet
      </Type>

      <Card sx={{ mxw: 400, mt: 40 }}>
        <Type var="h5" as="h5">
          Wallet Password
        </Type>
        <Type>Enter wallet password to access your wallet</Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30 }}
        >
          <InputText
            id="password1"
            type="password"
            label="Password"
            error={Boolean(errors.password1 || serverErrors?.password1)}
            msg={errors.password1?.message || serverErrors?.password1}
            inputProps={{
              ...register("password1"),
              name: "password1",
            }}
          />

          <InputText
            id="password2"
            type="password"
            label="Confirm Password"
            error={Boolean(errors.password2 || serverErrors?.password2)}
            msg={errors.password2?.message || serverErrors?.password2}
            inputProps={{
              ...register("password2"),
              name: "password2",
            }}
          />

          <Flex jc="space-between" sx={{ mt: 30, mb: 10 }}>
            <Button loading={isSubmitting}>Unlock</Button>

            <Button
              type="button"
              variant="text"
              onClick={() => navigate("/wallet/reset-password")}
            >
              Forgot Password
            </Button>
          </Flex>
        </Box>
      </Card>
    </>
  );
}

export default WalletUnlock;
