import { ComponentPropsWithoutRef, ReactNode, useEffect, useState } from "react";
import { Box, Flex } from "../ui";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import useMedia from "utilities/hooks/useMedia";
import theme from "theme";
import Mask from "ui/Mask";

interface LayoutProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const media = useMedia();
  const [expanded, setExpanded] = useState(true);
  const [open, setOpen] = useState(media <= theme.resolution.sm ? false : true);

  useEffect(() => {
    if (media <= theme.resolution.sm && !expanded) {
      setExpanded(true)
      setOpen(false)
    }
  }, [media, expanded])

  useEffect(() => {
    if (media > theme.resolution.sm && !open) {
      setOpen(true)
    }
  }, [media, open])

  return (
    <Box
      sx={{ h: "100vh", tr: "all 0.2s ease-in", ofy: 'scroll', ofx: "hidden" }}
      className={expanded ? "expanded" : ""}
    >
      <Header setOpen={setOpen} />

      <Flex ai="flex-start" jc="flex-start" sx={{ w: "100vw", bgc: "gray200" }}>
        <Sidebar {...{open, setOpen, expanded, setExpanded}} />
        <Mask show={media <= theme.resolution.sm && open} onClick={() => setOpen(false)} />
        <Box
          as="main"
          sx={{
            bgc: "gray200",
            w: `calc(100vw - ${open ? (expanded ? 240 : 77) : 0}px)`,
            tr: "all 0.1s ease-in",
            mnh: "calc(100vh - 68px)",
          }}
        >
          <Box sx={{ p: [40, "_", "_", 20] }} aria-label="content-wrapper">
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}

export default Layout;
