import React, {
  useState,
  useRef,
  useEffect,
  RefObject,
  ReactNode,
  MouseEvent,
  ReactElement,
} from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { Point, getPoint, oppositeDirection } from "utilities/relativePosition";
import Portal from "./Portal";
import isPropValid from "@emotion/is-prop-valid";
import { Position } from "types/types";

type StyledDropdownProps = {
  delay?: number;
  posRef: RefObject<Point>;
  show: boolean;
  placement?: Position;
};

const StyledDropdown = styled.span
  .withConfig({
    shouldForwardProp: (prop) => isPropValid(prop),
  })
  .attrs<StyledDropdownProps>((p) => ({
    delay: p.delay || 0.05,
  }))`
  position: fixed;
  top: ${(p) => p.posRef.current?.y}px;
  left: ${(p) => p.posRef.current?.x}px;
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  z-index: 99999;
  display: inline-block;
  white-space: nowrap;
  opacity: ${(p) => Number(p.show)};

  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s !important;

  transform-origin: ${(p) => oppositeDirection(p.placement || "bottom")};
  transform: scale(${(p) => (p.show ? 1 : 0.7)});
`;

interface DropdownProps extends Partial<StyledDropdownProps> {
  content: ReactNode;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  space?: number;
  children: ReactElement;
  disabled?: boolean;
  clickable?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

function Dropdown({
  content,
  show,
  setShow = () => {},
  placement = "bottom",
  space = 15,
  children,
  disabled = false,
  delay,
  clickable = true,
  onOpen = () => {},
  onClose = () => {},
}: DropdownProps) {
  const [show2, setShow2] = useState(false);
  const posRef = useRef<Point>({ x: 0, y: 0 });
  const dropdownRef = useRef<HTMLSpanElement | null>(null);
  const trigerRef = useRef<HTMLElement>(null);

  const handleMOver = (e: React.MouseEvent<HTMLElement>) => {
    setShow2(true);
    posRef.current = getPoint(
      e.currentTarget,
      dropdownRef.current as HTMLElement,
      placement,
      space
    );
    // console.log(e.currentTarget)
  };
  const handleMOut = () => setShow2(false);

  const handleClose = useCallback(() => {
    setShow(false);
    setShow2(false);
    onClose();
  }, [onClose, setShow]);

  const handleOpen = useCallback(() => {
    setShow(true);
    setShow2(true);
    onOpen();
  }, [onOpen, setShow]);

  let handleOutsideClick = useCallback(
    (e: any) => {
      if (!clickable) return;
      var isClickInsideElement =
        dropdownRef.current?.contains(e.target as HTMLElement) ||
        trigerRef.current?.contains(e.target as HTMLElement);
      if (!isClickInsideElement) {
        //Do something click is outside specified element
        handleClose();
      }
    },
    [handleClose, clickable]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("scroll", handleClose);
    window.addEventListener("resize", handleClose);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", handleClose);
      window.addEventListener("resize", handleClose);
    };
  }, [handleClose, handleOutsideClick]);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    // e.stopPropagation();
    if (!show || !show2) {
      handleOpen();
      posRef.current = getPoint(
        e.currentTarget, // or trigerRef.current
        dropdownRef.current as HTMLElement,
        placement,
        space
      );
    } else {
      handleClose();
    }
  };

  const cloneProps: {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    ref?: RefObject<HTMLElement>;
    onMouseOver?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseOut?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
  } = {
    className: show2 ? "active" : "",
  };

  if (clickable) {
    cloneProps.onClick = handleClick;
    cloneProps.ref = trigerRef;
  } else {
    cloneProps.onMouseOver = handleMOver;
    cloneProps.onMouseOut = handleMOut;
  }

  return (
    <>
      {disabled ? children : React.cloneElement(children, cloneProps)}
      {disabled || (
        <Portal>
          <StyledDropdown
            aria-describedby="dropdown"
            aria-label="dropdown"
            delay={delay}
            ref={dropdownRef}
            posRef={posRef}
            show={typeof show !== "undefined" ? show : show2}
          >
            {content}
          </StyledDropdown>
        </Portal>
      )}
    </>
  );
}

export default Dropdown;
