export default function truncate(s: string, len = 40): string {
    if (!s || s.length <= 40) return s;
    //trim the string to the maximum length
    var trimmedString = s.substring(0, len);
  
    //re-trim if we are in the middle of a word
    let trimmed = trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmed + "...";
  };