import { useEffect, useState } from "react";
import formFields from "../fields";
import genFieldsObjFromSpec from "utilities/generateFieldsObjFromSpec";
import { SpecField } from "types/types";

function useExpandFieldsFromSpec(specFields: SpecField[]) {
  const [formFieldsExpanded, setFormFieldsExpanded] = useState(formFields);

  useEffect(() => {
    const newFields = [...formFields, ...genFieldsObjFromSpec(specFields)];

    setFormFieldsExpanded(newFields);
  }, [specFields]);

  return formFieldsExpanded;
}

export default useExpandFieldsFromSpec;
