import { Box, Button, Icon, TableContainer, Type } from "ui";
import { memo, useEffect, useState } from "react";
import { format } from "date-fns";
import { GOOGLE_APT_KEY } from "config";
import type { Col } from "ui/tableContainer/TableContainer";
import type { Transaction } from "views/stats/types";
import { BaseStatus } from "types/types";

const style = {
  backgroundColor: "#f5f5f5",
  color: "#444",
  fontWeight: "600",
  borderBottom: "#ddd 1px solid",
};

type VerificationTransactionTableProps = {
  data: Transaction[];
  handleOpenMap: (location: { longitude: number; latitude: number }) => void;
  isPending: boolean;
};

function VerificationTransactionTable({
  data,
  isPending,
  handleOpenMap,
}: VerificationTransactionTableProps) {
  const cols: Col<Transaction>[] = [
    {
      name: "id",
      label: "ID",
      props: { style },
    },
    {
      name: "verification_hash",
      label: "Verification Hash",
      props: { style },
    },
    {
      name: "card_id",
      label: "Card ID",
      props: { style },
    },
    {
      name: "is_active",
      label: "Is Active",
      props: { style },
      render: ({ row }) => (
        <Icon
          name={row.is_active ? "check" : "check"}
          c={row.is_active ? "green" : "gray300"}
          w={15}
        />
      ),
    },
    {
      name: "scanned_on",
      label: "Scanned On",
      render: ({ row }) => {
        return (
          <Type>
            {format(new Date(row.scanned_on), "MMMM dd, yyyy hh:mm aaa")}
          </Type>
        );
      },
      props: { style },
    },
    {
      name: "location",
      label: "Location",
      props: {
        style: {
          ...style,
          minWidth: "210px",
        },
      },
      render: function OpenMap({ row }) {
        const [country, setCountry] = useState("");

        useEffect(() => {
          if (!row.location) return;
          (async () => {
            const res = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${row.location?.latitude},${row.location?.longitude}&key=${GOOGLE_APT_KEY}`
            );
            const countryName = (
              await res.json()
            ).results[0].address_components.find((item: any) =>
              item.types.includes("country")
            ).long_name;

            setCountry(countryName);
          })();
        }, [row.location]);

        const handleClick = () => {
          handleOpenMap(row.location);
        };

        return row.location ? (
          <>
            <Button
              variant="text"
              sx={{ ml: -8 }}
              onClick={handleClick}
              iconName="map"
            >
              {country}
            </Button>
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <Box>
      <TableContainer<Transaction>
        empty={data?.length === 0}
        status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
        data={data}
        aria-label="Last orders table"
        cols={cols}
      />
    </Box>
  );
}

export default memo(VerificationTransactionTable);
