import { useEffect } from "react";
import { useWallet } from "../../wallet/Provider";
import { Box, Card, Flex, Icon, Link, List, Loading, Type } from "ui";

function WalletInfo() {
  const {
    state: { financialInfo },
    getFinancialInfo,
  } = useWallet();

  useEffect(() => {
    getFinancialInfo();
  }, [getFinancialInfo]);

  return (
    <Box>
      <Card>
        <Type var="h5" as="h5" sx={{mb: 10}}>Wallet financial info</Type>

        {financialInfo ? (
          <List>
            <Flex as="li" jc="space-between">
              <Type>Coin Balance</Type>
              <Type>{financialInfo.coin_balance}</Type>
            </Flex>

            <Flex as="li" jc="space-between">
              <Type>Native Coin Balance</Type>
              <Type>{financialInfo.native_coin_balance}</Type>
            </Flex>

            {/* <Flex as="li" jc="space-between">
              <Type>Native Coin Balance</Type>
              <Type>{financialInfo.native_coin_balance}</Type>
            </Flex> */}

            <Flex as="li" jc="space-between">
              <Type>Network</Type>
              <Type>{financialInfo.network}</Type>
            </Flex>

            <Flex sx={{py: 14}} jc="space-between">
              <Type>View on Block Explorer</Type>
              <Link
                to={financialInfo.view_on_block_explorer}
                target="_blank"
                sx={{ d: "flex", ai: "center" }}
              >
                <span>Polygonscan</span>{" "}
                <Icon name="openLink" w={12} c="black" />
              </Link>
            </Flex>
          </List>
        ) : (
          <Flex>
            <Loading />
          </Flex>
        )}
      </Card>
    </Box>
  );
}

export default WalletInfo;
