import { ComponentPropsWithoutRef, ReactNode, RefObject } from "react";
import SelectedBox from "./SelectedBox";

export type SelectOption = {
  name: string | ReactNode;
  label?: string;
  data?: any;
  value?: string | number;
};

type SelectedProps = {
  refEl: RefObject<HTMLDivElement>;
  selected: SelectOption[];
} & ComponentPropsWithoutRef<"div">;

function Selected({ refEl, selected, ...rest }: SelectedProps) {
  // function getSelected() {
  //   if(!(selected.length && selected[0].hasOwnProperty("data"))) return '';

  //   return selected;
  // }

  return (
    <SelectedBox
      ref={refEl}
      {...rest}
      isValStr={Boolean(selected?.length && typeof selected[0].name === "string")}
      className="selected"
    >
      {selected.map((s, i) => (
        <span key={i} className="item">
          {s.name}
        </span>
      ))}
    </SelectedBox>
  );
}

export default Selected;
