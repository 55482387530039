import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

const Span = styled.span`
  border-top: ${(p) => p.theme.color.gray100} 3px solid;
  border-bottom: ${(p) => p.theme.color.gray100} 3px solid;
  width: 17px;
  height: 9px;
  background-color: transparent;
  position: relative;
  top: -3px;
  /* position: absolute;
    top: 20px;
    left: 10px; */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    top: 9px;
    width: 17px;
    height: 3px;
    background-color: ${(p) => p.theme.color.gray100};
  }
`;

type ToggleProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & ComponentPropsWithoutRef<"span">;

export default function ToggleNav({ setOpen }: ToggleProps) {
  return (
    <Span
      onClick={(e) => {
        e.stopPropagation();
        setOpen((open) => !open);
      }}
    />
  );
}
