import { useState } from "react";
import { useRequest } from "services/request";
import { useUtils } from "services/utilsProvider";
import { WalletActionType, useWallet } from "./Provider";
import { Box, Button, Type } from "ui";

type WalletApproveProps = {
  next: () => void;
};

const WalletApprove = ({ next }: WalletApproveProps) => {
  const [status, setStatus] = useState("idle");

  const req = useRequest();
  const { notify } = useUtils();
  const {
    state: { publicKey, privateKey },
    dispatch,
  } = useWallet();

  const handleClick = async () => {
    try {
      setStatus("loading");
      await req({
        url: `identities/${publicKey}/approve/`,
        body: {
          private_key: privateKey,
          approved: true,
        },
        options: { method: "POST" },
        withAuth: true
      });

      dispatch({ type: WalletActionType.SetHasApproved, payload: true });

      notify("Access has been granted.", "success");
      next();
    } catch (e) {
      console.log("error", e);
    } finally {
      setStatus("idle");
    }
  };

  return (
    <div>
      <Box sx={{ mt: 80, ta: "center", mxw: 400, mx: "auto" }}>
        <Type sx={{ fw: 500 }}>Allow Stitchain to use your wallet</Type>
        <Button
          onClick={handleClick}
          sx={{ mt: 30 }}
          loading={status === "loading"}
        >
          Allow
        </Button>
      </Box>
    </div>
  );
};

export default WalletApprove;
