/*
  Fetch the contents of the "message" textbox, and encode it
  in a form we can use for the encrypt operation.
  */
function getMessageEncoding(msg: string) {
  let enc = new TextEncoder();
  return enc.encode(msg);
}

async function digestMessage(password: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(password); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
}

/*
  Get the encoded message, encrypt it and display a representation
  of the ciphertext in the "Ciphertext" element.
  */
async function encryptMessage(msg: string, password: string) {
  const hash = await digestMessage(password);
  window.localStorage.setItem("wphash", hash);
  let encoded = getMessageEncoding(msg);
  let arPass = password.split("");
  const len = arPass.length;
  // iv must be 16 bytes
  if (len < 16) for (var i = 0; i < 16 - len; i++) arPass.push("0");
  else arPass = arPass.slice(0, 16);
  // The iv must never be reused with a given key.
  // iv = window.crypto.getRandomValues(new Uint8Array(16));
  var iv = new Uint8Array(arPass.map((c) => c.charCodeAt(0)));

  const key = await window.crypto.subtle.generateKey(
    {
      name: "AES-CBC",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );

  const exported = await window.crypto.subtle.exportKey("raw", key);

  const exportedKeyBuffer = new Uint8Array(exported);

  window.localStorage.setItem("exported-key", `[${exportedKeyBuffer}]`);

  const ciphertext = await window.crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv,
    },
    key,
    encoded
  );

  // let buffer = new Uint8Array(ciphertext, 0, 5);
  return ciphertext;
}

/*
  Fetch the ciphertext and decrypt it.
  Write the decrypted message into the "Decrypted" box.
  */
async function decryptMessage(ciphertext: Uint8Array, password: string) {
  return new Promise(async (resolve, reject) => {
    const hash = await digestMessage(password);
    if (hash !== window.localStorage.getItem("wphash")) {
      console.log("wrong passowrd");
      reject({ errors: { password1: ["Wrong password"] } });
    }
    let arPass = password.split("");
    const len = arPass.length;
    // iv must be 16 bytes
    if (len < 16) for (var i = 0; i < 16 - len; i++) arPass.push("0");
    else arPass = arPass.slice(0, 16);

    var iv = new Uint8Array(arPass.map((c) => c.charCodeAt(0)));
    var exportedKey = JSON.parse(
      window.localStorage.getItem("exported-key") || '{}'
    );

    const exportedKeyBuffer = new Uint8Array(exportedKey);

    const key = await window.crypto.subtle.importKey(
      "raw",
      exportedKeyBuffer,
      "AES-CBC",
      true,
      ["encrypt", "decrypt"]
    );

    try {
      let decrypted = await window.crypto.subtle.decrypt(
        {
          name: "AES-CBC",
          iv,
        },
        key,
        new Uint8Array(ciphertext)
      );

      let dec = new TextDecoder();
      resolve(dec.decode(decrypted));
    } catch (e) {
      console.error(e);
    }
  });
}

/*
  Generate an encryption key, then set up event listeners
  on the "Encrypt" and "Decrypt" buttons.
  */
// window.crypto.subtle
//   .generateKey(
//     {
//       name: "AES-CBC",
//       length: 256,
//     },
//     true,
//     ["encrypt", "decrypt"]
//   )
//   .then((key) => {
//     const encryptButton = document.querySelector(".aes-cbc .encrypt-button");
//     encryptButton.addEventListener("click", () => {
//       encryptMessage(key);
//     });

//     const decryptButton = document.querySelector(".aes-cbc .decrypt-button");
//     decryptButton.addEventListener("click", () => {
//       decryptMessage(key);
//     });
//   });

export { encryptMessage, decryptMessage };
