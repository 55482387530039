import { useQuery } from "@tanstack/react-query";
import { Box, TableContainer, Type } from "ui";
import usePosOrderApi from "views/orders/usePosOrderApi";
import { BaseStatus } from "types/types"; // enum
import type { Col } from "ui/tableContainer/TableContainer";
import type { PosOrder } from "views/orders/types";

const style = {
  // backgroundColor: "transparent",
  // color: "#444",
  fontWeight: "600",
  borderBottom: "#ddd 1px solid",
};

const cols: Col<PosOrder>[] = [
  {
    name: "customer_email",
    label: "Customer Email",
    props: { style },
  },
  {
    name: "customer_name",
    label: "Customer Name",
    props: { style },
  },
  {
    name: "phone_number",
    label: "Phone Number",
    props: { style },
  },
  {
    name: "order_details_count",
    label: "Order Details Count",
    sx: {ta: 'right'},
    props: { style: {...style, textAlign: 'right'} },
  },
  {
    name: "created_on",
    label: "Created",
    fn: "datetime",
    props: { style },
  },
  {
    name: "created_by",
    label: "Created by",
    props: { style },
    render: ({ row }) => (
      <Type>
        {row.created_by.first_name} {row.created_by.last_name}
      </Type>
    ),
  },
];

export default function OrderTable() {
  // const {
  //   state: { list, status },
  //   fetchList,
  // } = useOrders();

  // useEffect(() => {
  //   fetchList(0, 8);
  // }, [fetchList]);
  const { getPosOrders, page } = usePosOrderApi();

  const { data, isPending } = useQuery({
    queryKey: ["posOrder", page],
    queryFn: () => getPosOrders(page),
    placeholderData: (previousData) =>
      previousData || { count: 0, results: [] },
  });

  const { results: list } = data;

  return (
    <Box>
      <TableContainer
        empty={list.length === 0}
        status={isPending ? BaseStatus.Fetching : BaseStatus.Idle}
        data={list}
        aria-label="Last orders table"
        cols={cols}
      />
    </Box>
  );
}
