import { FormField, ValidationsTypes } from "types/types";

function _mapType(type: Number): ValidationsTypes {
  switch (type) {
    case 1:
      return "number";
    case 2:
      return "string";
    default:
      return "boolean";
  }
}

export default function genFieldsObjFromSpec(spec: any[]): FormField[] {
  return spec.map((f) => {
    const type = _mapType(f.field_type);
    const obj: FormField = {
      name: f.id.toString(),
      type: type === "boolean" ? "checkbox" : type,
      label: f.name,
      validationType: type,
      required: f.is_required,
      validations: [],
    };

    if (f.is_required) {
      obj.validations.push({
        type: "required",
        params: ["this field is required."],
      });
      if (type === "boolean")
        obj.validations.push({
          type: "oneOf",
          params: [[true], "field must be checked"],
        });
    }
    if (type === "number")
      obj.validations.push({
        type: "typeError",
        params: ["a number is required"],
      });
    return obj;
  });
}
