import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { Box, Button, Icon, Link, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import Card from "../../ui/Card";
import Dropdown from "../../ui/core/Dropdown";
// import useImageLink from "../../utilities/hooks/useImageLink";

const List = styled.ul`
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  border-bottom: ${(p) => p.theme.color.gray200} 1px solid;
  li {
    margin: 10px 0;
    font-size: 15px;
  }
`;

function UserInfo() {
  const { user } = useAuth();

  return (
    <Box sx={{ fs: 13, c: "gray300", p: 15, pb: 0 }}>
      <Box sx={{ m: 5 }}>
        <Type as="strong">
          {user?.first_name} {user?.last_name}
        </Type>
      </Box>
    </Box>
  );
}

function ProfileOpts() {
  const { signout } = useAuth();

  return (
    <Card sx={{ minw: 150 }} padding="0">
      <UserInfo />

      <List>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/profile">Profile</Link>
        </li>
      </List>

      <Button
        // size="large"
        variant="text"
        iconName="powerOff"
        bg="secondary"
        sx={{ w: "calc(100% - 40px)", mx: 20, my: 10 }}
        onClick={() => {
          signout();
          window.location.replace(`/login`);
        }}
      >
        Sign out
      </Button>
    </Card>
  );
}

function UserAccount() {
  // const { user } = useAuth();
  // const imgUrl = useImageLink(user?.avatarURL);

  return (
    <Dropdown space={21} content={<ProfileOpts />}>
      <span>
        <Avatar
          role="button"
          aria-label="current user account"
          var="dark"
          className="avatar"
          size={30}
          sx={{ cursor: "pointer" }}
        >
          {/* {imgUrl ? (
              <img src={imgUrl} alt="user profile avatar" />
            ) : ( */}
          <div>
            <Icon name="user" w={18} c="gray900" />
          </div>
          {/* )} */}
        </Avatar>
      </span>
    </Dropdown>
  );
}

export default UserAccount;
