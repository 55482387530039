import styled from "styled-components";

const Responsive = styled.div`
  width: 100%;
  overflow-x: auto;
  /* padding-bottom: 20px; */
  -webkit-overflow-scrolling: touch;
  background-color: ${p => p.theme.color.gray200};
  scrollbar-color: rgba(0, 0, 0, 0.1) white;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    height: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export default Responsive;