import timeZoneCityToCountry from "../data/tz-cities-to-countries.json";
import countries from "../data/countries.json";

var timezoneList: any = timeZoneCityToCountry;

interface country {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}

interface userLocation {
  country: string;
  city: string;
  region: string;
  timezone: string;
  countryDetails: country | undefined;
}

export default function getUserCountry(): userLocation {
  var userLocation: userLocation = {
    country: "",
    city: "",
    region: "",
    timezone: "",
    countryDetails: undefined,
  };

  if (Intl) {
    userLocation.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var tzArr: any[] = userLocation.timezone.split("/");
    userLocation.region = tzArr[0];
    userLocation.city = tzArr[tzArr.length - 1];
    userLocation.country = timezoneList[userLocation.city];
  }

  userLocation.countryDetails = countries.find(
    (c) => c.name.toLowerCase() === userLocation.country?.toLowerCase()
  );

  return userLocation;
}
