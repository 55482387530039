import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import createYupSchema from "utilities/yupSchemaCreator";
import { useItems } from "./provider";
import { Box, Button, Card, Type } from "ui";
import useExpandFieldsFromSpec from "./hooks/useExpandFieldsFromSpec";
import FormFields from "components/FormFields";
import cleanObj from "utilities/cleanObj";
import { FormField, SpecField } from "types/types";

type ItemFormProps = {
  item?: any;
  product: any;
  specFields: SpecField[];
  handleSuccess :() => void;
}

function ItemForm({ item, product, specFields, handleSuccess }: ItemFormProps) {
  const [error, setError] = useState(null);

  const { createItem, editItem } = useItems();

  const formFields: FormField[] = useExpandFieldsFromSpec(specFields);

  const yupSchema = createYupSchema(formFields, {
    viewType: Boolean(item) ? "edit" : "new",
  });

  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (item) {
      setValue("name", item.name);
      setValue("count", item.count);
      setValue("image", item.image);
      setValue("description", item.description);
      if (item["json_data"])
        Object.keys(item["json_data"]).forEach((k) => {
          setValue(k + "", item["json_data"][k]);
        });
    }
  }, [item, setValue]);

  const onSubmit = async (values: any) => {
    const data: any = {
      name: values.name,
      description: values.description,
      count: values.count,
    };

    if (!item) data.image = values.image;

    delete values.name;
    delete values.description;
    delete values.count;
    delete values.image;

    // don't send empty object properties to server
    data.save_json_data = cleanObj(values);

    try {
      if (item) await editItem(item.id, {...data});
      else {
        await createItem(data, product.id);
        reset();
      }
      handleSuccess();
    } catch (e: any) {
      setError(
        <>
          <div>Server error.</div>
          {e.errors?.data?.map((e: any) => (
            <p>{e}</p>
          ))}
        </>
      );
      console.log("e", e);
    }
  };

  return (
    <Box
    as="form"
    onSubmit={handleSubmit(onSubmit)}
    noValidate
    sx={{ mt: 40, pb: 20, mxw: 400 }}
    >
    <Card>
      {error && <Box sx={{ mt: 20, mb: 20, c: "red" }}>{error}</Box>}

      <Type var="h5" as="h5" sx={{ mb: 30, mt: 15 }}>
        Product: {product?.name}
      </Type>

      <FormFields
        fields={formFields}
        errors={errors}
        setValue={setValue}
        watch={watch}
        register={register}
        editing={Boolean(item)}
      />

      <Button
        type="submit"
        loading={isSubmitting}
      >
        {item ? "Save" : "Create"}
      </Button>
      {isSubmitting && (
        <Type sx={{ mt: 20 }}>
          Please note that this action may take a while
        </Type>
      )}
    </Card>
    </Box>
  );
}

export default memo(ItemForm);
