/**
 * createThumb
 * create the thumb of image with given width and height
 * @param  {file} file       File uploaded
 * @param  {number} w             width of the thumb
 * @param  {number} h             height of the thumb
 * @return {blob}                 the thumb
 */

export default function createThumb(file: any, w: number, h: number): any {
  return new Promise((resolve, reject) => {
    try {
      var reader: FileReader = new FileReader();

      reader.onloadend = function () {
        var img: HTMLImageElement = document.createElement("img");
        img.onload = async function (e) {
          resolve(await _resize(img, w, h, file.type));
        };
        img.onerror = (e) => {
          console.log(e);
        };
        img.src = reader.result as string;
      };
      //This starts the conversion
      reader.readAsDataURL(file);
    } catch (e: any) {
      console.error(e);
      reject(e.message);
    }
  });

  function _resize(
    img: HTMLImageElement,
    w: number,
    h: number,
    type: string
  ): Promise<Blob | null> {
    var width = img.width;
    var height = img.height;

    if (width < height) {
      height = height * (w / width);
      width = w;
    } else {
      width = width * (h / height);
      height = h;
    }

    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    var ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    ctx?.drawImage(img, 0, 0, width, height);

    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, type);
      } catch (e: any) {
        console.error(e);
        reject(e.message);
      }
    });
  }
}
