import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

type LoaderProps = {
  $size: number;
};

const Loader = styled.span<LoaderProps>`
  display: inline-block;
  border: ${(p) => p.$size / 9 + "px"} solid transparent;
  border-top: ${(p) => p.$size / 9 + "px"} solid
    ${(p) => p.theme.color.grayLight};
  border-left: ${(p) => p.$size / 9 + "px"} solid
    ${(p) => p.theme.color.grayLight};
  border-radius: 50%;
  width: ${(p) => p.$size + "px"};
  height: ${(p) => p.$size + "px"};
  animation: ${rotate} 0.6s linear infinite;
`;

function Loading({ size = 32 }) {
  return (
    <Loader
      className="loading"
      role="timer"
      aria-label="loading spinner"
      $size={size}
    />
  );
}

export default Loading;
