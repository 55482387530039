import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";
import { WalletActionType, useWallet } from "./Provider";
import Password from "./WalletResetPass/Password";
import Phrase from "./WalletResetPass/Phrase";
import { Box, Type } from "ui";
import { encryptMessage } from "utilities/encrypt-aes-cbc";

function WalletImport() {
  const [words, setWords] = useState(new Array(12).fill(""));
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState("");

  const {
    state: { privateKey },
    dispatch,
  } = useWallet();

  const req = useRequest();
  const navigate = useNavigate();

  const { user } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const newWords = words.map((w, index) =>
      index === i ? e.target.value : w
    );
    setWords(newWords);
  };

  const handlePhrase = async () => {
    setError("");
    setLoading(true);
    try {
      const resData = await req({
        url: `wallets/`,
        body: { mnemonic: words.join(" ") },
        options: { method: "POST" },
        withAuth: true
      });
      const currentPublicKey = user.manufacturer.identity_address;
      if (resData.public_key === currentPublicKey) {
        window.localStorage.setItem("wallet_public_key", resData.public_key);
        dispatch({ type: WalletActionType.SetPrivateKey, payload: resData.private_key });
        setShowPass(true);
      } else setError("The phrase is not correct.");
    } catch (e: any) {
      console.log(e);
      if (e.status === 400) setError("The phrase is not correct.");
    } finally {
      setLoading(false);
    }
  };

  const handlePassword = async (password: string) => {
    const cipherPack = await encryptMessage(privateKey, password);
    const cipherPackBuffer = new Uint8Array(cipherPack);
    window.localStorage.setItem("wallet_enc_pack", `[${cipherPackBuffer}]`);
    navigate("/wallet");
  };

  return (
    <>
      <Box sx={{ mt: 50, ta: "center" }}>
        <Type var="h5" sx={{ mb: 20 }}>
          Import Wallet
        </Type>

        {!showPass ? (
          <Phrase
            words={words}
            onChange={handleChange}
            loading={loading}
            next={handlePhrase}
          />
        ) : (
          <Password next={handlePassword} loading={loading} isImport={true} />
        )}

        {Boolean(error.length) && (
          <Type sx={{ mxw: 260, mt: 30, mx: "auto", c: "red" }}>
            {error}
          </Type>
        )}
      </Box>
    </>
  );
}

export default WalletImport;
