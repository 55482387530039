import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import Tags from "./Tags";
import List from "./List";

export default (
  <Route path="tags" element={<RequireAuth><Tags /></RequireAuth>}>
    <Route index element={<RequireAuth><List /></RequireAuth>} />
  </Route>
);
