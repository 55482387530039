import { memo, useState } from "react";
import { Box, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import { Col } from "ui/tableContainer/TableContainer";
import { useQuery } from "@tanstack/react-query";
import { useRequest } from "services/request";
import { BaseStatus } from "types/types";
import { Tag, TagFetch } from "./types";
import useApi from "./useApi";

const cols: Col<Tag>[] = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  {
    label: "Bound",
    name: "bound",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: ({ row }) =>
      row.bound ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        ""
      ),
  },
  {
    label: "Stitchain based verification",
    name: "stit_based_verification",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: ({ row }) =>
      row.stit_based_verification ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        <Box sx={{ ta: "center" }}>-</Box>
      ),
  },
  {
    label: "Write verification hash",
    name: "write_verification_hash",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: ({ row }) =>
      row.write_verification_hash ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        <Box sx={{ ta: "center" }}>-</Box>
      ),
  },
  { label: "Tag Type", name: "tag_type" },
];

function List() {
  const { fetch } = useApi();

  const handleChangePage = (newPage: number) => fetch.setPage(newPage);

  return (
    <>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Tags List
        </Type>
      </Flex>

      <Box>
        <TableContainer
          empty={fetch.data?.results?.length === 0}
          data={fetch.data?.results}
          status={fetch.isPending ? BaseStatus.Fetching : BaseStatus.Idle}
          aria-label="Tags table"
          cols={cols}
        />

        <Pagination
          data-testid="tags-pagination"
          count={fetch.data?.count}
          page={fetch.page}
          onPageChange={handleChangePage}
        />
      </Box>
    </>
  );
}

export default memo(List);
