import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import Type from "./Type";
import Icon, { IconType } from "./Icon";
import stylesBlocks, { StylesBlocksProps } from "./core/stylesBuilder";
import { ChangeEvent, ComponentPropsWithoutRef, InputHTMLAttributes, MouseEvent } from "react";
import { Size } from "types/types";

interface WrapperProps extends StylesBlocksProps {
  noSpace?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  margin-bottom: 25px;
  ${(p) =>
    p.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    &.icon-left {
      input {
        padding-left: 45px;
      }
    }
    > span {
      position: absolute;
      &.left {
        left: 13px;
      }
      &.right {
        right: 13px;
      }
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      &.clickable {
        pointer-events: auto;
        cursor: pointer;
      }
      line-height: 0;
      svg {
        width: 16px;
        fill: ${(p) => p.theme.color.gray700};
      }
    }
  }
  ${stylesBlocks}
`;

type InputTextProps = {
  label?: string;
  value?: string;
  name?: string;
  id?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  msg?: string;
  error?: boolean;
  iconLeft?:
    | IconType
    | [IconType, (event: MouseEvent<HTMLButtonElement>) => void];
  iconRight?:
    | IconType
    | [IconType, (event: MouseEvent<HTMLButtonElement>) => void];
  size?: Size;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
} & ComponentPropsWithoutRef<"div">;

function InputText({
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg,
  error = false,
  iconLeft,
  iconRight,
  size = "large",
  inputProps,
  ...props
}: InputTextProps) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      <div
        className={`inputWrap ${iconLeft && "icon-left"} ${
          iconRight && "icon-right"
        }`}
      >
        {iconLeft && (
          <span
            className={`left ${typeof iconLeft !== "string" && "clickable"}`}
            onClick={typeof iconLeft === "string" ? undefined : iconLeft[1]}
          >
            <Icon
              name={typeof iconLeft === "string" ? iconLeft : iconLeft[0]}
            />
          </span>
        )}
        {iconRight && (
          <span
            className={`right ${typeof iconRight !== "string" && "clickable"}`}
            onClick={typeof iconRight === "string" ? undefined : iconRight[1]}
          >
            <Icon
              name={typeof iconRight === "string" ? iconRight : iconRight[0]}
            />
          </span>
        )}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          id={id || inputProps?.id}
          aria-label={id || inputProps?.id || label || placeholder}
          {...{
            value,
            type,
            name,
            onChange,
            placeholder,
            $size: size,
            ...inputProps,
          }}
        />
      </div>
      {msg && <Type sx={{ c: error ? "red" : "gray600", mt: 8 }}>{msg}</Type>}
    </Wrapper>
  );
}

export default InputText;
