import { Dispatch, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText, Type } from "ui";

const schema = Yup.object().shape({
  password1: Yup.string().min(8, "Too Short!").required("Required"),
  password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("password1"), null], "Passwords must match"),
});

type WalletPasswordProps = {
  next: () => void;
  setPassword: Dispatch<React.SetStateAction<string>>
}

function WalletPassword({ next, setPassword }: WalletPasswordProps) {
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    try {
      setPassword(values.password1);
      next();
    } catch (e: any) {
      console.log("error", e);
      setServerErrors(e.errors);
    }
  };

  return (
    <div id="create-password">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 10, ta: "left", mxw: 400, mx: "auto" }}
      >
        <Type sx={{ py: 30, ta: 'center' }}>
          You will use this password to unlock your wallet account.
        </Type>
        <InputText
          id="password1"
          type="password"
          label="New Password"
          name="password1"
          error={Boolean(errors.password1 || serverErrors?.password1)}
          msg={
            errors.password1?.message || serverErrors?.password1
          }
          inputProps={{
            ...register("password1"),
          }}
        />

        <InputText
          id="password2"
          type="password"
          label="Confirm Password"
          name="password2"
          error={Boolean(errors.password2 || serverErrors?.password2)}
          msg={
            errors.password2?.message || serverErrors?.password2
          }
          inputProps={{
            ...register("password2"),
          }}
        />

        <Button sx={{ mt: 40, mx: 'auto', d: 'flex' }} loading={isSubmitting} iconName="arrowRight">
          Create Password
        </Button>
      </Box>
    </div>
  );
}

export default WalletPassword;
