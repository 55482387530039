import styled, { css } from "styled-components";
import { Size } from "types/types";

type CloseProps = {
  x: number;
  y: number;
  size: Size;
}

const Close = styled.div<CloseProps>`
  position: absolute;
  top: ${(p) => p.y || 40}px;
  right: ${(p) => p.x || 40}px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.primary};
  transform-origin: center;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  &::before,
  &::after {
    content: "";
    width: 21px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform-origin: center;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    opacity: 0.7;
    transform: scale(0.96);
  }
  &:active {
    opacity: 0.6;
    transform: scale(0.93);
  }

  ${(p) => {
    if (p.size === "small")
      return css`
        width: 28px;
        height: 28px;
        &::before,
        &::after {
          width: 15px;
        }
      `;
    else if (p.size === "medium")
      return css`
        width: 36px;
        height: 36px;
        &::before,
        &::after {
          width: 22px;
        }
      `;
    return css`
      width: 42px;
      height: 42px;
    `;
  }};
`;

export default Close;
