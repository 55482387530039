import { Position } from "types/types";

export interface Point {
  x: number;
  y: number;
  readonly reset?: (pt: Point) => void;
}

interface Rect {
  l: number;
  r: number;
  t: number;
  b: number;
}

function oppositeDirection(pos: Position): Position {
  if (pos === "left") return "right";
  if (pos === "right") return "left";
  if (pos === "top") return "bottom";
  else return "top";
}

function _isVertical(pos: Position): Boolean {
  return pos === "top" || pos === "bottom";
}

function _isHorizontal(pos: Position): Boolean {
  return pos === "left" || pos === "right";
}

function _restrictRect(pt: Point, rect: Rect): Point {
  var ptCopy = { ...pt };
  if (pt.x < rect.l) ptCopy.x = rect.l;
  else if (pt.x > rect.r) ptCopy.x = rect.r;
  if (pt.y < rect.t) ptCopy.y = rect.t;
  else if (pt.y > rect.b) ptCopy.y = rect.b;

  return ptCopy;
}

// NOTE: don't hide el by setting it's style display to none,
// hide it by setting it's opacity to 0 and pointer-event to none.
const getPoint = (
  el: HTMLElement,
  tt: HTMLElement,
  placement: Position,
  space: number
): Point => {
  let recurCount = 0;
  let pt: Point = { x: 0, y: 0 };
  const rect = {
    l: space,
    t: space,
    r: document.body.clientWidth - (tt.clientWidth + space),
    b: window.innerHeight - (tt.clientHeight + space),
  };

  const elRect: DOMRect = el.getBoundingClientRect();

  return (function recursive(placement: Position): Point {
    recurCount++;
    // const pos = position(placement);
    switch (placement) {
      case "left":
        pt.x = elRect.left - (tt.offsetWidth + space);
        pt.y =
          elRect.top +
          ((el.offsetHeight || elRect.height) -
            tt.offsetHeight) /
            2;
        break;
      case "right":
        pt.x = elRect.right + space;
        pt.y =
          elRect.top +
          ((el.offsetHeight || elRect.height) -
            tt.offsetHeight) /
            2;
        break;
      case "top":
        pt.x =
          elRect.left +
          ((el.offsetWidth || elRect.width) -
            tt.offsetWidth) /
            2;
        pt.y = elRect.top - (tt.offsetHeight + space);
        break;
      default:
        pt.x =
          elRect.left +
          ((el.offsetWidth || elRect.width) -
            tt.offsetWidth) /
            2;
        pt.y = elRect.bottom + space;
    }

    if (recurCount < 3)
      if (
        (_isHorizontal(placement) && (pt.x < rect.l || pt.x > rect.r)) ||
        (_isVertical(placement) && (pt.y < rect.t || pt.y > rect.b))
      ) {
        pt = recursive(oppositeDirection(placement));
      }

    return _restrictRect(pt, rect);
  })(placement);
};

export { getPoint, oppositeDirection };
