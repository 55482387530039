import styled from "styled-components";
import Flex from "./Flex";
import Loading from "./Loading";
import Mask from "./Mask";
import Type from "./Type";
import BackArrow from "./BackArrow";
import stylesBlocks, { StylesBlocksProps } from "./core/stylesBuilder";
import isPropValid from "@emotion/is-prop-valid";
import type * as CSS from "csstype";
import { Color } from "theme";
import { BaseHTMLAttributes } from "react";

interface WrapProps
  extends StylesBlocksProps,
    BaseHTMLAttributes<HTMLDivElement> {
  elevation?: number;
  elv?: number;
  bg?: Color;
  rad?: number;
}

const Wrap = styled.div
  .withConfig({ shouldForwardProp: (p) => isPropValid(p) })
  .attrs<WrapProps>((p) => ({
    elv: Number.isInteger(p.elevation) ? p.elevation : 1,
  }))`
  box-shadow: ${(p) => (p.elv || 0) * 0.2}px ${(p) => (p.elv || 0)}px
      ${(p) => (p.elv || 0) * 2}px ${(p) => (p.elv || 0) * 0.2}px ${(p) =>
  p.theme.color.black + "11"},
    0px ${(p) => (p.elv || 0) * 3}px ${(p) => (p.elv || 0) * 3}px 0px
      ${(p) => p.theme.color.black + "22"};
  background-color: ${(p) => p.theme.color[p.bg || "white"]};
  border-radius: ${(p) => (p.rad ? p.rad : "4")}px;
  /* border: ${(p) => p.theme.color.gray100} 1px solid; */
  position: relative;
  ${stylesBlocks}
`;

type HeadProps = {
  padding: CSS.Properties["padding"];
};

const Head = styled.div.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<HeadProps>`
  padding: ${(p) => p.padding || "20px 30px"};
  /* border-bottom: ${(props) => props.theme.color.gray300} 1px solid; */
`;

type BodyProps = HeadProps;

const Body = styled.div.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<BodyProps>`
  padding: ${(p) => p.padding || "17px 20px"};
  position: relative;
  @media only print {
    width: 90vw;
  }
`;

interface CardProps extends Partial<WrapProps> {
  title?: string;
  loading?: boolean;
  padding?: CSS.Properties["padding"];
}

function Card({
  title,
  children,
  loading = false,
  padding,
  ...props
}: CardProps) {
  return (
    <Wrap {...props}>
      {Boolean(title) && (
        <Head padding={padding}>
          <Flex jc="space-between">
            <Type var="h3" as="h3" sx={{ m: 0 }}>
              {title}
            </Type>
            <BackArrow />
          </Flex>
        </Head>
      )}
      <Body padding={padding}>{children}</Body>
      {loading && (
        <Mask relative show={loading} style={{ borderRadius: "15px" }}>
          <Loading />
        </Mask>
      )}
    </Wrap>
  );
}

export default Card;
