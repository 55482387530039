import { Card, Checkbox, Grid, Loading, Type } from "ui";
import { User } from "../type";
import { useQuery } from "@tanstack/react-query";
import useApi from "../useApi";

type RolesListProps = {
  onChange: (role: number[]) => void;
  userRolesIds: number[];
  user?: User;
};

const RolesList = ({ onChange, userRolesIds, user }: RolesListProps) => {
  const { getRoles } = useApi();

  const query = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;

    const roleId = Number(id.split("_")[0]);

    const newRoles = checked
      ? [...userRolesIds, roleId]
      : userRolesIds.filter((rId) => rId !== roleId);

    onChange(newRoles);
  };

  const { data: roles, isPending, isError } = query;

  if (isError)
    return (
      <Type sx={{ ta: "center" }}>Something went wrong, please try again.</Type>
    );
  if (isPending) return <Loading size={24} />;

  return (
    <Card>
      <Grid cols="1fr 1fr" colGap={0} onChange={handleChange}>
        {roles.map((role: any) => (
          <Checkbox
            style={{ margin: "10px 0" }}
            // value={role.name}
            key={role.name + role.id}
            label={role.name}
            id={user ? `${role.id}_${user.user_id}` : role.id}
            size="small"
            inputProps={{
              name: "roles",
              defaultChecked: Boolean(userRolesIds.includes(role.id)),
              disabled:
                user && user?.user_id === user.created_by && role.id === 1,
            }}
          />
        ))}
      </Grid>
    </Card>
  );
};

export default RolesList;
