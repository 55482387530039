import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";
import { AuthContextType, Tokens, useAuth } from "./auth";

var refreshing = false;
let commandsQueue: (() => void)[] = [];

type reqParamsType = {
  url: string | URL | Request;
  body?: any;
  options?: Omit<RequestInit, "body">;
  withAuth?: boolean;
  isFile?: boolean;
};

export const useRequest = () => {
  const auth = useAuth();

  const queue = useCallback(
    (params: reqParamsType): Promise<any> => {
      if (refreshing)
        return new Promise((resolve, reject) => {
          const command = () => {
            queue(params).then(resolve, reject);
          };
          commandsQueue.push(command);
        });

      return new Promise(async (resolve, reject) => {
        try {
          if (refreshing) await new Promise((r) => setTimeout(r, 1000));
          if (params.withAuth) await checkToken(auth);

          const res = await request(params);

          if (res.status === 401) {
            if (auth) {
              auth.signout();
              window.location.replace(
                `/login?from=${window.location.pathname}`
              );
              return;
            }
            reject({ status: res.status, errors: "Unauthorised Access" });
          } else if (res.status === 403) {
            window.location.replace("/no-access");
            reject({ status: res.status, errors: "Access Denied" });
          } else if (!res.ok) {
            const eData = await res.json();
            reject({ status: res.status, errors: eData });
          }
          const resData = await (res.status !== 204 ? res.json() : {});
          resolve(resData);
        } catch (error) {
          console.log("console error", error);
          reject(error);
        }
      });
    },
    [auth]
  );

  return queue;

  // return useCallback(queue, [auth]);
};

export default function request(params: reqParamsType): Promise<Response> {
  const defaultOptions: RequestInit = {
    method: params.body ? "POST" : "GET",
    referrerPolicy: "no-referrer",
    redirect: "follow",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",
    ...params.options,
  };

  const requestHeaders = new Headers({
    "Content-Type": "application/json",
    ...params.options?.headers,
  });

  if (params.body) {
    defaultOptions.body = params.isFile
      ? params.body
      : JSON.stringify(params.body);
  }

  if (params.withAuth) {
    requestHeaders.set(
      "Authorization",
      `jwt ${JSON.parse(localStorage.getItem("user"))?.tokens?.token}`
    );
  }

  defaultOptions.headers = requestHeaders;

  return fetch(`${baseUrl}/${params.url}`, defaultOptions);
}

function checkToken(auth: AuthContextType): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const tokens: Tokens = JSON.parse(localStorage.getItem("user"))?.tokens;
    if (!tokens || tokens === undefined) return resolve();

    const jwt = JSON.parse(atob(tokens.token.split(".")[1]));

    let milliExp = jwt.exp * 1000;

    if (milliExp < Date.now()) {
      refreshing = true;
      try {
        await refreshToken(tokens, auth);
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        refreshing = false;
        commandsQueue.forEach((command) => command());
        commandsQueue = [];
      }
    }
    resolve();
  });
}

async function refreshToken(
  tokens: Tokens,
  auth: AuthContextType
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request({
        url: `api-token-refresh/`,
        body: { refresh: tokens.refresh },
      });

      const data: Tokens = await res.json();

      if (data?.token) auth?.saveTokens(data);

      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
