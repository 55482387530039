import ToggleSidebar from "layout/header/ToggleSidebar";
import { Box } from "../../ui";
import Nav from "./Nav";

type SidebarProps = {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function Sidebar({ setExpanded, expanded, open, setOpen }: SidebarProps) {
  return (
    // <Box {...props} style={{position: 'sticky', top: '60px', height: 'calc(100vh - 69px)'}}></Box>
    <Box
      sx={{
        bgc: "gray900",
        w: open ? (expanded ? 240 : 60) : 0,
        tr: "all 0.1s ease-in",
        h: "calc(100vh - 68px)",
        pos: "sticky",
        top: 68,
        z: 9999,
      }}
    >
      <Nav isExpanded={expanded} isOpen={open} setOpen={setOpen} />
      <Box
        sx={{
          pos: "fixed",
          bottom: 20,
          left: 15,
          d: ["block", "_", "_", "none"],
        }}
        onClick={() => setExpanded((v) => !v)}
      >
        <ToggleSidebar isOpen={expanded} />
      </Box>
    </Box>
  );
}

export default Sidebar;
