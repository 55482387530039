import { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Size } from "types/types";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $size?: Size;
};

const Input = styled.input<InputProps>`
  outline: ${(props) => props.theme.color.gray200} 1px solid;
  background-color: ${(props) => props.theme.color.gray100};
  border-radius: 4px;
  height: 42px;
  padding: 0 15px 0 15px;
  ${(props) => {
    if (props.$size === "medium")
      return css`
        height: 38px;
        padding: 0 5px 0 20px;
      `;
    else if (props.$size === "small")
      return css`
        height: 34px;
        padding: 0 5px 0 14px;
      `;
  }}
  ::placeholder {
    color: ${(p) => p.theme.color.gray500};
    font-size: 14px;
  }
  width: 100%;
  border: none;
  font-size: 15px;
  color: ${(p) => p.theme.color.gray800};
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  &:focus,
  &:active {
    outline: ${(p) => p.theme.color.secondary} 1px solid;
    background-color: ${(p) => p.theme.color.gray50};
  }
  &:read-only,
  &:disabled {
    outline: ${(p) => p.theme.color.gray200} 1px solid;
    color: ${(p) => p.theme.color.gray500};
    background-color: ${(p) => p.theme.color.gray50};
  }
  ${(props) =>
    props.type === "textarea" &&
    css`
      height: 120px;
      padding: 15px;
    `}
`;

export default Input;
