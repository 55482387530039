import { BaseHTMLAttributes, memo } from "react";
import RowActions from "./RowActions";
import { Flex, Loading, Type } from "ui";
import { Spec } from "./types";
import useStore from "useStore";

interface SpecRowProps extends BaseHTMLAttributes<HTMLDivElement> {
  spec: Spec;
  onToggle: (id: number) => void;
}

function SpecRow({
  spec,
  onToggle,
}: SpecRowProps) {
  const {selected} = useStore(state => state.spec);

  if (!spec) return <Loading size={20} />;
  const labelId = `checkbox-list-label-${spec.id}`;
  const isSelected = spec.id === selected;

  return (
    <Flex
      role="tab"
      jc="space-between"
      onClick={() => onToggle(spec.id)}
      aria-selected={isSelected ? "true" : "false"}
      sx={{
        px: 15,
        py: 10,
        cursor: !isSelected ? "pointer" : "default",
        bgc: isSelected ? "gray300" : "white",
        ":hover": {
          bgc: !isSelected ? "gray100" : "gray300",
        },
      }}
    >
      <Type sx={{ fw: 500 }} id={labelId}>
        {spec.name}
      </Type>
      <RowActions spec={spec} />
    </Flex>
  );
}

export default memo(SpecRow);
