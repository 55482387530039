import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemForm from "./ItemForm";
import { useUtils } from "../../services/utilsProvider";
import { useProducts } from "../products/provider";
import { Flex, Link, Loading, Type } from "ui";
import { useItems } from "./provider";
import useFieldApi from "views/fields/useFieldApi";
import { useQueryClient } from "@tanstack/react-query";

function EditItem() {
  const [itemSpecFields, setItemSpecFields] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();
  const { getList } = useFieldApi();
  const queryClient = useQueryClient();

  const {
    state: { product },
    fetchProduct,
  } = useProducts();

  const {
    state: { selected, parentId },
    fetchOne,
  } = useItems();

  // const {
  //   fetchList: fetchFields,
  // } = useFields();

  useEffect(() => {
    if (!params.pid) return;
    fetchProduct(Number(params.pid));
  }, [fetchProduct, params.pid]);

  useEffect(() => {
    fetchOne(Number(params.id));
  }, [params.id, fetchOne]);

  useEffect(() => {
    if (!product || !product["item_fields_schema"]) return;
    const specId = product["item_fields_schema"];
    (async () => {
      // const data = await fetchFields(specId, 0, 100);
      const data = await queryClient.fetchQuery({
        queryKey: ["allFields", specId],
        queryFn: () =>
          getList({
            specId: specId,
            page: 0,
            pageSize: 100,
          }),
      });
      setItemSpecFields(data.results);
    })();
  }, [product, queryClient, getList]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Item saved successfully", "success");
  };

  return (
    <>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Dashboard</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
        <Type>-</Type>
        <Link to={`/products/${product?.id}/bulks`}>Bulks</Link>
        <Type>-</Type>
        <Link to={`/products/${product?.id}/bulk/${parentId}/items`}>Items</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Edit Item
      </Type>

      {!product || !itemSpecFields?.length || !selected ? (
        <Loading />
      ) : (
        <ItemForm item={selected} product={product} specFields={itemSpecFields} handleSuccess={handleSuccess} />
      )}
    </>
  );
}

export default memo(EditItem);
