export default function debounce(fn: Function, ms: number) {
  let timer: NodeJS.Timeout | undefined;
  return function () {
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
      fn.apply(null, args);
    }, ms);
  };
}
