import { memo, useEffect, useState } from "react";
import ProductForm from "./ProductForm";
import { useUtils } from "../../services/utilsProvider";
import { Box, Card, Flex, Link, Loading, Select, Type } from "ui";
import { SelectOption } from "ui/select/Selected";
import { orArray } from "types/types";
import useFieldApi from "views/fields/useFieldApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useSpecApi from "views/specs/useSpecApi";
import { useNavigate } from "react-router-dom";

function AddProduct() {
  const [productSpec, setProductSpec] = useState(null);
  const [itemSpec, setItemSpec] = useState(null);
  const [productSpecFields, setProductSpecFields] = useState([]);

  const { notify } = useUtils();
  const { getList } = useFieldApi();
  const { getList: getSpecs } = useSpecApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: specs, isPending } = useQuery({
    queryKey: ["allSpecs"],
    queryFn: () => getSpecs({ page: 0, pageSize: 999 }),
  });

  useEffect(() => {
    if (!productSpec) return;
    (async () => {
      const data = await queryClient.fetchQuery({
        queryKey: ["allFields", productSpec.id],
        queryFn: () =>
          getList({
            specId: productSpec.id,
            page: 0,
            pageSize: 100,
          }),
      });
      setProductSpecFields(data.results);
    })();
  }, [productSpec, queryClient, getList]);

  const handleSpecChange = (selected: orArray<SelectOption>) => {
    console.log(!Array.isArray(selected) ? selected.data : selected[0].data);
    setProductSpec(!Array.isArray(selected) ? selected.data : selected[0].data);
  };

  const handleItemSpecChange = (selected: orArray<SelectOption>) => {
    setItemSpec(!Array.isArray(selected) ? selected.data : selected[0].data);
  };

  const handleSuccess = () => {
    notify("Product created successfully", "success");
    setProductSpec(null);
    setItemSpec(null);
    navigate("/products");
  };

  const steps = [
    {
      label: "Select product spec",
      description: (
        <Select
          style={{ width: "100%" }}
          label="Product Spec"
          onChange={handleSpecChange}
          data={specs}
          mapData={{ value: "id", label: "name" }}
          inputProps={{
            value: productSpec || {},
          }}
        />
      ),
    },
    {
      label: "Select item Spec",
      description: (
        <Select
          style={{ width: "100%" }}
          label="Item Spec"
          onChange={handleItemSpecChange}
          mapData={{ value: "id", label: "name" }}
          data={specs}
          inputProps={{
            value: itemSpec || {},
          }}
        />
      ),
    },
    {
      label: "Create Product",
      description: Boolean(productSpec && itemSpec) ? (
        <ProductForm
          {...{ productSpec, productSpecFields, itemSpec, handleSuccess }}
        />
      ) : (
        <Type sx={{ c: "red" }}>
          Select a product spec and an item spec first.
        </Type>
      ),
    },
  ];

  return (
    <>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Dashboard</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Add Product
      </Type>

      {specs?.length ? (
        <Card sx={{ mt: 40, w: [500, 420, 380, 300] }}>
          {steps.map((step, i) => (
            <Box key={step.label} sx={{ mb: 0, mt: 20 }}>
              <Type var="h5" as="h5" sx={{ mb: 15 }}>
                {step.label}
              </Type>
              <Box>{step.description}</Box>
            </Box>
          ))}
        </Card>
      ) : !isPending ? (
        <Type sx={{ mt: 30, c: "red", bgc: "redbg" }}>
          No Specs found! Go to <Link to="/specs">Specs</Link>.
        </Type>
      ) : (
        <Box sx={{ my: 15 }}>
          <Loading size={20} />
        </Box>
      )}
    </>
  );
}

export default memo(AddProduct);
