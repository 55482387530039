import styled from "styled-components";
import stylesBlocks, { StylesBlocksProps } from "./core/stylesBuilder";
import isPropValid from "@emotion/is-prop-valid";
import { BaseHTMLAttributes } from "react";

export interface BoxProps
  extends StylesBlocksProps,
    BaseHTMLAttributes<HTMLDivElement> {
  w?: number;
}

const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<BoxProps>`
  max-width: ${(props) => (props.w ? props.w + "px" : "auto")};
  ${stylesBlocks}
`;

export default Box;
