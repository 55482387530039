import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Item, ItemsActionType, useItems } from "./provider";
import { useProducts } from "../products/provider";
import { BoundFilter, NftFilter } from "./filters";
import { Access, useAuth } from "../../services/auth";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import SearchInput from "components/SearchInput";
import Thumb from "ui/Thumb";
import truncate from "utilities/truncate";
import { Col } from "ui/tableContainer/TableContainer";

function List() {
  const [term, setTerm] = useState("");
  const [bound, setBound] = useState("all");
  const [nft, setNft] = useState("all");

  const { bulkid, id } = useParams();

  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    state: { list, status, page, perPage, count },
    fetchList,
    deleteItem,
    dispatch,
  } = useItems();

  const {
    state: { product },
  } = useProducts();

  const cols: Col<Item>[] = [
    { label: "ID", name: "id" },
    { label: "Name", name: "name" },
    { label: "Description", name: "description", fn: (v) => truncate(v) },
    {
      label: "Image",
      name: "image",
      render: ({ row }) =>
        row.image && (
          <Thumb
            thumbSrc={`${user.configs.ipfs_base_url}/${row.thumbnail}`}
            imgSrc={`${user.configs.ipfs_base_url}/${row.image}`}
            w={50}
            alt={`item ${row.name} photo`}
          />
        ),
    },
    {
      label: "Bound",
      name: "bound",
      filter: <BoundFilter filter={bound} setFilter={setBound} />,
      filtered: bound !== "all",
      props: {
        style: {
          textAlign: "center",
        },
      },
      render: ({ row }) =>
        row.bound ? (
          <Box sx={{ ta: "center" }}>
            <Icon name="check" c="black" w={16} />
          </Box>
        ) : (
          ""
        ),
    },
    {
      label: "NFT",
      name: "nft",
      filter: <NftFilter filter={nft} setFilter={setNft} />,
      filtered: nft !== "all",
    },
  ];

  useEffect(() => {
    if (!bulkid) return;
    fetchList(Number(bulkid), page, perPage, term, bound, nft);
  }, [fetchList, bulkid, page, perPage, term, bound, nft]);

  const handleDelete = (id: number) => {
    deleteItem(id);
  };

  const handleEdit = (item: any) => {
    navigate(`/products/${product.id}/items/${item.id}/edit`);
  };

  const handleChangePage = (newPage: number) =>
    dispatch({ type: ItemsActionType.ChangePage, payload: newPage });

  return (
    <Box>
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type var="h4" as="h4" sx={{ mt: 0, mb: 0 }}>
          List of items
        </Type>

        <Access path={`products/${id}/items/new`}>
          <Button
            iconName="plus"
            onClick={() => navigate(`/products/${id}/items/new`)}
          >
            Add Items
          </Button>
        </Access>
      </Flex>

      <Box sx={{ ml: "auto", w: 240 }}>
        <SearchInput setTerm={setTerm} />
      </Box>

      <TableContainer
        empty={list?.length === 0}
        status={status}
        cols={cols}
        data={list}
        moreOptions={[
          {
            name: "edit",
            render: (row) => (
              <Icon
                hover="gray300"
                name="pen"
                w={14}
                onClick={() => handleEdit(row)}
              />
            ),
          },
          {
            name: "delete",
            render: (row: any) => (
              <Icon
                hover="gray300"
                name="trash"
                w={14}
                onClick={() => {
                  if (window.confirm("Are you sure?")) handleDelete(row.id);
                }}
              />
            ),
          },
        ]}
      />

      <Box sx={{ mt: 20 }}>
        <Pagination
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Box>
    </Box>
  );
}

export default List;
