import { Box, Button, Grid, InputText, Type } from "ui";

type PhraseProps = {
  words: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>, i: number) => void;
  next: () => void;
  loading: boolean;
};

function Phrase({ words, onChange, next, loading }: PhraseProps) {
  return (
    <>
      <Type sx={{ mxw: 400, mx: "auto", mb: 20 }}>
        Type your 12-Word Phrase
      </Type>
      <Box sx={{ mxw: 860, mx: "auto" }}>
        <Grid
          cols={[
            "repeat(6, 1fr)",
            "repeat(4, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
          ]}
          rowGap={20}
          sx={{ p: [20, "_", "_", "0 0"], my: 0 }}
        >
          {words.map((w, i) => (
            <section key={i}>
              <Box sx={{ p: 4, m: 4 }}>
                <InputText
                  style={{marginBottom: 0}}
                  value={words[i]}
                  inputProps={{
                    onChange: (e) => onChange(e, i),
                    style: { marginBottom: 0 },
                  }}
                />
              </Box>
              <Box sx={{ c: "gray600", fs: 13 }}>{i + 1}</Box>
            </section>
          ))}
        </Grid>
      </Box>

      <Button sx={{ mt: 20 }} loading={loading} onClick={next}>
        Continue
      </Button>
    </>
  );
}

export default Phrase;
