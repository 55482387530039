import { useEffect, useState } from "react";
import formFields from "../fields";
import genFieldsObjFromSpec from "utilities/generateFieldsObjFromSpec";
import { FormField } from "types/types";

function useExpandFieldsFromSpec(
  itemSpecId: number,
  productSpecId: number,
  productSpecFields: any[],
  editing: boolean
) {
  const [formFieldsExpanded, setFormFieldsExpanded] = useState(formFields);

  useEffect(() => {
    setFormFieldsExpanded(() => {
      let newFields: FormField[] = [...formFields];

      newFields.push(...genFieldsObjFromSpec(productSpecFields));

      // In case of a new product
      if (!editing) {
        newFields.push({
          name: "product_fields_schema",
          type: "hidden",
          validationType: "number",
          required: true,
          value: productSpecId,
          validations: [
            {
              type: "required",
              params: ["Product field spec is required."],
            },
          ],
        });

        newFields.push({
          name: "item_fields_schema",
          type: "hidden",
          value: itemSpecId,
          validationType: "number",
          required: true,
          validations: [
            {
              type: "required",
              params: ["Item field spec is required."],
            },
          ],
        });
      }

      return newFields;
    });
  }, [productSpecFields, editing, itemSpecId, productSpecId]);

  return formFieldsExpanded;
}

export default useExpandFieldsFromSpec;
