import { ApexOptions } from "apexcharts";
import { memo, useEffect, useState } from "react";

// third-party
import ReactApexChart from "react-apexcharts";
import theme from "theme";

// chart options
const barChartOptions: ApexOptions = {
  chart: {
    type: "line",
    height: 460,
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: true,
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
};

const repeat = (arr: string[], n: number) => [].concat(...Array(n).fill(arr));

type MonthlyBarChartProps = {
  data: number[];
  labels: string[];
};

const MonthlyBarChart = ({ data, labels }: MonthlyBarChartProps) => {
  const [series, setSeries] = useState([{ data: [] }]);

  const [options, setOptions] = useState<ApexOptions>(barChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.color.blue],
      yaxis: {
        labels: {
          style: {
            fontSize: "15px",
            colors: [
              theme.color.gray500,
              theme.color.gray500,
              theme.color.gray500,
              theme.color.gray500,
              theme.color.gray500,
              theme.color.gray500,
              theme.color.gray500,
            ],
          },
          offsetX: -10,
        },
      },
      xaxis: {
        type: "datetime",
        categories: labels,
        labels: {
          style: {
            paddingTop: 10,
            fontSize: "16px",
            colors: [
              ...repeat(
                ["#eee", theme.color.gray500, "#eee", "#eee"],
                Math.ceil(labels.length / 4)
              ),
            ],
          },
          offsetY: 5,
        },
      },
      tooltip: {
        theme: "light",
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSeries([{ data }]);
    }, 250);
  }, [data]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={460}
      />
    </div>
  );
};

export default memo(MonthlyBarChart);
