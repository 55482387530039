import styled, { css } from "styled-components";
import Box from "./Box";
import isPropValid from "@emotion/is-prop-valid";
import type * as CSS from "csstype";
import { orArray } from "types/types";
import { StylesBlocksProps } from "./core/stylesBuilder";
import { BaseHTMLAttributes } from "react";

interface GridProps extends StylesBlocksProps, BaseHTMLAttributes<HTMLDivElement> {
  cols: orArray<CSS.Properties["gridTemplateColumns"]>;
  rows?: orArray<CSS.Properties["gridTemplateRows"]>;
  rowGap?: number;
  colGap?: number;
  gap?: orArray<number>;
};

export default styled(Box).withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<GridProps>`
  display: grid;
  ${(props) => {
    if (!props.cols) return null;
    if (typeof props.cols === "string") {
      return css`
        grid-template-columns: ${props.cols};
      `;
    } else {
      return css`
        grid-template-columns: ${props.cols[0]};
        @media only screen and (max-width: 1200px) {
          grid-template-columns: ${props.cols[1]};
        }
        @media only screen and (max-width: 992px) {
          grid-template-columns: ${props.cols[2]};
        }
        @media only screen and (max-width: 768px) {
          grid-template-columns: ${props.cols[3]};
        }
        /* grid-template-columns: ${props.cols}; */
      `;
    }
  }}
  ${(props) =>
    props.rows &&
    css`
      grid-template-rows: ${props.rows};
    `}
    ${(props) =>
    props.rowGap &&
    css`
      row-gap: ${props.rowGap}px;
    `};
  ${(props) =>
    props.colGap &&
    css`
      column-gap: ${props.colGap}px;
    `};
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap}px;
    `};
`;
