import { useRequest } from "services/request";
import { useState } from "react";
import { FetchListResult } from "./types";

export default function useApi() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const req = useRequest();

  async function getReports(page = 0, pageSize = 10): Promise<FetchListResult> {
    const query = `page=${page + 1}&pageSize=${pageSize}`;
    return await req({url: `stats/products-stats/?${query}`, withAuth: true});
  }

  return {
    getReports,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
}
