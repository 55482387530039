import { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import { Bulk, BulksActionType, useBulks } from "./Provider";
import { format } from "date-fns";
import { Access } from "services/auth";
import { Col } from "ui/tableContainer/TableContainer";

const cols: Col<Bulk>[] = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  { label: "Items Count", name: "items_count" },
  {
    label: "Created On",
    name: "created_on",
    render: ({ row }) => {
      return (
        <Type>
          {format(new Date(row.created_on), "MMMM dd, yyyy hh:mm aaa")}
        </Type>
      );
    },
  },
];

function List() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    state: { list, status, page, perPage, count },
    dispatch,
    fetchList,
  } = useBulks();

  useEffect(() => {
    fetchList(Number(id), page, perPage);
  }, [fetchList, id, page, perPage]);

  const handleShow = (bulkId: number) => {
    navigate(`/products/${id}/bulk/${bulkId}/items`);
  };

  const handleChangePage = (newPage: number) =>
    dispatch({ type: BulksActionType.ChangePage, payload: newPage });

  return (
    <Box>
      {/* <Flex jc="space-between" sx={{ mb: 60 }}> */}
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type var="h4" as="h4" sx={{ mt: 0, mb: 0 }}>
          List of bulks
        </Type>

        <Access path="/items/new">
          <Button
            iconName="plus"
            onClick={() => navigate(`/products/${id}/items/new`)}
          >
            Add Items
          </Button>
        </Access>
      </Flex>

      <TableContainer
        empty={list?.length === 0}
        data={list}
        status={status}
        aria-label="Bulks table"
        cols={cols}
        moreOptions={[
          {
            name: "show",
            render: (row) => (
              <Icon
                hover="gray300"
                name="eye"
                w={14}
                onClick={() => handleShow(row.id as number)}
              />
            ),
          },
        ]}
      />

      <Box sx={{mt: 20}}>
        <Pagination
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Box>
    </Box>
  );
}

export default memo(List);
