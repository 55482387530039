export default async function blobToBinary(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    try {
      var reader: FileReader = new FileReader();

      reader.onloadend = function () {
        resolve(reader.result);
      };

      reader.readAsArrayBuffer(file);
    } catch (e: any) {
      reject(e.message);
    }
  });
}
