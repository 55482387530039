var apiUrl: URL;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = new URL("https://api.stitchain.com/v1");
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = new URL("https://sandbox.api.stitchain.com/v1");
else {
  apiUrl = new URL("https://staging.api.stitchain.com/v1");
}

const GOOGLE_APT_KEY = "AIzaSyCerC9R94lDgwBKR1yf_EAqL0oa1CRdW8c";

type Access = {
  [key: number]: (RegExp | string)[];
}

const access: Access = {
  1001: [/\/manufacturers$/i],
  1002: [/\/manufacturers\/edit/i],
  1003: [/\/specs$/i],
  1004: ["/specs/new", /\/specs\/edit/i],
  1005: [/\/users$/i],
  1006: ["/users/new", /\/users\/edit/i],
  2001: [/\/products$/i],
  2002: ["/products/new", /\/products\/edit/i],
  2003: [/\/items$/i],
  2004: [
    /\/products\/\d+\/items\/new/i,
    "/items/new",
    "/items/edit",
    /\/products\/\d+\/items\/\d+\/edit/i,
  ],
  2005: ["Bind With Nfc"],
};

export { apiUrl, access, GOOGLE_APT_KEY };
