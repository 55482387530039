import { memo } from "react";
import { User } from "../type";
import { Box, Flex, Icon, Loading, Type } from "ui";
import Dropdown from "ui/core/Dropdown";
import RolesList from "./RolesList";
import useApi from "../useApi";

const EditRoles = ({ row }: { row: User }) => {
  const {editUser} = useApi();

  const handleRolesChange = (roles: number[]) => {
    editUser.mutate({id: row.user_id, data: { roles }});
  };

  if (!row.roles) return null;

  return (
    <Flex jc="flex-start" gap={20}>
      <Box>
        {row.roles.map((r: any) => (
          <Type key={r.name} sx={{ fw: "bold", fs: 13 }}>
            {r.name}
          </Type>
        ))}
      </Box>
      <Dropdown
        content={
          <span>
            <RolesList
              userRolesIds={row.roles.map((r: any) => r.id)}
              onChange={handleRolesChange}
              user={row}
            />
          </span>
        }
      >
        <span>
          {editUser.isPending ? (
            <Loading size={18} />
          ) : (
            <Icon name="plus" c="black" w={11} hover="gray300" />
          )}
        </span>
      </Dropdown>
    </Flex>
  );
};

export default memo(EditRoles);
