import VerificationTransactionCard from "./components/VerificationTransactionCard";
import { useCallback, useState } from "react";
import { Box, Pagination, Type } from "ui";
import Modal from "ui/Modal";
import TransactionMap from "./components/TransactionMap";
import { useQuery } from "@tanstack/react-query";
import useStatsApi from "views/stats/useStatsApi";

function VerificationTransaction() {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(null);

  const { getItemTransaction, page, setPage } = useStatsApi();

  const handleOpenMap = useCallback(
    (location: { longitude: number; latitude: number }) => {
      setOpen(true);
      setLocation(location);
    },
    []
  );

  const { data, isPending } = useQuery({
    queryKey: ["itemTransaction"],
    queryFn: () => getItemTransaction(page),
    placeholderData: (previousData) =>
      previousData || { results: [], count: 0 },
  });

  const { results: itemTranaction, count } = data;

  return (
    <>
      <Type var="h3" as="h3">
        Verification Transactions
      </Type>

      {!itemTranaction.length && (
        <Type sx={{ mt: 40 }}>No data available yet.</Type>
      )}
      {itemTranaction.map((item) => (
        <Box key={item.id} sx={{ my: 40 }}>
          <VerificationTransactionCard
            data={item}
            isPending={isPending}
            handleOpenMap={handleOpenMap}
          />
        </Box>
      ))}

      <Pagination count={count} page={page} onPageChange={setPage} />

      <Modal onClose={() => setOpen(false)} show={open}>
        {location && (
          <TransactionMap lng={location?.longitude} lat={location?.latitude} />
        )}
      </Modal>
    </>
  );
}

export default VerificationTransaction;
