import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../services/auth";
import { Box, Button, Card, Flex, Icon, InputText, Link, Type } from "ui";
import { useUtils } from "services/utilsProvider";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function Email() {
  const navigate = useNavigate();
  const {notify} = useUtils();
  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: auth.user.email,
    },
  });

  const onSubmit = async (values: any) => {
    try {
      await auth.editAccount(values);
      notify("Email has been saved successfully", "success");
      navigate("/profile");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Type var="h4">
        <Link to={-1 as any}>
          <Icon name="arrowLeft" w={12} c="black" />
        </Link>{" "}
        Email
      </Type>

      <Flex jc="flex-start" gap={8} sx={{py: 15}}>
        <Icon name="warning" w={18} c="yellow" />
        <Type>This step require verification</Type>
      </Flex>

      <Card sx={{ mxw: 400, mt: 40 }}>
        <Type var="h5" as="h5">
          Change Email
        </Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30 }}
        >
          <InputText
            id="email"
            type="email"
            label="email"
            name="email"
            error={!!errors.email}
            msg={errors.email?.message}
            inputProps={{
              autoFocus: true,
              ...register("email"),
              required: true,
            }}
          />

          <Flex jc="flex-start" gap={15} sx={{mt: 30}}>
            <Button loading={isSubmitting}>Save</Button>
            <Button type="button" variant="text" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </Card>
    </>
  );
}

export default Email;
