import styled from "styled-components";
import { Icon } from "ui";

const Toggle = styled.div`
  cursor: pointer;
  padding: 10px 6px;
  width: 18px;
`;

type ToggleSidebarProps = {
  isOpen: boolean;
};

function ToggleSidebar({ isOpen }: ToggleSidebarProps) {
  return (
    <Toggle>
      <div className="bars" />
      <Icon name={isOpen ? "arrowLeft" : "arrowRight"} c="white" w={16} />
    </Toggle>
  );
}

export default ToggleSidebar;
