import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Card, Flex, InputRadio, Type } from "ui";

interface FilterProps {
  filter: string,
  setFilter: Dispatch<SetStateAction<string>>
}

interface FilterFormProps extends FilterProps {
  title: string,
  name: string,
}

const FilterForm = ({ title, name, filter, setFilter }: FilterFormProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  return (
    <Card sx={{ mnw: 200, mb: 0, px: 2, py: 1 }}>

        <Type id={`${name}-filter`} sx={{mb: 15}}>{title}</Type>
        <Flex
          gap={15}
          // value={filter}
          onChange={handleChange}
          // name={name}
        >
          <InputRadio  label="Yes" name={name} inputProps={{value: '1', defaultChecked: filter === '1'}} />
          <InputRadio label="No" name={name} inputProps={{value: '0', defaultChecked: filter === '0'}} />
          <InputRadio label="Both" name={name} inputProps={{value: 'all', defaultChecked: filter === 'all'}} />
        </Flex>

    </Card>
  );
};

const BoundFilter = ({ filter, setFilter }: FilterProps) => {
  return (
    <FilterForm
      title="Is Bound to a card?"
      name="bound"
      {...{ filter, setFilter }}
    />
  );
};

const NftFilter = ({filter, setFilter}: FilterProps) => {
  return (
    <FilterForm
      title="Has recieved NFT?"
      name="nft"
      {...{ filter, setFilter }}
    />
  );
};

export { BoundFilter, NftFilter };
