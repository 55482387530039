import { useAuth } from "../../services/auth";
import Account from "./profileTabs/AccountInfo";
import Wallet from "./profileTabs/WalletInfo";
import { Box, Grid, Type } from "ui";
import BrandInfo from "./profileTabs/BrandInfo";


function ProfileInfo() {
  const auth = useAuth();

  if (!auth.user) return;

  return (
    <>
      <Type var="h4" as="h4" sx={{mb: 40}}>
        Your profile info on Stitchain services
      </Type>


        <Grid cols={["1fr 1fr 1fr", "1fr 1fr", "1fr", "100%"]} gap={30} sx={{mxw: 1500}}>
          <Box>
            <Account />
          </Box>
          {/* <Box> */}
            <BrandInfo />
            <Wallet />
          {/* </Box> */}
        </Grid>

    </>
  );
}

export default ProfileInfo;
