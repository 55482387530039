import { ComponentPropsWithoutRef, useState } from "react";
import Modal from "./Modal";
import styled from "styled-components";

type ImgProps = { $w: number } & ComponentPropsWithoutRef<"img">;

const Img = styled.img<ImgProps>`
  object-fit: contain;
  width: ${(p) => p.$w}px;
  height: ${(p) => p.$w}px;
  cursor: pointer;
`;

type ThumbProps = {
  thumbSrc: string;
  imgSrc: string;
  w: number;
  alt: string;
};

function Thumb({ thumbSrc, imgSrc, w, alt }: ThumbProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Img $w={w} src={thumbSrc} alt={alt} onClick={() => setOpen(true)} />

      <Modal onClose={handleClose} show={open}>
        <img src={imgSrc} alt="full width" onClick={() => setOpen(true)} />
      </Modal>
    </>
  );
}

export default Thumb;
