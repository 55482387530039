import { useState } from "react";
import { useRequest } from "services/request";
import {
  ItemTransactionResult,
  MintedItem,
  StatsTotals,
  TransactionByCountry,
} from "./types";

export default function useStatsApi() {
  const [page, setPage] = useState(0);

  const req = useRequest();

  async function getTotals(params = { daysAgo: 100 }): Promise<StatsTotals> {
    const searchParams: Record<string, any> = new URLSearchParams();
    searchParams.append("days_ago", params.daysAgo);
    return await req({
      url: `stats/totals/?${searchParams.toString()}`,
      withAuth: true,
    });
  }

  async function getMintedItems(): Promise<MintedItem[]> {
    return await req({ url: `stats/minted_items/`, withAuth: true });
  }

  async function getItemTransaction(page = 0): Promise<ItemTransactionResult> {
    return await req({
      url: `stats/verification-transactions/?page=${page + 1}`,
      withAuth: true,
    });
  }

  async function getTransactionsByCountry(): Promise<TransactionByCountry[]> {
    return await req({url: `stats/transactions_by_country/`, withAuth: true});
  }

  return {
    getTotals,
    getMintedItems,
    getItemTransaction,
    getTransactionsByCountry,
    page,
    setPage,
  };
}
