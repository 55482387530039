import { useEffect, useState } from "react";
import WalletPassword from "./WalletPassword";
import WalletSecure from "./WalletSecure";
import WalletConfirm from "./WalletConfirm";
import WalletIdentity from "./WalletIdentity";
import WalletApprove from "./WalletApprove";
import { Box, Button, Divider, Flex, Type } from "ui";
import LayoutMasked from "layout/LayoutMasked";
import logo from "../../assets/logo-white.png";
import { useNavigate } from "react-router-dom";
import { useWallet } from "./Provider";
import { useAuth } from "services/auth";

const steps = [
  "Create Password",
  "Secure your wallet",
  "Confirm secret phrase",
  "Create Identity",
  "Approve",
  "Finish",
];

function WalletCreate() {
  const [activeStep, setActiveStep] = useState(0);
  const [words, setWords] = useState([]);
  const [password, setPassword] = useState("");

  const { user } = useAuth();

  const navigate = useNavigate();

  const {
    state: { privateKey, hasApproved },
    fetchHasApproved,
  } = useWallet();

  // useEffect(() => {
  //   if (hasApproved !== null) return;
  //   fetchHasApproved();
  // }, [fetchHasApproved, hasApproved]);

  useEffect(() => {
    if (user.manufacturer.identity_address)
      if (hasApproved === null) {
        (async () => await fetchHasApproved())();
      }
    console.log("asdadadadadasdadasdasd");
    if (window.localStorage.getItem("wallet_enc_pack")) {
      if (privateKey) {
        if (!user.manufacturer.identity_address) setActiveStep(3);
        else if (!hasApproved) setActiveStep(4);
        else navigate("/wallet");
      } else {
        navigate("/wallet/unlock");
      }
    }
  }, [
    navigate,
    privateKey,
    activeStep,
    user.manufacturer.identity_address,
    hasApproved,
    fetchHasApproved,
  ]);

  const step1 = WalletPassword;
  const step2 = WalletSecure;
  const step3 = WalletConfirm;
  const step4 = WalletIdentity;
  const step5 = WalletApprove;
  const step6 = () => (
    <>
      <Type var="h5" sx={{ mt: 80, mb: 60, ta: "center" }}>
        Your wallet has been created successfully.
      </Type>

      <Button
        onClick={() => {
          const user = JSON.parse(window.localStorage.getItem("user"));
          user.manufacturer.identity_address =
            window.localStorage.getItem("wallet_public_key");
          window.localStorage.setItem("user", JSON.stringify(user));
          window.location.replace("/dashboard");
        }}
      >
        Go to Dashboard
      </Button>
    </>
  );

  const contents = [step1, step2, step3, step4, step5, step6];

  const Content = contents[activeStep];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <LayoutMasked>
      <Box center w={900} sx={{ ta: "center", p: 40 }}>
        <Box sx={{ p: 6, br: 2, bgc: "black", d: "inline-block", mb: 30 }}>
          <img
            src={logo}
            alt="stitchain"
            width={40}
            style={{ display: "block" }}
          />
        </Box>
        <Type var="h4" sx={{ mt: 20, print: { d: "none" } }}>
          Create A Wallet
        </Type>
        <Type var="subtitle" sx={{ print: { d: "none" } }}>
          You must have a wallet, in order to be able to use your account.
        </Type>
        <Box sx={{ mxw: 800, my: 40, p: 40 }}>
          <Flex
            ai="flex-start"
            sx={{ mb: 40, print: { d: "none" } }}
            gap={0}
            // activeStep={activeStep}
            // alternativeLabel
          >
            {steps.map((label, i) => {
              return (
                <Flex ai="flex-start" key={label}>
                  <Flex flexDir="column" gap={10} sx={{ ta: "center" }}>
                    <Flex
                      sx={{
                        bgc: Boolean(i === activeStep) ? "black" : "gray400",
                        br: 999,
                        w: 26,
                        h: 26,
                      }}
                    >
                      <Type sx={{ c: "white" }}>{i + 1}</Type>
                    </Flex>
                    <Type
                      sx={{
                        fs: 13,
                        lh: 18,
                        mnw: 90,
                        fw: Boolean(i === activeStep) ? 600 : 400,
                      }}
                    >
                      {label}
                    </Type>
                  </Flex>
                  {Boolean(i + 1 < steps.length) && (
                    <Box sx={{ w: 30, mt: 6, ml: -20 }}>
                      <Divider border="gray300" />
                    </Box>
                  )}
                </Flex>
              );
            })}
          </Flex>
          <Box sx={{ mx: "auto", my: 3 }}>
            <Content
              next={handleNext}
              {...{ words, setWords, setPassword, password }}
            />
          </Box>
        </Box>
      </Box>
    </LayoutMasked>
  );
}

export default WalletCreate;
